import { useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import PropTypes from 'prop-types';
import MealDetailsQuery from './MealDetailsQuery';

const MealDetailsCacheConnectorApollo = ({ children }) => {
    const client = useApolloClient();
    const requestIdleCallbackIds = [];

    useEffect(() => {
        return () => {
            if (window.cancelIdleCallback) {
                requestIdleCallbackIds.forEach(id => {
                    window.cancelIdleCallback(id);
                });
            }
        };
    }, []);

    const idle = callback => {
        if (!window.requestIdleCallback) {
            callback();
            return;
        }
        requestIdleCallbackIds.push(window.requestIdleCallback(callback));
    };

    const createFetch = (userDietSetId, mealType, dietMealId) => {
        idle(() =>
            client.query({
                query: MealDetailsQuery,
                variables: {
                    userDietSetId,
                    mealType,
                    dietMealId,
                },
            })
        );
    };

    return children({
        prefetchMealDetails: createFetch,
    });
};

MealDetailsCacheConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
};

export default MealDetailsCacheConnectorApollo;
