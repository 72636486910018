import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import { withLocale } from '@dietlabs/components';
import { reportError } from '@dietlabs/utils';

import { formatIsoDate } from 'utils/dateFormatter';

import DayPlan from './components/DayPlan';
import DayPlanSummary from './components/DayPlanSummary';
import Meal from './components/DayPlanMeal';
import PrintForm from './components/DayPlanPrintForm';

import MealReplacementList from './components/DayPlanMealReplacementList';
import MealReplacement from './components/DayPlanMealReplacement';

import DayPlanChangeDay from './components/DayPlanChangeDay';
import DayReplacement from './components/DayPlanDayReplacement';

import MealNavigation from './components/DayPlanMealNavigation';
import MealViewContainer from '../MealView/MealViewContainer';

class DayViewContainer extends Component {
    static propTypes = {
        renderTimeline: PropTypes.func.isRequired,
        dailyDietPlan: PropTypes.shape({
            setId: PropTypes.number.isRequired,
            canBeCopiedToDate: PropTypes.bool.isRequired,
            date: PropTypes.instanceOf(Date).isRequired,
            dayDiet: PropTypes.shape({
                id: PropTypes.number.isRequired,
                kcal: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                macro: PropTypes.shape({
                    animalProtein: PropTypes.shape({
                        grams: PropTypes.number.isRequired,
                        kcal: PropTypes.number.isRequired,
                        percentage: PropTypes.number.isRequired,
                    }).isRequired,
                    carbohydrates: PropTypes.shape({
                        grams: PropTypes.number.isRequired,
                        kcal: PropTypes.number.isRequired,
                        percentage: PropTypes.number.isRequired,
                    }).isRequired,
                    fat: PropTypes.shape({
                        grams: PropTypes.number.isRequired,
                        kcal: PropTypes.number.isRequired,
                        percentage: PropTypes.number.isRequired,
                    }).isRequired,
                    protein: PropTypes.shape({
                        grams: PropTypes.number.isRequired,
                        kcal: PropTypes.number.isRequired,
                        percentage: PropTypes.number.isRequired,
                    }).isRequired,
                }).isRequired,
                phase: PropTypes.shape({
                    name: PropTypes.string.isRequired,
                    identifier: PropTypes.string.isRequired,
                }).isRequired,
            }),
            events: PropTypes.arrayOf(PropTypes.shape()),
            showMacros: PropTypes.bool.isRequired,
        }).isRequired,
        availableDays: PropTypes.arrayOf(PropTypes.object).isRequired,
        categories: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        goal: PropTypes.shape({
            reachedBecauseOfLoseWeight: PropTypes.bool.isRequired,
            reachedBecauseOfPutOnWeight: PropTypes.bool.isRequired,
            lostBecauseOfLoseWeight: PropTypes.bool.isRequired,
            lostBecauseOfPutOnWeight: PropTypes.bool.isRequired,
        }).isRequired,
        isHolidayMenu: PropTypes.bool.isRequired,
        isTimeToNagForCurrentMeasurement: PropTypes.bool.isRequired,
        alreadyCopied: PropTypes.bool.isRequired,
        print: PropTypes.bool,
        prefetchSurroundingDays: PropTypes.func.isRequired,
        prefetchMealDetails: PropTypes.func.isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
            search: PropTypes.string.isRequired,
        }).isRequired,
        dietId: PropTypes.number.isRequired,
        copyDayPlan: PropTypes.shape().isRequired,
        addMealToFavorites: PropTypes.shape().isRequired,
        removeMealFromFavorites: PropTypes.shape().isRequired,
        insertFavoriteToDayplan: PropTypes.shape().isRequired,
        setMealEaten: PropTypes.shape().isRequired,
        loadDayPlanDayReplacements: PropTypes.shape().isRequired,
        replaceDay: PropTypes.shape().isRequired,
        loadDayPlanMealReplacements: PropTypes.shape().isRequired,
        replaceMeal: PropTypes.shape().isRequired,
        recipeNotesForNextDay: PropTypes.arrayOf(PropTypes.string).isRequired,
        fastType: PropTypes.string.isRequired,
        fastingStatistics: PropTypes.shape({
            dietsCount: PropTypes.number,
            currentDietLength: PropTypes.number,
            currentDietProgress: PropTypes.number,
        }).isRequired,
        intermittentFasting: PropTypes.shape().isRequired,
    };

    static defaultProps = {
        print: false,
    };

    state = {
        showModal: false,
        showModalWithReplacements: false,
        showModalWithDayReplacements: false,
        mealId: undefined,
        replacements: null,
        event: {
            id: undefined,
            key: undefined,
            original: undefined,
            name: undefined,
        },
    };

    componentDidMount = async () => {
        if (!this.props.print) {
            this.props.prefetchSurroundingDays();
        }

        this.prefetchMealDetails();
        this.props.prefetchSurroundingDays();

        const urlParams = new URLSearchParams(this.props.location.search);
        if (urlParams.has('showReplacements')) {
            this.toggleModalWithDayReplacements();
        }
    };

    componentDidUpdate = async prevProps => {
        if (
            formatIsoDate(prevProps.dailyDietPlan.date) !==
            formatIsoDate(this.props.dailyDietPlan.date)
        ) {
            this.prefetchMealDetails();
            this.props.prefetchSurroundingDays();
            const urlParams = new URLSearchParams(this.props.location.search);
            if (urlParams.has('showReplacements')) {
                this.toggleModalWithDayReplacements();
            }
        }
    };

    prefetchMealDetails = () => {
        this.props.dailyDietPlan.events.map(event => {
            if (event.__typename === 'UserDailyDietPlanEventMeal') {
                this.props.prefetchMealDetails(
                    this.props.dailyDietPlan.setId,
                    event.mealType.type,
                    event.id
                );
            }
            return null;
        });
    };

    toggleModal(mealId) {
        if (mealId) {
            this.setState(prevState => ({
                showModal: !prevState.showModal,
                mealId,
            }));
        } else {
            this.setState(prevState => ({
                showModal: !prevState.showModal,
            }));
            setTimeout(() => {
                this.setState({ mealId });
            }, 500);
        }
    }

    toggleModalWithReplacements(replacements, event) {
        if (replacements) {
            this.setState(prevState => ({
                showModalWithReplacements: !prevState.showModalWithReplacements,
                replacements,
                event,
            }));
        } else {
            this.setState(prevState => ({
                showModalWithReplacements: !prevState.showModalWithReplacements,
            }));
        }
    }

    toggleModalWithDayReplacements() {
        this.setState(prevState => ({
            showModalWithDayReplacements: !prevState.showModalWithDayReplacements,
        }));
    }

    changeMeal(mealId) {
        this.setState({ mealId });
    }

    renderMealReplacements = (
        originalMealId,
        mealId,
        replacements,
        mealName,
        mealType
    ) =>
        replacements.map(mealReplacement => (
            <MealReplacement
                key={mealReplacement.id}
                mealId={mealId}
                originalMealId={originalMealId}
                mealReplacement={mealReplacement}
                replaceMeal={this.props.replaceMeal}
                insertFavoriteToDayplan={this.props.insertFavoriteToDayplan}
                showModal={this.state.showModal}
                toggleModalWithReplacements={() =>
                    this.toggleModalWithReplacements()
                }
                toggleModal={() => this.toggleModal()}
                changeMeal={newMealId => this.changeMeal(newMealId)}
                mealName={mealName}
                userDietSetId={this.props.dailyDietPlan.setId}
                mealType={mealType}
            />
        ));

    renderMealReplacementsList = (replacements, event) => (
        <MealReplacementList
            mealId={event.id}
            renderOriginalMeal={() => null}
            replacements={replacements}
            toggleModalWithReplacements={() =>
                this.toggleModalWithReplacements()
            }
            toggleModal={() => this.toggleModal()}
            date={this.props.dailyDietPlan.date}
        >
            {() =>
                this.renderMealReplacements(
                    event.originalMeal ? event.originalMeal.id : null,
                    event.id,
                    replacements,
                    event.mealType.name,
                    event.mealType.type
                )
            }
        </MealReplacementList>
    );

    renderMealDetails = events =>
        events.map(event => {
            if (
                event.__typename === 'UserDailyDietPlanEventMeal' &&
                event.id === this.state.mealId
            ) {
                return (
                    <MealViewContainer
                        userDietSetId={this.props.dailyDietPlan.setId}
                        mealType={event.mealType.type}
                        mealKey={event.key}
                        dietMealId={event.id}
                        date={this.props.dailyDietPlan.date}
                        showMacros={this.props.dailyDietPlan.showMacros}
                        toggleModal={() => this.toggleModal()}
                        addMealToFavorites={this.props.addMealToFavorites}
                        removeMealFromFavorites={
                            this.props.removeMealFromFavorites
                        }
                        setId={this.props.dailyDietPlan.setId}
                        setMealEaten={this.props.setMealEaten}
                        toggleModalWithReplacements={replacements =>
                            this.toggleModalWithReplacements(
                                replacements,
                                event
                            )
                        }
                        loadDayPlanMealReplacements={
                            this.props.loadDayPlanMealReplacements
                        }
                        key={event.key}
                    />
                );
            }

            return '';
        });

    renderEvents = events =>
        events.map(event => {
            if (event.__typename === 'UserDailyDietPlanEventMeal') {
                return (
                    <Meal
                        key={event.key}
                        mealKey={event.key}
                        mealId={event.id}
                        originalMealId={
                            event.originalMeal ? event.originalMeal.id : null
                        }
                        preparationTime={event.preparationTime}
                        name={event.mealType.name}
                        mealType={event.mealType.type}
                        kcal={event.kcal}
                        macro={event.macro}
                        showMacros={this.props.dailyDietPlan.showMacros}
                        data-test="meal-component"
                        loadDayPlanMealReplacements={
                            this.props.loadDayPlanMealReplacements
                        }
                        print={false}
                        toggleModal={mealId => this.toggleModal(mealId)}
                        toggleModalWithReplacements={replacements =>
                            this.toggleModalWithReplacements(
                                replacements,
                                event
                            )
                        }
                        dishes={event.dishes}
                        setMealEaten={this.props.setMealEaten}
                        setId={this.props.dailyDietPlan.setId}
                        preparationImageUrl={event.preparationImageUrl}
                        publicName={event.name}
                        addMealToFavorites={this.props.addMealToFavorites}
                        removeMealFromFavorites={
                            this.props.removeMealFromFavorites
                        }
                        canBeAddedToFavorites={event.canBeAddedToFavorites}
                        isFavorite={event.isFavorite}
                        isEaten={event.isEaten}
                    >
                        {() =>
                            this.renderDishes(
                                event.dishes,
                                event.name,
                                event.preparationVideoUrl
                            )
                        }
                    </Meal>
                );
            }
            reportError(
                new Error(`Unknown event typename: ${event.__typename}`)
            );
            return '';
        });

    renderMealLinks = () => {
        return (
            <MealNavigation
                events={this.props.dailyDietPlan.events}
                changeMeal={mealId => this.changeMeal(mealId)}
                activeMeal={this.state.mealId}
                date={this.props.dailyDietPlan.date}
                kcal={this.props.dailyDietPlan.dayDiet.kcal}
                showMacros={this.props.dailyDietPlan.showMacros}
            />
        );
    };

    renderDayReplacements = (replacements, search) =>
        replacements.map(replacement => (
            <DayReplacement
                key={replacement.dietSetId}
                dayReplacement={replacement}
                search={search}
                replaceDay={this.props.replaceDay}
                toggleModalWithDayReplacements={() =>
                    this.toggleModalWithDayReplacements()
                }
            />
        ));

    renderChangeDay = () => (
        <DayPlanChangeDay
            loadDayPlanDayReplacements={this.props.loadDayPlanDayReplacements}
            renderOriginalDay={(original, search) =>
                this.renderDayReplacements([original], search)
            }
            date={this.props.dailyDietPlan.date}
            currentDayPlan={this.props.dailyDietPlan.events}
            toggleModalWithDayReplacements={() =>
                this.toggleModalWithDayReplacements()
            }
            toggleModalWithReplacements={() =>
                this.toggleModalWithReplacements()
            }
        >
            {(replacements, search) =>
                this.renderDayReplacements(replacements, search)
            }
        </DayPlanChangeDay>
    );

    renderSummary = (canBeCopiedToDate, alreadyCopied) => (
        <DayPlanSummary
            protein={this.props.dailyDietPlan.dayDiet.macro.protein.percentage}
            fat={this.props.dailyDietPlan.dayDiet.macro.fat.percentage}
            carbohydrates={
                this.props.dailyDietPlan.dayDiet.macro.carbohydrates.percentage
            }
            canBeCopiedToDate={canBeCopiedToDate}
            copyDayPlan={this.props.copyDayPlan}
            alreadyCopied={alreadyCopied}
            print={false}
            data-test="summary-component"
            toggleModalWithDayReplacements={() =>
                this.toggleModalWithDayReplacements()
            }
        />
    );

    renderPrintForm = () => (
        <PrintForm
            date={this.props.dailyDietPlan.date}
            availableDays={this.props.availableDays}
        />
    );

    render() {
        return (
            <DayPlan
                kcal={this.props.dailyDietPlan.dayDiet.kcal}
                macro={this.props.dailyDietPlan.dayDiet.macro}
                showMacros={this.props.dailyDietPlan.showMacros}
                date={this.props.dailyDietPlan.date}
                name={this.props.dailyDietPlan.dayDiet.name}
                phase={this.props.dailyDietPlan.dayDiet.phase}
                goal={this.props.goal}
                isHolidayMenu={this.props.isHolidayMenu}
                isTimeToNagForCurrentMeasurement={
                    this.props.isTimeToNagForCurrentMeasurement
                }
                availableDays={this.props.availableDays}
                categories={this.props.categories}
                renderTimeline={position => this.props.renderTimeline(position)}
                print={this.props.print}
                recipeNotesForNextDay={this.props.recipeNotesForNextDay}
                renderMealLinks={() => this.renderMealLinks()}
                showModal={this.state.showModal}
                renderMealDetails={() =>
                    this.renderMealDetails(this.props.dailyDietPlan.events)
                }
                showModalWithReplacements={this.state.showModalWithReplacements}
                toggleModalWithReplacements={() =>
                    this.toggleModalWithReplacements()
                }
                renderMealReplacementsList={() =>
                    this.renderMealReplacementsList(
                        this.state.replacements,
                        this.state.event
                    )
                }
                showModalWithDayReplacements={
                    this.state.showModalWithDayReplacements
                }
                toggleModalWithDayReplacements={() =>
                    this.toggleModalWithDayReplacements()
                }
                renderChangeDay={() => this.renderChangeDay()}
                dietId={this.props.dietId}
                fastType={this.props.fastType}
                fastingStatistics={this.props.fastingStatistics}
                intermittentFasting={this.props.intermittentFasting}
            >
                {() => (
                    <React.Fragment>
                        {this.renderEvents(this.props.dailyDietPlan.events)}
                        {this.renderSummary(
                            this.props.dailyDietPlan.canBeCopiedToDate,
                            this.props.alreadyCopied
                        )}
                        {this.renderPrintForm()}
                    </React.Fragment>
                )}
            </DayPlan>
        );
    }
}

export { DayViewContainer };
export default withRouter(withLocale(DayViewContainer));
