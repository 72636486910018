import { formatIsoDate } from './dateFormatter';

function isToday(date) {
    return formatIsoDate(date) === formatIsoDate(new Date());
}

function clone(date) {
    return new Date(date.valueOf());
}

function addDays(date, days) {
    const newDate = clone(date);
    newDate.setDate(newDate.getDate() + days);
    // Correct hour if timezone changed
    newDate.setHours(
        newDate.getHours() +
            (date.getTimezoneOffset() - newDate.getTimezoneOffset()) / 60
    );
    return newDate;
}

function subDays(date, days) {
    return addDays(date, -1 * days);
}

function dateTimeToDate(dateTime) {
    return new Date(formatIsoDate(dateTime));
}

function isTomorrow(date) {
    return formatIsoDate(date) === formatIsoDate(addDays(new Date(), 1));
}

function isYesterday(date) {
    return formatIsoDate(date) === formatIsoDate(addDays(new Date(), -1));
}

export {
    isToday,
    isTomorrow,
    isYesterday,
    clone as cloneDate,
    addDays,
    subDays,
    dateTimeToDate,
};
