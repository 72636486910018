import React, { Component } from 'react';
import { Container, Row, Col, FormGroup, Button, Label } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import PropTypes from 'prop-types';

class ShoppingListPlaceholder extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <Container>
                    <Row>
                        <Col xs="12" sm={{ size: 8, offset: 2 }}>
                            <Label>
                                {t('shopping-list/date-range')}
                                &nbsp;<sup>*</sup>
                            </Label>
                            <Row>
                                <Col xs="12" sm="6">
                                    <FormGroup>
                                        <div className="input-placeholder placeholder">
                                            &nbsp;
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col xs="12" sm="6">
                                    <FormGroup>
                                        <div className="input-placeholder placeholder">
                                            &nbsp;
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <br />
                            <div className="text-center">
                                <Button color="placeholder">
                                    {t('shopping-list/show-list-button')}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <section>
                    <Container>
                        <hr />
                        <Row>
                            <Col xs="12" sm="6">
                                <ul className="ingredients">
                                    <li className="placeholder">&nbsp;</li>
                                    <li className="placeholder">&nbsp;</li>
                                    <li className="placeholder">&nbsp;</li>
                                    <li className="placeholder">&nbsp;</li>
                                    <li className="placeholder">&nbsp;</li>
                                </ul>
                            </Col>
                            <Col xs="12" sm="6">
                                <ul className="ingredients">
                                    <li className="placeholder">&nbsp;</li>
                                    <li className="placeholder">&nbsp;</li>
                                    <li className="placeholder">&nbsp;</li>
                                    <li className="placeholder">&nbsp;</li>
                                    <li className="placeholder">&nbsp;</li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default withLocale(ShoppingListPlaceholder);
