/* eslint-disable no-use-before-define */
import { useEffect } from 'react';
import { subDays } from 'utils/date';
import { formatIsoDate } from 'utils/dateFormatter';
import FavMealsQuery from '@dietlabs/components/src/ApolloConnectors/FavMeals/Fetch/FavMealsQuery';
import { useApolloClient } from '@apollo/client';
import {
    MEASUREMENT_TYPES,
    PERIOD_END,
    PERIOD_LENGTH,
} from 'Measurement/constants';
import MeasurementHistoryQuery from '@dietlabs/components/src/ApolloConnectors/Measurement/MeasurementHistory/MeasurementHistoryQuery';
import CurrentMeasurementsQuery from '@dietlabs/components/src/ApolloConnectors/Measurement/CurrentMeasurements/CurrentMeasurementsQuery';
import DietPlanQuery from '@dietlabs/components/src/ApolloConnectors/DailyDietPlan/Fetch/DietPlanQuery';

const OnMessageCacheModifier = () => {
    const client = useApolloClient();

    useEffect(() => {
        window.addEventListener('message', e => {
            if (e.data.action === 'handle_favourite_meal') {
                const { key, mealId, isFavorite } = e.data.payload;
                handleFavouriteMeal(key, mealId, isFavorite);
            } else if (e.data.action === 'handle_eaten_meal') {
                const { key, isEaten } = e.data.payload;
                handleIsEatenMeal(key, isEaten);
            } else if (e.data.action === 'updated_measurements') {
                handleMeasurement();
            } else if (e.data.action === 'updated_intermittent_fasting') {
                handleIntermittentFasting();
            }
        });
    }, []);

    const handleFavouriteMeal = (key, mealId, isFavorite) => {
        client.cache.modify({
            id: `UserDailyDietMeal:{"key":"${key}"}`,
            fields: {
                isFavorite() {
                    return isFavorite;
                },
            },
        });

        client.cache.modify({
            id: `UserDailyDietPlanEventMeal:{"key":"${key}"}`,
            fields: {
                isFavorite() {
                    return isFavorite;
                },
            },
        });

        if (!isFavorite) {
            client.cache.evict({ id: `DietMeal:${mealId}` });
            client.cache.gc();
        } else {
            client.query({
                query: FavMealsQuery,
                variables: { text: '', date: formatIsoDate(new Date()) },
                fetchPolicy: 'network-only',
            });
        }
    };

    const handleIsEatenMeal = (key, isEaten) => {
        client.cache.modify({
            id: `UserDailyDietMeal:{"key":"${key}"}`,
            fields: {
                isEaten() {
                    return isEaten;
                },
            },
        });

        client.cache.modify({
            id: `UserDailyDietPlanEventMeal:{"key":"${key}"}`,
            fields: {
                isEaten() {
                    return isEaten;
                },
            },
        });
    };

    const handleMeasurement = () => {
        client.query({
            query: CurrentMeasurementsQuery,
            fetchPolicy: 'network-only',
        });
        client.query({
            query: MeasurementHistoryQuery,
            variables: {
                datePeriod: {
                    start: formatIsoDate(
                        subDays(PERIOD_END, PERIOD_LENGTH - 1)
                    ),
                    end: formatIsoDate(PERIOD_END),
                },
                [MEASUREMENT_TYPES.WEIGHT]: true,
            },
            fetchPolicy: 'network-only',
        });
    };

    const handleIntermittentFasting = () => {
        client.query({
            query: DietPlanQuery,
            variables: {
                today: formatIsoDate(new Date()),
            },
            fetchPolicy: 'network-only',
        });
    };

    return null;
};

export default OnMessageCacheModifier;
