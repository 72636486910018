import CouponIndexPage from 'Coupon/CouponIndexPage';
import CouponActivatePage from 'Coupon/Activate/CouponActivatePage';

const PATH_COUPON_INDEX = '/aktywacja';
const PATH_COUPON_ACTIVATE = '/coupon/access-code';

const routes = [
    {
        path: PATH_COUPON_INDEX,
        component: CouponIndexPage,
        private: false,
        exact: true,
        title: 'route-title/default',
    },
    {
        path: PATH_COUPON_ACTIVATE,
        component: CouponActivatePage,
        private: true,
        exact: true,
        title: 'route-title/default',
    },
];

export { routes, PATH_COUPON_ACTIVATE };
