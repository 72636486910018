import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import { Link } from 'react-router-dom';
import { PATHS } from 'config/paths';
import ShoppingCartDelivery from 'view/ShoppingCart/Delivery/ShoppingCartDelivery';

export class ShoppingCartOrderDeliverySummaryComponent extends React.Component {
    render() {
        const {
            name,
            address,
            city,
            country,
            phone,
            postalCode,
        } = this.props.delivery;
        return (
            <section>
                <Container>
                    <h2>{this.props.t('cart/address-of-delivery')}</h2>
                    <br />
                    <p className="mb-0">
                        <span data-test="name">{name}</span>,{' '}
                        <span data-test="address">{address}</span>,{' '}
                        <span data-test="postalCode">{postalCode}</span>{' '}
                        <span data-test="city">{city}</span>,{' '}
                        <span data-test="country">{country.name}</span>,{' '}
                        <span className="text-lowercase">
                            {this.props.t('cart/delivery/phone')}
                        </span>
                        : <span data-test="phone">{phone}</span>
                        {this.props.isAbleToProcess ? (
                            <React.Fragment>
                                {' '}
                                -{' '}
                                <Link
                                    data-test="change"
                                    className="grey"
                                    to={PATHS.CART.INDEX}
                                >
                                    {this.props.t('cart/change')}
                                </Link>
                            </React.Fragment>
                        ) : (
                            ''
                        )}
                    </p>
                </Container>
            </section>
        );
    }
}

ShoppingCartOrderDeliverySummaryComponent.propTypes = {
    t: PropTypes.func.isRequired,
    isAbleToProcess: PropTypes.bool.isRequired,
    delivery: PropTypes.instanceOf(ShoppingCartDelivery).isRequired,
};

export default withLocale(ShoppingCartOrderDeliverySummaryComponent);
