import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import { DateType } from 'types/CommonTypes';
import ShoppingListQuery from './ShoppingListQuery';

const ShoppingListConnectorApollo = ({ children, period }) => {
    const { loading, error, data } = useQuery(ShoppingListQuery, {
        variables: period ? { period } : {},
    });

    const result = {
        loading,
        error,
    };

    if (
        !loading &&
        data.me.shoppingList &&
        data.me.shoppingList.__typename === 'UserShoppingList'
    ) {
        const categories = data.me.shoppingList.category.map(singleCategory => {
            const newCategory = {
                ...singleCategory,
                products: singleCategory.product,
            };
            delete newCategory.product;

            return newCategory;
        });

        const shoppingList = {
            ...data.me.shoppingList,
            categories,
        };

        delete shoppingList.category;

        result.shoppingList = shoppingList;
        result.availableDays = data.me.diet.availableDays.map(i => i.date);
    }

    return children(result);
};

ShoppingListConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
    period: PropTypes.shape({
        start: DateType.isRequired,
        end: DateType.isRequired,
    }),
};

ShoppingListConnectorApollo.defaultProps = {
    period: undefined,
};

export default ShoppingListConnectorApollo;
