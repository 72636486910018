import React, { Component } from 'react';
import {
    FormGroup,
    Label,
    Input,
    InputGroup,
    InputGroupAddon,
    FormFeedback,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { InputErrorType } from 'types/CommonTypes';
import withRequired from './withRequired';

class InputNumber extends Component {
    render() {
        let errorMgs;

        if (this.props.errors) {
            this.props.errors.details.forEach(error => {
                if (error.fieldName === this.props.name) {
                    errorMgs = error.messages;
                }
            });
        }

        const formGroupClasses = [];
        if (this.props.hide) {
            formGroupClasses.push('d-none');
        } else {
            formGroupClasses.push('d-block');
        }
        if (errorMgs) {
            formGroupClasses.push('is-invalid');
        }

        return (
            <FormGroup className={formGroupClasses.join(' ')}>
                {this.props.label ? (
                    <Label for={this.props.name}>
                        {this.props.label}{' '}
                        {this.props.required ? <sup>*</sup> : ''}
                    </Label>
                ) : (
                    ''
                )}
                <InputGroup
                    className={
                        errorMgs ? 'form-control is-invalid' : 'form-control'
                    }
                >
                    <Input
                        type="number"
                        step={this.props.step}
                        name={this.props.name}
                        value={this.props.value || ''}
                        onChange={this.props.handleChange}
                        onBlur={this.props.onBlur}
                        required={this.props.required}
                        disabled={!!this.props.disabled}
                    />
                    <InputGroupAddon addonType="append">
                        {this.props.unit}
                    </InputGroupAddon>
                </InputGroup>
                <FormFeedback>
                    {errorMgs
                        ? errorMgs.map((message, k) => (
                              <li key={k}>{message}</li>
                          ))
                        : ''}
                </FormFeedback>
            </FormGroup>
        );
    }
}

InputNumber.propTypes = {
    errors: InputErrorType,
    label: PropTypes.node,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    handleChange: PropTypes.func.isRequired,
    unit: PropTypes.string,
    value: PropTypes.number,
    step: PropTypes.number,
    validationErrors: PropTypes.object, //eslint-disable-line
    onBlur: PropTypes.func,
};

InputNumber.defaultProps = {
    errors: undefined,
    required: false,
    disabled: false,
    unit: undefined,
    value: undefined,
    step: 0.01,
    validationErrors: undefined,
    label: null,
    onBlur: undefined,
};

export default withRequired(InputNumber);
