import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import Layout from 'Layout/Layout';

export class ShoppingCartConfirmPlaceholder extends React.Component {
    render() {
        return (
            <Layout page="cart">
                <header className="d-none d-md-block">
                    <h1 className="text-center ">
                        {this.props.t('cart/order-summary')}
                    </h1>
                </header>

                <section className="cart-summary">
                    <Container>
                        <h2>{this.props.t('cart/summary')}</h2>
                        <Row>
                            <Col>{this.props.t('cart/summary/products')}</Col>
                            <Col className="price">
                                <div className="placeholder">&nbsp;</div>
                            </Col>
                        </Row>
                        <Row className="delivery-option-price">
                            <Col>{this.props.t('cart/summary/delivery')}</Col>
                            <Col className="price">
                                <div className="placeholder">&nbsp;</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <strong>
                                    {this.props.t('cart/summary/total')}
                                </strong>
                            </Col>
                            <Col className="price">
                                <div className="placeholder">&nbsp;</div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section>
                    <Container>
                        <h2>{this.props.t('cart/payment')}</h2>
                        <br />
                        <div className="payment-method d-flex align-items-center">
                            <Row className="align-items-center w-100">
                                <Col xs={4} className="text-center">
                                    <div className="placeholder logo-placeholder" />
                                </Col>
                                <Col xs={8}>
                                    <h3 className="placeholder">&nbsp;</h3>
                                    <p className="placeholder">&nbsp;</p>
                                </Col>
                            </Row>
                        </div>
                        <div className="payment-method d-flex align-items-center">
                            <Row className="align-items-center w-100">
                                <Col xs={4} className="text-center">
                                    <div className="placeholder logo-placeholder" />
                                </Col>
                                <Col xs={8}>
                                    <h3 className="placeholder">&nbsp;</h3>
                                    <p className="placeholder">&nbsp;</p>
                                </Col>
                            </Row>
                        </div>
                        <div className="payment-method d-flex align-items-center">
                            <Row className="align-items-center w-100">
                                <Col xs={4} className="text-center">
                                    <div className="placeholder logo-placeholder" />
                                </Col>
                                <Col xs={8}>
                                    <h3 className="placeholder">&nbsp;</h3>
                                    <p className="placeholder">&nbsp;</p>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </section>
            </Layout>
        );
    }
}

ShoppingCartConfirmPlaceholder.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withLocale(ShoppingCartConfirmPlaceholder);
