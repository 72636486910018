import SaleProduct from '../../Sale/Product/SaleProduct';

export default class ShoppingCartProduct {
    constructor(saleProduct, amount, lastSource) {
        if (saleProduct instanceof SaleProduct) {
            this.saleProduct = saleProduct;
        } else {
            throw new Error('saleProduct has to be instance of SaleProduct');
        }

        this.id = saleProduct.id;
        this.name = saleProduct.name;
        this.price = saleProduct.price;
        this.isPhysical = saleProduct.isPhysical;
        this.initAmount(amount);
        this.lastSource = lastSource;
        this.vendorIdentifier = saleProduct.vendorIdentifier;
    }

    initAmount(amount) {
        if (!amount) {
            throw new Error('Amount has to be set');
        }
        this.amount = this.isItPossibleToBuyMoreThanOne
            ? Number(amount)
            : Math.min(Number(amount), 1);
    }

    get sumPrice() {
        return this.price.promo.multiply(this.amount);
    }

    get diffPrice() {
        return this.price.regular.subtract(this.price.promo);
    }

    get isItPossibleToBuyMoreThanOne() {
        return this.isPhysical;
    }
}
