import React from 'react';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import Layout from 'Layout/Layout';
import Header from 'Layout/Header';
import PageContainer from 'Layout/Container/PageContainer';
import CurrentMeasurementsConnectorApollo from '@dietlabs/components/src/ApolloConnectors/Measurement/CurrentMeasurements/CurrentMeasurementsConnectorApollo';
import CurrentMeasurementsContainer from 'Measurement/CurrentMeasurements/CurrentMeasurementsContainer';
import MeasurementHistoryConnectorApollo from '@dietlabs/components/src/ApolloConnectors/Measurement/MeasurementHistory/MeasurementHistoryConnectorApollo';
import {
    MEASUREMENT_TYPES,
    PERIOD_END,
    PERIOD_LENGTH,
} from 'Measurement/constants';
import MeasurementHistoryContainer from 'Measurement/MeasurementHistory/MeasurementHistoryContainer';

class MeasurementsPage extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    render() {
        const { t } = this.props;
        return (
            <Layout page="measurement">
                <div className="bg-white">
                    <Header>{t('measurments/title')}</Header>
                    <PageContainer>
                        <CurrentMeasurementsConnectorApollo>
                            {currentMeasurementData => (
                                <CurrentMeasurementsContainer
                                    {...currentMeasurementData}
                                />
                            )}
                        </CurrentMeasurementsConnectorApollo>

                        <MeasurementHistoryConnectorApollo
                            initialMeasurementType={MEASUREMENT_TYPES.WEIGHT}
                            periodLength={PERIOD_LENGTH}
                            periodEnd={PERIOD_END}
                        >
                            {measurementHistoryData => (
                                <MeasurementHistoryContainer
                                    {...measurementHistoryData}
                                />
                            )}
                        </MeasurementHistoryConnectorApollo>
                    </PageContainer>
                </div>
            </Layout>
        );
    }
}

export default withLocale(MeasurementsPage);
