import React from 'react';
import { Redirect } from 'react-router';
import PropTypes from 'prop-types';
import { Alert, Form, Button, Row, Col } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import { PATH_DAY_PLAN_INDEX } from 'DayPlan/DayPlanPaths';

import InputText from 'components/FormElements/InputTextControlled';
import Loader from 'Layout/Loader';

import { FlashMessageSuccess } from '@dietlabs/components/src/FlashMessage/FlashMessage';
import { FLASH_MESSAGES } from 'consts';
import withFlashMessage from '@dietlabs/components/src/FlashMessage/withFlashMessage';

import { validateField } from 'view/Validation/validateField';
import { validateFields } from 'view/Validation/validateFields';
import RequiredRule from 'view/Validation/ValidationRules/RequiredRule';
import ValidationErrors from 'view/Validation/ValidationErrors';

export class ActivateFormComponent extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        activateCoupon: PropTypes.func.isRequired,
        addMessage: PropTypes.func.isRequired,
    };

    state = {
        accessCode: '',
        errors: new ValidationErrors(),
        errorActivate: false,
        redirectToDayPlan: false,
        preload: false,
    };

    validationRules = {
        accessCode: [new RequiredRule({ translator: this.props.t })],
    };

    handleSubmit = async event => {
        event.preventDefault();

        const frontEndErrors = validateFields(
            this.validationRules,
            this.state,
            this.props.t
        );

        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                details: frontEndErrors,
            },
        }));

        if (frontEndErrors.length === 0) {
            this.setState({ preload: true });

            const request = {
                accessCode: this.state.accessCode,
            };

            try {
                const response = await this.props.activateCoupon(request);
                const { code } = response.data.me.coupon.activate;

                if (code === 200) {
                    this.props.addMessage(
                        new FlashMessageSuccess(
                            this.props.t('coupon/activated-successfully'),
                            FLASH_MESSAGES.COUPON.SUCCESS
                        )
                    );
                    this.setState({
                        redirectToDayPlan: true,
                        preload: false,
                    });
                } else {
                    this.setState({
                        preload: false,
                        errors: response.data.me.coupon.activate,
                        errorActivate: true,
                    });
                }
            } catch (e) {
                this.setState({ errorActivate: true, preload: false });
                throw new Error(`Failed to activate coupon, got error: ${e}`);
            }
        }
    };

    handleInputChange = event => {
        this.setState({ [event.target.name]: event.target.value });
        this.validateInput(event.target.name, event.target.value);
    };

    validateInput = (field, value) => {
        if (this.validationRules[field]) {
            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    details: validateField(
                        field,
                        value,
                        this.validationRules[field],
                        prevState
                    ),
                },
            }));
        }
    };

    render() {
        if (this.state.redirectToDayPlan) {
            return <Redirect to={PATH_DAY_PLAN_INDEX} />;
        }
        return (
            <Row>
                <Col xs="12" sm={{ size: 6, offset: 3 }}>
                    {this.state.errorActivate ? (
                        <Alert color="danger" data-test="activate-error">
                            {this.props.t('error/message/generic')}
                        </Alert>
                    ) : (
                        ''
                    )}
                    <Form
                        onSubmit={this.handleSubmit}
                        data-test="coupon-activate-form"
                        className="text-center"
                        noValidate
                    >
                        <InputText
                            label={this.props.t('coupon/access-code')}
                            name="accessCode"
                            value={this.state.accessCode}
                            errors={this.state.errors}
                            handleChange={this.handleInputChange}
                            validationRules={this.validationRules.accessCode}
                            data-test="access-code-input"
                        />

                        <Button color="primary" data-test="send-button">
                            {this.props.t('coupon/send')}
                        </Button>
                    </Form>
                </Col>
                {this.state.preload ? <Loader /> : ''}
            </Row>
        );
    }
}

export default withFlashMessage(withLocale(ActivateFormComponent));
