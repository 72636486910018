import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withLoading from 'Layout/withLoading';
import Layout from 'Layout/Layout';
import { withSnackbar } from 'react-simple-snackbar';
import { Container, Row, Col, Button } from 'reactstrap';
import InputCheckbox from 'components/FormElements/InputCheckboxControlled2';

import { CSSTransition } from 'react-transition-group';

import IngredientList from 'DayPlan/components/DayPlanIngredientList';
import Menu from 'Layout/Menu';
import Footer from 'Layout/Footer';
import withMobileAppMode from 'Layout/withMobileAppMode';
import favouritesIcon from 'assets/icons/favourites/favourites.svg';
import withFirebase from 'view/FirebaseAnalytics/withFirebase';
import Dish from './FavMealDish';
import Ingredient from './FavMealIngredient';
import FavMealsIndexPlaceholder from './FavMealsPlaceholder';
import FavMealsFilters from './FavMealsFilters';
import FavMeal from './FavMeal';
import FavMealDetails from './FavMealDetails';
import FavMealsSearchForm from './FavMealsSearchForm';

class FavMealsIndexContainer extends Component {
    static propTypes = {
        favMeals: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        t: PropTypes.func.isRequired,
        mobileAppMode: PropTypes.bool.isRequired,
        removeMealFromFavorites: PropTypes.shape().isRequired,
        addMealToFavorites: PropTypes.shape().isRequired,
        trackFirebaseEvent: PropTypes.func.isRequired,
        currentPhase: PropTypes.string.isRequired,
        refetch: PropTypes.func.isRequired,
    };

    state = {
        active: 'all',
        search: '',
        showModal: false,
        mealId: undefined,
        favMeals: this.props.favMeals,
        useSearchResult: false,
        showOnlyCurrentPhase: false,
    };

    componentDidUpdate() {
        let favouritesNumber = 0;
        this.state.favMeals.forEach(meal => {
            if (
                meal.name === this.state.active ||
                this.state.active === 'all'
            ) {
                favouritesNumber += 1;
            }
        });

        this.props.trackFirebaseEvent('screen_view', {
            firebase_screen: 'Favourites',
            favourites_category: this.state.active,
            favourites_number: favouritesNumber,
        });
    }

    setActive(category) {
        this.setState({ active: category });
    }

    handleChange = event => {
        if (event.target.name === 'showOnlyCurrentPhase') {
            this.setState({ [event.target.name]: event.target.checked });
            this.props.trackFirebaseEvent('user_action', {
                action: 'tap-diet_phase_meals ',
                location: 'Favourites ',
            });
        } else {
            this.setState({ [event.target.name]: event.target.value });
        }
    };

    handleSubmit = async event => {
        event.preventDefault();
        await this.props.refetch({ text: this.state.search });
        if (this.state.search) {
            this.setState({ useSearchResult: true });
        } else {
            this.setState({ useSearchResult: false });
        }

        this.props.trackFirebaseEvent('user_action', {
            action: 'search_favourites',
            location: 'Favourites ',
        });
    };

    toggleModal(mealId) {
        if (mealId) {
            this.setState(prevState => ({
                showModal: !prevState.showModal,
                mealId,
            }));
        } else {
            this.setState(prevState => ({
                showModal: !prevState.showModal,
            }));
            setTimeout(() => {
                this.setState({ mealId });
            }, 500);
        }
    }

    renderIngredients = ingredients =>
        ingredients.map(ingredient => (
            <Ingredient
                key={ingredient.productId}
                name={ingredient.name}
                measurements={ingredient.measurements}
                saleProductVariant={ingredient.saleProductVariant}
                productId={ingredient.productId}
            />
        ));

    renderIngredientList = ingredients => {
        const filteredIngredients = ingredients.filter(
            ingredient =>
                !ingredient.category.isSeasoning &&
                !ingredient.category.isOptional
        );

        const onlySeasoningsIngredients = ingredients.filter(
            ingredient => ingredient.category.isSeasoning
        );

        const optionalIngredients = ingredients.filter(
            ingredient => ingredient.category.isOptional
        );

        return (
            <IngredientList
                renderIngredients={() =>
                    this.renderIngredients(filteredIngredients)
                }
                renderOnlySeasoningsIngredients={() =>
                    this.renderIngredients(onlySeasoningsIngredients)
                }
                renderOptionalIngredients={() =>
                    this.renderIngredients(optionalIngredients)
                }
            />
        );
    };

    renderDishes = (dishes, name) =>
        dishes.map(dish => (
            <Dish
                key={dish.id}
                name={dish.name}
                mealName={name}
                recipe={dish.recipe}
                recipeNote={dish.recipeNote}
                isFirstOccurance={dish.isFirstOccurance}
                isLastOccurance={dish.isLastOccurance}
                isPortioned={dish.isPortioned}
                portions={dish.portions}
                portionsTotal={dish.portionsTotal}
                data-test="dish-component"
            >
                {() => this.renderIngredientList(dish.ingredients)}
            </Dish>
        ));

    renderFavMealDetails = () => {
        if (this.state.favMeals) {
            return (
                <>
                    {this.state.favMeals.map(meal => {
                        if (meal.id === this.state.mealId) {
                            return (
                                <FavMealDetails
                                    key={meal.id}
                                    mealId={meal.id}
                                    preparationTime={meal.preparationTime}
                                    name={meal.name}
                                    kcal={meal.kcal}
                                    macro={meal.macro}
                                    toggleModal={() => this.toggleModal()}
                                    publicName={meal.publicName}
                                    dishes={meal.dishes}
                                    preparationImageUrl={
                                        meal.preparationImageUrl
                                    }
                                    removeMealFromFavorites={
                                        this.props.removeMealFromFavorites
                                    }
                                    addMealToFavorites={
                                        this.props.addMealToFavorites
                                    }
                                    showModal={this.state.showModal}
                                    phase={meal.phase}
                                >
                                    {() =>
                                        this.renderDishes(
                                            meal.dishes,
                                            meal.name
                                        )
                                    }
                                </FavMealDetails>
                            );
                        }
                        return '';
                    })}
                </>
            );
        }
        return '';
    };

    renderTopBar = (withMenu, withBackButton) => (
        <React.Fragment>
            {withMenu && <Menu forceReloadLinks={false} />}
            <div className="top-bar border-bottom">
                <Container className="py-3 px-3 px-md-0 d-flex align-items-center">
                    {withBackButton ? (
                        <>
                            <Button
                                color="secondary"
                                size="sm"
                                onClick={() => window.history.back()}
                                className="mr-2 outline with-arrow-prev"
                                style={{ minWidth: '0px' }}
                                outline
                            >
                                <span className="d-none d-md-inline">
                                    {this.props.t('back')}
                                </span>
                            </Button>
                            <h1 className="text-center w-100 mb-0">
                                {this.props.t('meal/meal-details')}
                            </h1>
                        </>
                    ) : (
                        <h1 className="text-center w-100 mb-0">
                            {this.props.t('meal/favourite-meals')}
                        </h1>
                    )}
                    <div className="clearfix" />
                </Container>
            </div>
        </React.Fragment>
    );

    renderFavMeals = () => {
        if (this.props.favMeals) {
            if (this.state.useSearchResult) {
                if (this.props.favMeals.length > 0) {
                    const filteredFavMeals = this.props.favMeals.filter(
                        meal =>
                            ((meal.name === this.state.active ||
                                this.state.active === 'all') &&
                                this.state.showOnlyCurrentPhase &&
                                (meal.phase.colour ===
                                    this.props.currentPhase ||
                                    !this.props.currentPhase)) ||
                            (!this.state.showOnlyCurrentPhase &&
                                (meal.name === this.state.active ||
                                    this.state.active === 'all'))
                    );

                    return (
                        <Row>
                            {filteredFavMeals.map(meal => (
                                <FavMeal
                                    key={meal.id}
                                    mealId={meal.id}
                                    preparationTime={meal.preparationTime}
                                    name={meal.name}
                                    kcal={meal.kcal}
                                    macro={meal.macro}
                                    toggleModal={mealId =>
                                        this.toggleModal(mealId)
                                    }
                                    dishes={meal.dishes}
                                    preparationImageUrl={
                                        meal.preparationImageUrl
                                    }
                                    publicName={meal.publicName}
                                    removeMealFromFavorites={
                                        this.props.removeMealFromFavorites
                                    }
                                    addMealToFavorites={
                                        this.props.addMealToFavorites
                                    }
                                    phase={meal.phase}
                                />
                            ))}
                        </Row>
                    );
                }

                return (
                    <Row className="mt-5">
                        <Col
                            md={{ size: 6, offset: 3 }}
                            className="text-center"
                        >
                            <p className="grey">
                                {this.props.t(
                                    'meal/favourite-meals/no-search-results'
                                )}
                            </p>
                        </Col>
                    </Row>
                );
            }

            if (this.props.favMeals.length > 0) {
                const filteredFavMeals = this.props.favMeals.filter(
                    meal =>
                        ((meal.name === this.state.active ||
                            this.state.active === 'all') &&
                            this.state.showOnlyCurrentPhase &&
                            (meal.phase.colour === this.props.currentPhase ||
                                !this.props.currentPhase)) ||
                        (!this.state.showOnlyCurrentPhase &&
                            (meal.name === this.state.active ||
                                this.state.active === 'all'))
                );

                return (
                    <Row>
                        {filteredFavMeals.map(meal => (
                            <FavMeal
                                key={meal.id}
                                mealId={meal.id}
                                preparationTime={meal.preparationTime}
                                name={meal.name}
                                kcal={meal.kcal}
                                macro={meal.macro}
                                toggleModal={mealId => this.toggleModal(mealId)}
                                dishes={meal.dishes}
                                preparationImageUrl={meal.preparationImageUrl}
                                publicName={meal.publicName}
                                removeMealFromFavorites={
                                    this.props.removeMealFromFavorites
                                }
                                addMealToFavorites={
                                    this.props.addMealToFavorites
                                }
                                phase={meal.phase}
                            />
                        ))}
                    </Row>
                );
            }

            return (
                <Row className="mt-5">
                    <Col md={{ size: 6, offset: 3 }} className="text-center">
                        <img src={favouritesIcon} alt="" />
                        <p className="grey">
                            {this.props.t('meal/favourite-meals/no-meals')}
                        </p>
                    </Col>
                </Row>
            );
        }

        return '';
    };

    render() {
        return (
            <Layout page="fav-meals">
                <div
                    className={
                        this.state.showModal
                            ? 'modalContainer showModal'
                            : 'modalContainer'
                    }
                >
                    {!this.props.mobileAppMode
                        ? this.renderTopBar(false, false)
                        : ''}

                    {((this.props.favMeals && this.props.favMeals.length > 0) ||
                        this.state.useSearchResult) && (
                        <>
                            <FavMealsFilters
                                favMeals={this.props.favMeals}
                                active={this.state.active}
                                setActive={category => this.setActive(category)}
                                search={this.state.search}
                                handleChange={event => this.handleChange(event)}
                                handleSubmit={event => this.handleSubmit(event)}
                            />

                            <FavMealsSearchForm
                                search={this.state.search}
                                handleChange={event => this.handleChange(event)}
                                handleSubmit={event => this.handleSubmit(event)}
                            />
                        </>
                    )}

                    {/* content */}
                    <section className="pt-4">
                        <Container>
                            {this.state.favMeals &&
                                this.state.favMeals.length > 0 && (
                                    <InputCheckbox
                                        label={this.props.t(
                                            'meal/show-only-current-phase'
                                        )}
                                        id="showOnlyCurrentPhase"
                                        name="showOnlyCurrentPhase"
                                        value={this.state.showOnlyCurrentPhase}
                                        handleChange={event =>
                                            this.handleChange(event)
                                        }
                                        checked={Boolean(
                                            this.state.showOnlyCurrentPhase
                                        )}
                                    />
                                )}

                            <Row>
                                <Col md={{ size: 12, offset: 0 }}>
                                    {this.renderFavMeals()}
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </div>

                <CSSTransition
                    in={this.state.showModal}
                    timeout={500}
                    classNames="view"
                    unmountOnExit
                >
                    <div className="view bg-white">
                        {!this.props.mobileAppMode
                            ? this.renderTopBar(true, true)
                            : ''}
                        {this.renderFavMealDetails()}
                        {!this.props.mobileAppMode && (
                            <Footer forceReloadLinks={false} />
                        )}
                    </div>
                </CSSTransition>
            </Layout>
        );
    }
}

export { FavMealsIndexContainer };
export default withLoading(
    withFirebase(
        withMobileAppMode(
            withSnackbar(withMobileAppMode(FavMealsIndexContainer))
        )
    ),
    FavMealsIndexPlaceholder
);
