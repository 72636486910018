import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavItem } from 'reactstrap';
import { Link } from 'react-scroll';

class DayPlanMealLink extends Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        mealId: PropTypes.number.isRequired,
    };

    render() {
        return (
            <NavItem>
                <Link
                    className="nav-link"
                    activeClass="active"
                    to={`meal${this.props.mealId}`}
                    spy
                    smooth
                    offset={-40}
                >
                    {this.props.name}
                </Link>
            </NavItem>
        );
    }
}

export default DayPlanMealLink;
