import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import withStep from './StepContext/withStep';

class StepActions extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        prevStep: PropTypes.func.isRequired,
        nextStep: PropTypes.func.isRequired,
        isNextButtonDisabled: PropTypes.bool.isRequired,
        currentStep: PropTypes.string.isRequired,
        preload: PropTypes.bool.isRequired,
    };

    render() {
        const {
            nextStep,
            prevStep,
            isNextButtonDisabled,
            currentStep,
            preload,
        } = this.props;

        if (currentStep === 'start') {
            return (
                <Row>
                    <Col className="text-center">
                        <Button
                            color="primary"
                            onClick={nextStep()}
                            disabled={isNextButtonDisabled}
                            className="with-arrow-next"
                        >
                            {this.props.t('diet-settings/cta')}
                        </Button>
                    </Col>
                </Row>
            );
        }

        if (currentStep === 'acceptance') {
            return (
                <Row>
                    <Col className="text-center">
                        <Button
                            color="primary"
                            onClick={nextStep()}
                            disabled={isNextButtonDisabled || preload}
                        >
                            {preload && (
                                <span
                                    className="spinner-border spinner-border-sm mr-2"
                                    role="status"
                                    aria-hidden="true"
                                />
                            )}
                            {this.props.t('diet-settings/acceptance/confirm')}
                        </Button>
                    </Col>
                </Row>
            );
        }

        if (currentStep === 'diet-programme') {
            return (
                <Row>
                    <Col className="text-center">
                        <Button
                            color="primary"
                            onClick={nextStep()}
                            disabled={isNextButtonDisabled || preload}
                            className="with-arrow-next"
                        >
                            {preload && (
                                <span
                                    className="spinner-border spinner-border-sm mr-2"
                                    role="status"
                                    aria-hidden="true"
                                />
                            )}
                            {this.props.t('next')}
                        </Button>
                    </Col>
                </Row>
            );
        }

        if (currentStep === 'finish') {
            return '';
        }

        return (
            <div className="d-flex justify-content-around">
                <Button
                    color="secondary"
                    onClick={prevStep()}
                    type="button"
                    className="with-arrow-prev"
                />

                <Button
                    color="primary"
                    onClick={nextStep()}
                    disabled={isNextButtonDisabled || preload}
                    className="with-arrow-next"
                >
                    {preload && (
                        <span
                            className="spinner-border spinner-border-sm mr-2"
                            role="status"
                            aria-hidden="true"
                        />
                    )}
                    {this.props.t('next')}
                </Button>
            </div>
        );
    }
}

export default withStep(withLocale(StepActions));
