import PropTypes from 'prop-types';
import { useMutation, useApolloClient } from '@apollo/client';
import RemoveMealFromFavoritesMutation from './RemoveMealFromFavoritesMutation';

const RemoveMealFromFavoritesConnectorApollo = ({ children }) => {
    const client = useApolloClient();

    const [removeMealFromFavorites, { data, loading, error }] = useMutation(
        RemoveMealFromFavoritesMutation
    );

    const optimisticResponse = {
        me: {
            removeMealFromFavoritesByMealId: {
                code: 200,
                __typename: 'BasicMutationSuccess',
            },
        },
    };

    const createRemoveMealFromFavorites = async mealId => {
        const serializedState = client.cache.extract();

        const UserDailyDietPlanEventMealItems = Object.values(
            serializedState
        ).filter(
            item =>
                item.__typename === 'UserDailyDietPlanEventMeal' &&
                item.id === mealId
        );

        const UserDailyDietMealItems = Object.values(serializedState).filter(
            item =>
                item.__typename === 'UserDailyDietMeal' && item.id === mealId
        );

        await removeMealFromFavorites({
            variables: { mealId },
            optimisticResponse,
            update: cache => {
                UserDailyDietPlanEventMealItems.forEach(item => {
                    cache.modify({
                        id: `UserDailyDietPlanEventMeal:{"key":"${item.key}"}`,
                        fields: {
                            isFavorite(existing) {
                                return !existing;
                            },
                        },
                        broadcast: true,
                    });
                });
                UserDailyDietMealItems.forEach(item => {
                    cache.modify({
                        id: `UserDailyDietMeal:{"key":"${item.key}"}`,
                        fields: {
                            isFavorite(existing) {
                                return !existing;
                            },
                        },
                        broadcast: true,
                    });
                });
            },
        });

        client.cache.evict({ id: `DietMeal:${mealId}` });
        client.cache.gc();
    };

    return children({
        removeMealFromFavorites: {
            removeMeal: createRemoveMealFromFavorites,
            response: data,
            loading,
            error,
        },
    });
};

RemoveMealFromFavoritesConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
};

export default RemoveMealFromFavoritesConnectorApollo;
