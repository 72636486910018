import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';
import InputRadio from 'components/FormElements/InputRadioControlled';
import InputNumber from 'components/FormElements/InputNumberControlled';
import { withLocale } from '@dietlabs/components';
import withFirebase from 'view/FirebaseAnalytics/withFirebase';
import withStep from './StepContext/withStep';

class StepGoal extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        dietMode: PropTypes.string,
        goalWeight: PropTypes.shape({
            value: PropTypes.number,
            unit: PropTypes.string.isRequired,
        }).isRequired,
        handleInputChange: PropTypes.func.isRequired,
        changeNextBtnState: PropTypes.func.isRequired,
        errors: PropTypes.shape(),
        validateData: PropTypes.func.isRequired,
        trackFirebaseEvent: PropTypes.func.isRequired,
    };

    static defaultProps = {
        dietMode: undefined,
        errors: undefined,
    };

    componentDidMount() {
        this.checkErrors();

        this.props.trackFirebaseEvent('screen_view ', {
            firebase_screen: 'Goal_target_weight',
        });
    }

    componentDidUpdate() {
        this.checkErrors();
    }

    checkErrors = () => {
        let errors = false;

        if (this.props.errors) {
            this.props.errors.details.forEach(error => {
                if (error.fieldName === 'goalWeight.value') {
                    errors = true;
                }
            });
        }

        this.props.changeNextBtnState(
            !(
                this.props.dietMode === 'stabilization' ||
                (this.props.dietMode && this.props.goalWeight.value && !errors)
            )
        );
    };

    render() {
        const { dietMode, goalWeight, handleInputChange, errors } = this.props;

        const optionalGoalWeightProps = {};
        if (goalWeight.value) {
            optionalGoalWeightProps.value = Number(goalWeight.value);
        }

        return (
            <div className="full-screen3">
                <div className="mb-auto w-100">
                    <FormGroup tag="fieldset">
                        <Label>
                            {this.props.t('diet-settings/goal/label')}:
                        </Label>
                        <InputRadio
                            label={
                                <strong>
                                    {this.props.t(
                                        'diet-settings/goal/slimming'
                                    )}
                                </strong>
                            }
                            name="dietMode"
                            value="slimming"
                            handleChange={handleInputChange}
                            checked={dietMode === 'slimming'}
                            data-test="dietMode-slimming"
                        />
                        <InputRadio
                            label={
                                <strong>
                                    {this.props.t(
                                        'diet-settings/goal/stabilization'
                                    )}
                                </strong>
                            }
                            name="dietMode"
                            value="stabilization"
                            handleChange={handleInputChange}
                            checked={dietMode === 'stabilization'}
                            data-test="dietMode-stabilization"
                        />
                        <InputRadio
                            label={
                                <strong>
                                    {this.props.t(
                                        'diet-settings/goal/put-on-weight'
                                    )}
                                </strong>
                            }
                            name="dietMode"
                            value="put_on_weight"
                            handleChange={handleInputChange}
                            checked={dietMode === 'put_on_weight'}
                            data-test="dietMode-put-on-weight"
                        />
                    </FormGroup>

                    <InputNumber
                        label={
                            <strong>
                                {this.props.t('diet-settings/goal-weight')}
                            </strong>
                        }
                        name="goalWeight.value"
                        unit={goalWeight.unit}
                        errors={errors}
                        step={0.1}
                        handleChange={handleInputChange}
                        onBlur={() => this.props.validateData()}
                        {...optionalGoalWeightProps}
                        hide={!dietMode || dietMode === 'stabilization'}
                        data-test="goal-weight-input"
                    />
                </div>
            </div>
        );
    }
}

export default withFirebase(withStep(withLocale(StepGoal)));
