import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import { FlashMessageError } from '@dietlabs/components/src/FlashMessage/FlashMessage';
import { Button } from 'reactstrap';
import { PATHS } from 'config/paths';
import { Link } from 'react-router-dom';
import Loader from 'Layout/Loader';
import { FLASH_MESSAGES } from 'consts';
import withFlashMessage from '@dietlabs/components/src/FlashMessage/withFlashMessage';
import removeAuthCredentials from '@dietlabs/components/src/Auth/removeAuthCredentials';

export class MeComponent extends Component {
    static propTypes = {
        authenticationTokenIsSet: PropTypes.bool.isRequired,
        shouldActivateAccount: PropTypes.bool.isRequired,
        userDataOnDemand: PropTypes.func.isRequired,
        activateAccount: PropTypes.func.isRequired,
        addMessage: PropTypes.func.isRequired,
        t: PropTypes.func.isRequired,
        token: PropTypes.string,
        children: PropTypes.node.isRequired,
    };

    static defaultProps = {
        token: undefined,
    };

    state = {
        initialized: false,
    };

    async componentDidMount() {
        if (this.props.authenticationTokenIsSet) {
            const response = await this.props.userDataOnDemand();

            // user does not exist
            if (!response.data.me) {
                removeAuthCredentials();
                this.setState({ initialized: true });
                return;
            }

            this.displayNoPasswordFlashMessage(response);
            if (
                this.props.shouldActivateAccount &&
                response.data.me.status === 'STATUS_NON_ACTIVE'
            ) {
                await this.props.activateAccount();
            }
        }
        this.setState({ initialized: true });
    }

    // load user data
    async componentDidUpdate(prevProps) {
        if (prevProps.token !== this.props.token) {
            if (this.props.authenticationTokenIsSet) {
                const response = await this.props.userDataOnDemand();

                // user does not exist
                if (!response.data.me) {
                    removeAuthCredentials();
                    return;
                }

                this.displayNoPasswordFlashMessage(response);
            }
        }
    }

    displayNoPasswordFlashMessage(response) {
        if (
            !response.data.me.passwordIsSet &&
            !response.data.me.hasExternalProviderSetUp
        ) {
            this.props.addMessage(
                new FlashMessageError(
                    (
                        <div className="text-center">
                            <p>{this.props.t('auth/password-is-not-set')}</p>
                            <hr />
                            <Button
                                color="primary"
                                tag={Link}
                                to={PATHS.AUTH.SET_PASSWORD}
                            >
                                {this.props.t('auth/set-password')}
                            </Button>
                        </div>
                    ),
                    FLASH_MESSAGES.AUTH.SET_INITIAL_PASSWORD.NO_PASSWORD
                )
            );
        }
    }

    render() {
        return (
            <Fragment>
                {this.state.initialized ? (
                    this.props.children
                ) : (
                    <div className="full-height">
                        <Loader />
                    </div>
                )}
            </Fragment>
        );
    }
}

export default withFlashMessage(withLocale(MeComponent));
