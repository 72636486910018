import React from 'react';

import ContentConnectorApollo from '@dietlabs/components/src/ApolloConnectors/Content/ContentConnectorApollo';
import DietFaqContainer from './DietFaqContainer';

class DietFaqPage extends React.Component {
    static propTypes = {};

    render() {
        return (
            <ContentConnectorApollo identifier="faq:dietitian">
                {({ ...contentData }) => <DietFaqContainer {...contentData} />}
            </ContentConnectorApollo>
        );
    }
}

export default DietFaqPage;
