import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { formatIsoDate, addDays } from 'utils/dateFormatter';
import DayPlanCopyMutation from './DayPlanCopyMutation';
import DailyDietPlanQuery from '../Fetch/DailyDietPlanQuery';

const CopyDailyDietPlanConnectorApollo = ({ children, date }) => {
    // const client = useApolloClient();

    const [copyDailyDietPlan, { data, loading, error }] = useMutation(
        DayPlanCopyMutation,
        {
            refetchQueries: [
                {
                    query: DailyDietPlanQuery,
                    variables: {
                        date: formatIsoDate(addDays(date, 1)),
                        canBeCopiedToDate: formatIsoDate(addDays(date, 2)),
                    },
                },
            ],
        }
    );

    const createCopyDayPlan = async () => {
        await copyDailyDietPlan({
            variables: {
                sourceDate: formatIsoDate(date),
                targetDate: formatIsoDate(addDays(date, 1)),
            },
        });

        // client.cache.evict({ id: `DietMeal:${mealId}` });
        // client.cache.gc();
    };

    return children({
        copyDayPlan: {
            copy: createCopyDayPlan,
            response: data,
            loading,
            error,
        },
    });
};

CopyDailyDietPlanConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
    date: PropTypes.instanceOf(Date).isRequired,
};

export default CopyDailyDietPlanConnectorApollo;
