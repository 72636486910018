const config = {
    apiGateway: {
        url: process.env.REACT_APP_GRAPHQL_URL,
    },
    flags: {
        activities: false,
        cart: true,
        shoppingListActions: true,
        termsOfService: {
            web: true,
            android: true,
            ios: true,
            huawei: false,
        },
    },
};

export default config;
export const { flags } = config;
