import { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import flattenValidationExceptionMessages from 'Hydration/utils/flattenValidationExceptionMessages';
import removePortionmutation from './HydrationDayPortionRemoveMutation';

export function getLastPortionId(portions) {
    const lastPortion =
        portions && portions.length && portions[portions.length - 1];
    return lastPortion ? lastPortion.id : null;
}

const HydrationDayPortionRemoveConnectorApollo = ({
    children,
    queryResult,
}) => {
    const [mutate, { data }] = useMutation(removePortionmutation);
    const [lastPortionId, setLastPortionId] = useState();

    let removePortionMessages = [];
    if (data) {
        removePortionMessages = flattenValidationExceptionMessages(
            data.me.hydrationDayRemovePortion
        );
    }

    useEffect(() => {
        if (queryResult) {
            setLastPortionId(
                getLastPortionId(queryResult.me.hydrationDay.portions)
            );
        }
    }, [queryResult]);

    async function createPortionRemove(portionId) {
        const {
            hydrationDay: hydrationDayResult,
            ...meResult
        } = queryResult.me;

        setLastPortionId(getLastPortionId(hydrationDayResult.portions));
        const variables = {
            portionId,
        };

        const optimisticResponse = {
            me: {
                ...meResult,
                hydrationDayRemovePortion: {
                    ...hydrationDayResult,
                    portions: hydrationDayResult.portions.filter(
                        ({ id }) => id !== portionId
                    ),
                    value: {
                        ...hydrationDayResult.value,
                        value:
                            hydrationDayResult.value.value -
                            hydrationDayResult.portions.find(
                                ({ id }) => id === portionId
                            ).value.value,
                    },
                },
            },
        };

        await mutate({
            variables,
            optimisticResponse,
            update: (cache, { data: response }) => {
                cache.modify({
                    id: cache.identify(queryResult.me),
                    fields: {
                        hydrationDay(existing) {
                            return {
                                ...existing,
                                portions:
                                    response.me.hydrationDayRemovePortion
                                        .portions,
                                value:
                                    response.me.hydrationDayRemovePortion.value,
                            };
                        },
                    },
                    broadcast: true,
                });
                cache.gc();
            },
        });
    }

    const removeLastPortion = lastPortionId
        ? () => createPortionRemove(lastPortionId)
        : null;

    return children(removeLastPortion, removePortionMessages);
};

HydrationDayPortionRemoveConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    queryResult: PropTypes.object.isRequired,
};

export { HydrationDayPortionRemoveConnectorApollo };
export default HydrationDayPortionRemoveConnectorApollo;
