export default class UrlFormatRule {
    constructor(options = {}) {
        const t = options.translator;
        if (!t) {
            throw new Error('translator option is required in EmailFormatRule');
        }
        this.messages = {
            urlFormat: t('validation/url-format'),
            ...options.messages,
        };
    }

    isValid = value => {
        const urlformat = /^(http:\/\/www.|https:\/\/www.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

        if (value.match(urlformat)) {
            return true;
        }
        return false;
    };

    getErrorMsg() {
        return this.messages.urlFormat;
    }
}
