import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import ArticleFetchConnectorApollo from '@dietlabs/components/src/ApolloConnectors/Knowledge/Fetch/ArticleFetchConnectorApollo';
import ArticleContainer from './ArticleContainer';

class ArticlePage extends React.Component {
    static propTypes = {
        match: PropTypes.shape({
            params: PropTypes.shape({
                article: PropTypes.string,
            }).isRequired,
        }).isRequired,
    };

    render() {
        const id = parseInt(this.props.match.params.article, 10);

        return (
            <ArticleFetchConnectorApollo articleId={id}>
                {({ ...article }) => <ArticleContainer {...article} />}
            </ArticleFetchConnectorApollo>
        );
    }
}

export default withRouter(ArticlePage);
