import GraphQLFetch from '@dietlabs/components/src/GraphQL/GraphQLFetch';
import ShoppingCartProduct from 'view/ShoppingCart/Product/ShoppingCartProduct';
import SaleProduct from 'view/Sale/Product/SaleProduct';
import Price from 'view/Price/Price';
import SaleProductPrice from 'view/Sale/Product/Price/SaleProductPrice';
import ShoppingCartDelivery from 'view/ShoppingCart/Delivery/ShoppingCartDelivery';
import Country from 'view/Country/Country';
import Query from './PaymentLoadByTokenQuery';
import Payment from '../../Payment';

export const paymentLoadByToken = token =>
    GraphQLFetch.runQuery(Query, { token }).then(res => {
        const rawPayment = res.data.sale.payment.getByToken;

        if (!rawPayment) {
            return false;
        }

        const products = (rawPayment.products || []).map(rawProduct => {
            if (
                rawProduct.saleProductVariant.id === undefined ||
                rawProduct.saleProductVariant.saleProduct.name === undefined ||
                rawProduct.price.amount === undefined ||
                rawProduct.price.currency === undefined ||
                rawProduct.saleProductVariant.saleProduct.isVirtual ===
                    undefined ||
                rawProduct.saleProductVariant.saleProduct.containsDiet ===
                    undefined ||
                rawProduct.saleProductVariant.saleProduct.containsWorkout ===
                    undefined ||
                rawProduct.amount === undefined
            ) {
                throw new Error();
            }

            return new ShoppingCartProduct(
                new SaleProduct(
                    rawProduct.saleProductVariant.id,
                    rawProduct.saleProductVariant.saleProduct.name,
                    new SaleProductPrice(
                        new Price(
                            rawProduct.price.amount,
                            rawProduct.price.currency
                        )
                    ),
                    !rawProduct.saleProductVariant.saleProduct.isVirtual,
                    rawProduct.saleProductVariant.saleProduct.containsDiet,
                    rawProduct.saleProductVariant.saleProduct.containsWorkout,
                    rawProduct.saleProductVariant.saleProduct.tags,
                    rawProduct.saleProductVariant.saleProduct.isBestseller,
                    rawProduct.saleProductVariant.saleProduct.shortDescription,
                    rawProduct.saleProductVariant.saleProduct.longDescription,
                    rawProduct.saleProductVariant.saleProduct.lengthLabel,
                    rawProduct.saleProductVariant.saleProduct.vendor
                        ? rawProduct.saleProductVariant.saleProduct.vendor
                              .identifier
                        : ''
                ),
                rawProduct.amount
            );
        });

        if (
            rawPayment.price.amount === undefined ||
            rawPayment.price.currency === undefined
        ) {
            throw new Error();
        }
        const totalPrice = new Price(
            rawPayment.price.amount,
            rawPayment.price.currency
        );

        if (
            rawPayment.shipping.price.amount === undefined ||
            rawPayment.shipping.price.currency === undefined
        ) {
            throw new Error();
        }

        const deliveryOption = {
            price: new Price(
                rawPayment.shipping.price.amount,
                rawPayment.shipping.price.currency
            ),
            id: rawPayment.shipping.saleShipping.id,
        };

        let delivery = new ShoppingCartDelivery();
        if (rawPayment.shipment) {
            delivery = new ShoppingCartDelivery(
                rawPayment.shipment.name,
                rawPayment.shipment.address,
                rawPayment.shipment.postalCode,
                rawPayment.shipment.city,
                new Country(
                    rawPayment.shipment.country.code,
                    rawPayment.shipment.country.name
                ),
                rawPayment.shipment.phone
            );
        }

        const user = rawPayment.user;

        return new Payment(
            token,
            products,
            totalPrice,
            deliveryOption,
            delivery,
            rawPayment.status,
            rawPayment.isCod,
            user
        );
    });
