import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
    Container,
    Row,
    Col,
    Button,
    Form,
    FormGroup,
    Label,
    InputGroup,
    Input,
} from 'reactstrap';

import { withLocale } from '@dietlabs/components';

class FavMealsSearchForm extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        search: PropTypes.string.isRequired,
        handleChange: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
    };

    render() {
        return (
            <section className="search d-none d-md-block pt-4">
                <Container>
                    <Row className="mt-3">
                        <Col md={{ size: 8, offset: 2 }}>
                            <Form onSubmit={this.props.handleSubmit}>
                                <FormGroup row>
                                    <Col xs="12">
                                        <Label for="search" className="mr-sm-2">
                                            {this.props.t(
                                                'meal/show-favourite-meals'
                                            )}
                                        </Label>
                                    </Col>
                                    <Col lg="8">
                                        <InputGroup className="form-control">
                                            <Input
                                                type="search"
                                                name="search"
                                                placeholder={this.props.t(
                                                    'meal/name-of-dish-or-ingredient'
                                                )}
                                                value={this.props.search}
                                                onChange={
                                                    this.props.handleChange
                                                }
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col lg="4" className="text-center">
                                        <Button
                                            color="secondary"
                                            className="mt-3 mt-lg-1 w-100"
                                        >
                                            {this.props.t('meal/search')}
                                        </Button>
                                    </Col>
                                </FormGroup>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}

export default withLocale(FavMealsSearchForm);
