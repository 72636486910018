import {
    ARTICLE_SEND_REQUEST,
    ARTICLE_RECEIVE_RESPONSE,
} from './ArticleFetchActionType';

export default function(state, action) {
    let result = state;

    switch (action.type) {
        case ARTICLE_SEND_REQUEST:
            result = {
                ...state,
            };
            break;
        case ARTICLE_RECEIVE_RESPONSE:
            result = {
                ...state,
                article: action.article,
            };
            break;
    }

    return result;
}
