import getLanguage from 'utils/getLanguage';

const supportsNumberFormat = () =>
    global.Intl &&
    typeof global.Intl === 'object' &&
    typeof global.Intl.NumberFormat === 'function';

const formattersByLangAndCurrency = new Map();

function getFormatter(lang, currency) {
    const key = [lang, currency].join();
    let formatter = formattersByLangAndCurrency.get(key);
    if (!formatter) {
        formatter = new global.Intl.NumberFormat(lang, {
            style: 'currency',
            currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        });
        formattersByLangAndCurrency.set(key, formatter);
    }

    return formatter;
}

function formatPriceNative(amount, currency) {
    const lang = getLanguage();
    const formatter = getFormatter(lang, currency);
    return formatter.format(amount);
}

function formatPriceFallback(amount, currency) {
    const roundAmount = Number(amount)
        ? Math.round(Number(amount) * 100) / 100
        : 0;
    const amountInt = parseInt(roundAmount, 10);
    let amountFrac = parseInt((roundAmount - amountInt) * 100, 10);
    if (amountFrac) {
        amountFrac = `.${amountFrac}`;
    } else {
        amountFrac = '';
    }
    return `${amountInt}${amountFrac} ${currency}`;
}

function formatPrice({ amount, currency }) {
    if (!supportsNumberFormat()) {
        return formatPriceFallback(amount, currency);
    }

    return formatPriceNative(amount, currency);
}

export { formatPrice };
