import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button } from 'reactstrap';

import { withLocale } from '@dietlabs/components';

import withGTM from 'view/GoogleTagManager/withGTM';
import { EVENT_CATEGORY_HYDRATION } from 'view/GoogleTagManager/EventCategories';

class HydrationDayProgress extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        trackEvent: PropTypes.func.isRequired,
        removeLastPortion: PropTypes.func,
        messages: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    };

    static defaultProps = {
        removeLastPortion: null,
    };

    trackClick = buttonName => {
        this.props.trackEvent(EVENT_CATEGORY_HYDRATION, `${buttonName} click`);
    };

    render() {
        const { t, removeLastPortion } = this.props;

        return (
            <Fragment>
                {this.props.messages.map(msg => (
                    <Alert key={msg} color="danger">
                        {msg}
                    </Alert>
                ))}
                <div className="text-center">
                    <Button
                        color="link"
                        disabled={!removeLastPortion}
                        onClick={() => {
                            if (removeLastPortion) {
                                removeLastPortion();
                                this.trackClick('remove');
                            }
                        }}
                    >
                        {t('hydration/remove-last-portion')}
                    </Button>
                </div>
            </Fragment>
        );
    }
}

export { HydrationDayProgress };
export default withGTM(withLocale(HydrationDayProgress));
