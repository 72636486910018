import React from 'react';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';

import ShoppingListConnectorApollo from '@dietlabs/components/src/ApolloConnectors/ShoppingList/ShoppingListConnectorApollo';
import ShoppingListMarkProductConnectorApollo from '@dietlabs/components/src/ApolloConnectors/ShoppingList/ShoppingListMarkProductConnectorApollo ';

import ShoppingListIndexContainer from 'ShoppingList/Index/ShoppingListIndexContainer';

import Layout from 'Layout/Layout';
import Header from 'Layout/Header';
import PageContainer from 'Layout/Container/PageContainer';

class ShoppingListPage extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    state = {
        period: undefined,
    };

    handlePeriodChange = period => {
        this.setState({ period });
        global.localStorage.setItem(
            'shoppingListPeriod',
            JSON.stringify(period)
        );
    };

    render() {
        const { t } = this.props;

        let period = JSON.parse(
            global.localStorage.getItem('shoppingListPeriod')
        );

        if (!period) {
            period = this.state.period;
        }

        return (
            <Layout page="shopping-list">
                <div className="bg-white">
                    <Header>{t('shopping-list/title')}</Header>
                    <PageContainer>
                        <ShoppingListConnectorApollo period={period}>
                            {shoppingListData => (
                                <ShoppingListMarkProductConnectorApollo
                                    {...shoppingListData.shoppingList}
                                >
                                    {({ handleMarkProduct }) => (
                                        <ShoppingListIndexContainer
                                            {...{
                                                handleMarkProduct,
                                            }}
                                            {...shoppingListData}
                                            handlePeriodChange={
                                                this.handlePeriodChange
                                            }
                                        />
                                    )}
                                </ShoppingListMarkProductConnectorApollo>
                            )}
                        </ShoppingListConnectorApollo>
                    </PageContainer>
                </div>
            </Layout>
        );
    }
}

export { ShoppingListPage };
export default withLocale(ShoppingListPage);
