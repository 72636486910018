import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import UserDataQuery from './UserDataQuery';

const UserDataConnectorApollo = ({ children }) => {
    const [loadUserData] = useLazyQuery(UserDataQuery);

    const fetch = async token => {
        const result = await loadUserData({
            fetchPolicy: 'no-cache',
            context: {
                headers: {
                    'X-Authentication': token,
                },
            },
        });

        return result;
    };

    return children({
        userDataOnDemand: fetch,
    });
};

UserDataConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
};

export default UserDataConnectorApollo;
