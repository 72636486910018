import gql from 'graphql-tag';

const query = gql`
    query DietSettingsValidate(
        $request: UserDietSettingsValidationFastingInputType
    ) {
        me {
            validateDietSettings(settings: $request) {
                ... on ValidationException {
                    code
                    messages
                    details {
                        fieldName
                        messages
                    }
                }
            }
        }
    }
`;

export default query;
