import {
    ARTICLE_LIST_SEND_REQUEST,
    ARTICLE_LIST_RECEIVE_RESPONSE,
} from './ArticleListFetchActionType';

export default function(state, action) {
    let result = state;

    switch (action.type) {
        case ARTICLE_LIST_SEND_REQUEST:
            result = {
                ...state,
            };
            break;
        case ARTICLE_LIST_RECEIVE_RESPONSE:
            result = {
                ...state,
                articleList: action.articleList,
            };
            break;
    }

    return result;
}
