/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import PropTypes from 'prop-types';
import withMobileAppMode from 'Layout/withMobileAppMode';
import { withSnackbar } from 'react-simple-snackbar';

import start from 'assets/images/dayplan/start.jpg';
import breakfast from 'assets/images/dayplan/breakfast.jpg';
import dinner from 'assets/images/dayplan/dinner.jpg';
import snack from 'assets/images/dayplan/snack.jpg';
import supper from 'assets/images/dayplan/supper.jpg';

import playBtn from 'assets/images/dayplan/play-button.svg';

import { callNativeAppRouteChanged } from 'components/Helpers/callNativeAppRouteChanged';
import { callNativeAppRemoveLastRoute } from 'components/Helpers/callNativeAppRemoveLastRoute';

import favouriteFull from 'assets/images/favourites/favourite-full.svg';

import withFirebase from 'view/FirebaseAnalytics/withFirebase';

const steps = [5, 15, 30, 45, 60];

class FavMealDetails extends Component {
    static propTypes = {
        mealId: PropTypes.number.isRequired,
        preparationTime: PropTypes.shape({
            years: PropTypes.number,
            days: PropTypes.number,
            hours: PropTypes.number,
            minutes: PropTypes.number,
            seconds: PropTypes.number,
        }).isRequired,
        kcal: PropTypes.number.isRequired,
        macro: PropTypes.shape({
            animalProtein: PropTypes.shape({
                grams: PropTypes.number.isRequired,
                kcal: PropTypes.number.isRequired,
                percentage: PropTypes.number.isRequired,
            }).isRequired,
            carbohydrates: PropTypes.shape({
                grams: PropTypes.number.isRequired,
                kcal: PropTypes.number.isRequired,
                percentage: PropTypes.number.isRequired,
            }).isRequired,
            fat: PropTypes.shape({
                grams: PropTypes.number.isRequired,
                kcal: PropTypes.number.isRequired,
                percentage: PropTypes.number.isRequired,
            }).isRequired,
            protein: PropTypes.shape({
                grams: PropTypes.number.isRequired,
                kcal: PropTypes.number.isRequired,
                percentage: PropTypes.number.isRequired,
            }).isRequired,
        }).isRequired,
        t: PropTypes.func.isRequired,
        children: PropTypes.func.isRequired,
        toggleModal: PropTypes.func.isRequired,
        name: PropTypes.string.isRequired,
        mobileAppMode: PropTypes.bool.isRequired,
        preparationVideoUrl: PropTypes.string,
        preparationImageUrl: PropTypes.string,
        publicName: PropTypes.string.isRequired,
        removeMealFromFavorites: PropTypes.shape({
            removeMeal: PropTypes.func.isRequired,
            response: PropTypes.shape(),
            loading: PropTypes.bool,
            error: PropTypes.shape(),
        }).isRequired,
        addMealToFavorites: PropTypes.shape({
            addMeal: PropTypes.func.isRequired,
            response: PropTypes.shape(),
            loading: PropTypes.bool,
            error: PropTypes.shape(),
        }).isRequired,
        openSnackbar: PropTypes.func.isRequired,
        showModal: PropTypes.bool.isRequired,
        trackFirebaseEvent: PropTypes.func.isRequired,
        phase: PropTypes.shape({
            colour: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        }).isRequired,
    };

    static defaultProps = {
        preparationVideoUrl: undefined,
        preparationImageUrl: undefined,
    };

    state = {
        bodyScroll: document.body.getBoundingClientRect().top,
    };

    static calculatePreparationTime(minutes) {
        if (minutes === 0 || minutes == null) {
            return null;
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const step of steps) {
            if (minutes <= step) {
                return `< ${step}`;
            }
        }
        return `> ${steps[steps.length - 1]}`;
    }

    componentDidMount() {
        document.body.style.overflow = 'hidden';

        // hack to hide large title on iOS
        setTimeout(() => {
            window.scrollTo(0, 100);
        }, 500);

        if (window.location.href.includes('szczegoly-posilku')) {
            callNativeAppRemoveLastRoute('diet');
            // routeChanged post msg
            callNativeAppRouteChanged(
                window.location.href,
                'diet',
                this.props.t('meal/meal-details')
            );
        } else {
            // disable browser back button
            window.history.pushState(
                null,
                null,
                `${window.location.pathname}?szczegoly-posilku`
            );

            // routeChanged post msg
            callNativeAppRouteChanged(
                window.location.href,
                'diet',
                this.props.t('meal/meal-details')
            );
        }

        window.onpopstate = () => {
            this.props.toggleModal();
        };

        this.props.trackFirebaseEvent('screen_view ', {
            firebase_screen: 'Favourite_meal_details',
            meal_category: this.props.name,
            meal_id: this.props.mealId,
            diet_phase: this.props.phase.colour,
        });
    }

    componentWillUnmount() {
        document.body.style.overflow = 'unset';
        window.scrollTo(0, -this.state.bodyScroll);
        window.onpopstate = () => {};
    }

    handleRemoveMeal = async () => {
        if (this.props.showModal) {
            window.history.go(-1);
            callNativeAppRemoveLastRoute('diet');
        }

        try {
            const mealId = this.props.mealId;
            await this.props.removeMealFromFavorites.removeMeal(mealId);
            if (
                this.props.removeMealFromFavorites.response.me
                    .removeMealFromFavoritesByMealId.__typename ===
                'BasicMutationSuccess'
            ) {
                this.props.openSnackbar(
                    <p>
                        {this.props.t('fav-meals/removed')}
                        <Button
                            color="link"
                            onClick={() => this.handleAddMeal(mealId)}
                        >
                            {this.props.t('fav-meals/back')}
                        </Button>
                    </p>
                );

                this.props.trackFirebaseEvent('user_action', {
                    action: 'tap-delete_favourites',
                    location: 'Favourites',
                    meal_id: this.props.mealId,
                    meal_category: this.props.name,
                    diet_phase: this.props.phase.colour,
                });
            }
        } catch (error) {
            throw new Error(`Failed to remove meal from favorites: ${error}`);
        }
    };

    handleAddMeal = async mealId => {
        try {
            await this.props.addMealToFavorites.addMeal(mealId);
        } catch (error) {
            throw new Error(`Failed to add meal to favorites: ${error}`);
        }
    };

    renderDietPhase() {
        return (
            <div className={`diet-phase ${this.props.phase.colour}`}>
                {this.props.phase.name}
            </div>
        );
    }

    playVideo = async () => {
        this.setState({ visiblePoster: false });
        this.props.trackFirebaseEvent('user_action', {
            action: 'tap-watch_video',
            location: 'Meal_details',
            meal_id: this.props.mealId,
            meal_category: this.props.name,
        });

        try {
            await this.videoRef.current.play();
        } catch (err) {
            // eslint-disable-next-line no-console
            console.warn(err);
        }
    };

    renderMealVideo = () => {
        if (!this.props.preparationVideoUrl) {
            return this.renderMealImage();
        }

        return (
            <div className="embed-responsive embed-responsive-1by1">
                <video
                    ref={this.videoRef}
                    src={this.props.preparationVideoUrl}
                    controls
                    className="embed-responsive-item"
                    playsInline
                    controlsList="nodownload"
                    disablePictureInPicture
                />
                {this.state.visiblePoster ? (
                    <div
                        style={{
                            backgroundImage: `url(${this.props.preparationImageUrl
                                .replace('(', '%28')
                                .replace(')', '%29')})`,
                        }}
                        className="embed-responsive-item d-flex align-items-center justify-content-center"
                        onClick={this.playVideo}
                    >
                        <img src={playBtn} alt="" />
                        <div style={{ position: 'absolute', bottom: '1rem' }}>
                            <div className="label mr-2">{this.props.name}</div>
                            {this.renderDietPhase()}
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </div>
        );
    };

    renderMealImage = () => {
        let mealImg;

        if (this.props.preparationImageUrl) {
            mealImg = this.props.preparationImageUrl;
            return (
                <div className="embed-responsive embed-responsive-1by1">
                    <div
                        style={{
                            backgroundImage: `url(${mealImg})`,
                        }}
                        className="embed-responsive-item d-flex align-items-center justify-content-center"
                    >
                        <div style={{ position: 'absolute', bottom: '1rem' }}>
                            <div className="label mr-2">{this.props.name}</div>
                            {this.renderDietPhase()}
                        </div>
                    </div>
                </div>
            );
        }

        switch (this.props.name) {
            case 'Start':
                mealImg = start;
                break;
            case 'Śniadanie':
                mealImg = breakfast;
                break;
            case 'Obiad':
                mealImg = dinner;
                break;
            case 'Przekąska':
                mealImg = snack;
                break;
            case 'Kolacja':
                mealImg = supper;
                break;
            default:
                mealImg = breakfast;
        }

        return (
            <React.Fragment>
                <div className="embed-responsive d-md-none d-flex align-items-center justify-content-center">
                    <img src={mealImg} className="img-fluid" alt="" />
                    <div style={{ position: 'absolute', bottom: '1rem' }}>
                        <div className="label mr-2">{this.props.name}</div>
                        {this.renderDietPhase()}
                    </div>
                </div>

                <div className="embed-responsive embed-responsive-1by1 d-none d-md-block">
                    <div
                        style={{
                            backgroundImage: `url(${mealImg})`,
                        }}
                        className="embed-responsive-item d-flex align-items-center justify-content-center"
                    >
                        <div style={{ position: 'absolute', bottom: '1rem' }}>
                            <div className="label mr-2">{this.props.name}</div>
                            {this.renderDietPhase()}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    render() {
        const preparationTime = this.constructor.calculatePreparationTime(
            this.props.preparationTime.minutes
        );

        return (
            <section className="meal-details">
                <Container>
                    <Row className="align-items-center">
                        <Col md="5">
                            {this.renderMealVideo()}
                            <button
                                className="add-remove-fav mt-2 mr-md-4"
                                type="button"
                                onClick={() => this.handleRemoveMeal()}
                            >
                                <img src={favouriteFull} alt="" />
                            </button>
                        </Col>
                        <Col md={{ size: 6, offset: 1 }}>
                            <div className="meal-info">
                                {!this.props.mobileAppMode && (
                                    <h1 className="d-none d-md-block">
                                        {this.props.publicName}
                                    </h1>
                                )}

                                <div className="macro-and-kcal">
                                    <Row>
                                        <Col xs={{ size: 8, offset: 2 }}>
                                            <h3 className="mb-0 text-center">
                                                {this.props.t('meal/time')}{' '}
                                                <strong>
                                                    {preparationTime}{' '}
                                                    {this.props.t('meal/min')}
                                                </strong>
                                            </h3>
                                        </Col>
                                    </Row>
                                    {this.props.phase.colour !== 'fast' && (
                                        <>
                                            <hr />
                                            <div className="d-flex">
                                                <div className="flex-grow-1 text-center border-right">
                                                    <h3 className="mb-0">
                                                        <strong>
                                                            {this.props.kcal}
                                                        </strong>
                                                    </h3>
                                                    kcal
                                                </div>

                                                <div className="flex-grow-1 text-center border-right">
                                                    <h3 className="mb-0">
                                                        <strong>
                                                            {
                                                                this.props.macro
                                                                    .protein
                                                                    .grams
                                                            }
                                                            &nbsp;g
                                                        </strong>
                                                    </h3>
                                                    {this.props.t(
                                                        'meal/proteins'
                                                    )}
                                                </div>

                                                <div className="flex-grow-1 text-center border-right">
                                                    <h3 className="mb-0">
                                                        <strong>
                                                            {
                                                                this.props.macro
                                                                    .fat.grams
                                                            }
                                                            &nbsp;g
                                                        </strong>
                                                    </h3>
                                                    {this.props.t('meal/fat')}
                                                </div>

                                                <div className="flex-grow-1 text-center">
                                                    <h3 className="mb-0 primary semi-bold">
                                                        <strong>
                                                            {
                                                                this.props.macro
                                                                    .carbohydrates
                                                                    .grams
                                                            }
                                                            &nbsp;g
                                                        </strong>
                                                    </h3>
                                                    {this.props.t(
                                                        'meal/carbohydrates'
                                                    )}
                                                </div>
                                            </div>
                                            {/* <Row>
                                                <Col
                                                    xs="3"
                                                    className="text-center border-right"
                                                >
                                                    <h3 className="mb-0">
                                                        <strong>
                                                            {this.props.kcal}
                                                        </strong>
                                                    </h3>
                                                    kcal
                                                </Col>
                                                <Col
                                                    xs="3"
                                                    className="text-center border-right"
                                                >
                                                    <h3 className="mb-0">
                                                        <strong>
                                                            {
                                                                this.props.macro
                                                                    .protein
                                                                    .grams
                                                            }
                                                            &nbsp;g
                                                        </strong>
                                                    </h3>
                                                    {this.props.t(
                                                        'meal/proteins'
                                                    )}
                                                </Col>
                                                <Col
                                                    xs="3"
                                                    className="text-center border-right"
                                                >
                                                    <h3 className="mb-0">
                                                        <strong>
                                                            {
                                                                this.props.macro
                                                                    .fat.grams
                                                            }
                                                            &nbsp;g
                                                        </strong>
                                                    </h3>
                                                    {this.props.t('meal/fat')}
                                                </Col>
                                                <Col
                                                    xs="3"
                                                    className="text-center"
                                                >
                                                    <h3 className="mb-0 primary semi-bold">
                                                        <strong>
                                                            {
                                                                this.props.macro
                                                                    .carbohydrates
                                                                    .grams
                                                            }
                                                            &nbsp;g
                                                        </strong>
                                                    </h3>
                                                    {this.props.t(
                                                        'meal/carbohydrates'
                                                    )}
                                                </Col>
                                            </Row> */}
                                        </>
                                    )}
                                </div>
                            </div>
                        </Col>
                    </Row>

                    {this.props.children()}
                </Container>
            </section>
        );
    }
}

export default withFirebase(
    withSnackbar(withMobileAppMode(withLocale(FavMealDetails)))
);
