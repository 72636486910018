import React, { Component } from 'react';

import Layout from 'Layout/Layout';
import { subDays } from 'utils/date';

import HistoryConnector from '@dietlabs/components/src/ApolloConnectors/Hydration/History/HydrationHistoryConnectorApollo';
import HistoryContainer from './HydrationHistoryContainer';

class HydrationHistoryPage extends Component {
    state = {
        length: 7,
    };

    setLength = length => this.setState({ length });

    render() {
        const to = new Date();
        const from = subDays(to, this.state.length - 1);

        return (
            <Layout page="hydration">
                <div className="full-screen">
                    <HistoryConnector dateFrom={from} dateTo={to}>
                        {({ loading2, unit, defaultDailyGoal, days }) => (
                            <HistoryContainer
                                loading={loading2}
                                dateFrom={from}
                                dateTo={to}
                                unit={unit}
                                defaultDailyGoal={defaultDailyGoal}
                                days={days}
                                historyLength={this.state.length}
                                setHistoryLength={this.setLength}
                            />
                        )}
                    </HistoryConnector>
                </div>
            </Layout>
        );
    }
}

export { HydrationHistoryPage };
export default HydrationHistoryPage;
