import { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { DateType } from 'types/CommonTypes';
import ShoppingListMarkProductAsBoughtMutation from './ShoppingListMarkProductAsBoughtMutation';
import optimisticResponseBought from './ShoppingListMarkProductAsBoughtOptimisticResponse';
import optimisticResponseNotBought from './ShoppingListMarkProductAsNotBoughtOptimisticResponse';
import ShoppingListMarkProductAsNotBoughtMutation from './ShoppingListMarkProductAsNotBoughtMutation';

const MARK = 'mark';
const UNMARK = 'unMark';

const ShoppingListMarkProductConnectorApollo = ({ children, period }) => {
    const [markValue, setMarkValue] = useState();
    const [mutate] = useMutation(
        markValue === MARK
            ? ShoppingListMarkProductAsBoughtMutation
            : ShoppingListMarkProductAsNotBoughtMutation
    );

    const handleMarkProduct = async (
        markValueSign,
        { key, productId, __typename }
    ) => {
        if (markValueSign !== MARK && markValueSign !== UNMARK) {
            throw new Error('Invalid markValue. Enter "mark" or "unMark"');
        }
        if (!period) {
            throw new Error(
                'Tried to mark product as bought with undefined period'
            );
        }

        setMarkValue(markValueSign);
        const { start, end } = period;

        const variables = {
            request: {
                productId,
                datePeriod: { start, end },
            },
        };

        await mutate({
            variables,
            optimisticResponse:
                markValueSign === MARK
                    ? optimisticResponseBought
                    : optimisticResponseNotBought,
            update: (cache, { data }) => {
                const { isBought } =
                    markValueSign === MARK
                        ? data.me.shoppingListMarkProductAsBought
                        : data.me.shoppingListMarkProductAsNotBought;

                const cachedProductToFindById = {
                    __typename,
                    key,
                };
                cache.modify({
                    id: cache.identify(cachedProductToFindById),
                    fields: {
                        isBought() {
                            return isBought;
                        },
                    },
                });
            },
        });
    };

    return children({
        handleMarkProduct,
    });
};

ShoppingListMarkProductConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
    period: PropTypes.shape({
        start: DateType.isRequired,
        end: DateType.isRequired,
    }),
};

ShoppingListMarkProductConnectorApollo.defaultProps = {
    period: undefined,
};

export default ShoppingListMarkProductConnectorApollo;
