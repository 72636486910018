import React from 'react';
import SendRequestToRemoveAccountConnectorApollo from '@dietlabs/components/src/ApolloConnectors/UserSettings/RemoveAccount/SendRequestToRemoveAccount/SendRequestToRemoveAccountConnectorApollo';
import ActiveSubscriptionConnectorApollo from '@dietlabs/components/src/ApolloConnectors/UserSettings/RemoveAccount/ActiveSubscription/ActiveSubscriptionConnectorApollo';
import RemoveAccountRequestContainer from './RemoveAccountRequestContainer';

const RemoveAccountRequestPage = () => {
    return (
        <ActiveSubscriptionConnectorApollo>
            {ActiveSubscriptionData => (
                <SendRequestToRemoveAccountConnectorApollo>
                    {SendRequestToRemoveAccountData => {
                        return (
                            <RemoveAccountRequestContainer
                                loading={
                                    SendRequestToRemoveAccountData.loading ||
                                    ActiveSubscriptionData.loading
                                }
                                sendRequestToRemoveAccount={() =>
                                    SendRequestToRemoveAccountData.sendRequestToRemoveAccount()
                                }
                                activeSubscription={
                                    ActiveSubscriptionData.activeSubscriptions
                                }
                                userEmail={ActiveSubscriptionData.email}
                            />
                        );
                    }}
                </SendRequestToRemoveAccountConnectorApollo>
            )}
        </ActiveSubscriptionConnectorApollo>
    );
};

export default RemoveAccountRequestPage;
