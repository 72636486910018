import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { formatIsoDate } from 'utils/dateFormatter';
import { subDays } from 'utils/date';
import { MEASUREMENT_TYPES } from 'Measurement/constants';
import DietSettingsQuery from 'DietSettings/GraphQL/DietSettingsQuery';
import ChangeSettingsMutation from './ChangeSettingsMutation';
import HydationDayQuery from '../../Hydration/FetchHydrationDay/HydrationDayQuery';
import CurrentMeasurementsQuery from '../../Measurement/CurrentMeasurements/CurrentMeasurementsQuery';
import MeasurementHistoryQuery from '../../Measurement/MeasurementHistory/MeasurementHistoryQuery';

const RATIO = 28.4130625;

const todayDate = formatIsoDate(new Date());
const periodEnd = new Date();
const periodLength = 31;

const optimisticResponse = {
    me: {
        setting: {
            code: 200,
            __typename: 'BasicMutationSuccess',
        },
        __typename: 'MeMutation',
    },
};

const convertToOz = value => {
    return Math.round(value * (1 / RATIO));
};

const convertToMl = value => {
    return Math.round((value * RATIO) / 50) * 50;
};

const ChangeSettingsConnectorApollo = ({ children, rawQueryResult }) => {
    const [mutate, { loading }] = useMutation(ChangeSettingsMutation, {
        refetchQueries: [
            {
                query: DietSettingsQuery,
            },
            {
                query: HydationDayQuery,
                variables: { date: todayDate },
            },
            {
                query: CurrentMeasurementsQuery,
            },
            {
                query: MeasurementHistoryQuery,
                variables: {
                    datePeriod: {
                        start: formatIsoDate(
                            subDays(periodEnd, periodLength - 1)
                        ),
                        end: formatIsoDate(periodEnd),
                    },
                    [MEASUREMENT_TYPES.WEIGHT]: true,
                },
            },
        ],
    });

    const changeSettings = async systemOfMeasures => {
        await mutate({
            variables: {
                request: {
                    systemOfMeasures,
                },
            },
            optimisticResponse,
            update: cache => {
                cache.modify({
                    id: cache.identify(rawQueryResult.me),
                    fields: {
                        systemOfMeasures() {
                            return systemOfMeasures;
                        },
                        hydrationSettings(existingHydrationSettings) {
                            return {
                                ...existingHydrationSettings,
                                defaultDailyGoal: {
                                    ...existingHydrationSettings.defaultDailyGoal,
                                    value:
                                        systemOfMeasures === 'imperial'
                                            ? convertToOz(
                                                  existingHydrationSettings
                                                      .defaultDailyGoal.value
                                              )
                                            : convertToMl(
                                                  existingHydrationSettings
                                                      .defaultDailyGoal.value
                                              ),
                                    unit:
                                        systemOfMeasures === 'imperial'
                                            ? 'oz'
                                            : 'ml',
                                },
                            };
                        },
                    },
                });
            },
        });
    };

    const result = {
        changeSettings,
        loading,
    };
    return children(result);
};

ChangeSettingsConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    rawQueryResult: PropTypes.object.isRequired,
};

export default ChangeSettingsConnectorApollo;
