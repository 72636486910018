import React from 'react';
import PropTypes from 'prop-types';
import { ApiDateType } from 'types/CommonTypes';
import MeasurementEditContainer from 'Measurement/Edit/MeasurementEditContainer';
import MeasurementFetchByDateConnectorApollo from '@dietlabs/components/src/ApolloConnectors/Measurement/MeasurementByDate/MeasurementFetchByDate/MeasurementFetchByDateConnectorApollo';
import MeasurementSaveByDateConnectorApollo from '@dietlabs/components/src/ApolloConnectors/Measurement/MeasurementByDate/MeasurementSaveByDate/MeasurementSaveByDateConnectorApollo';
import MeasurementDeleteByDateConnectorApollo from '@dietlabs/components/src/ApolloConnectors/Measurement/MeasurementByDate/MeasurementDeleteByDate/MeasurementDeleteByDateConnectorApollo';

const MeasurementEdit = props => (
    <MeasurementFetchByDateConnectorApollo date={props.match.params.date}>
        {fetchData => (
            <MeasurementSaveByDateConnectorApollo
                date={props.match.params.date}
                {...fetchData}
            >
                {saveData => (
                    <MeasurementDeleteByDateConnectorApollo {...saveData}>
                        {deleteData => (
                            <MeasurementEditContainer
                                {...fetchData}
                                {...saveData}
                                {...deleteData}
                            />
                        )}
                    </MeasurementDeleteByDateConnectorApollo>
                )}
            </MeasurementSaveByDateConnectorApollo>
        )}
    </MeasurementFetchByDateConnectorApollo>
);

MeasurementEdit.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            date: ApiDateType,
        }).isRequired,
    }).isRequired,
};

export default MeasurementEdit;
