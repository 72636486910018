import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Layout from 'Layout/Layout';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import { Link } from 'react-router-dom';
import stats from 'assets/icons/hydration/stats.svg';
import { PATH_HYDRATION_HISTORY } from '../paths';

class DietSettings extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
    };

    render() {
        const { children } = this.props;

        return (
            <Layout page="hydration">
                <Container>
                    <Row>
                        <Col
                            xs={12}
                            md={{ size: 8, offset: 2 }}
                            lg={{ size: 6, offset: 3 }}
                        >
                            <Link
                                to={PATH_HYDRATION_HISTORY}
                                className="btn-history"
                            >
                                <img src={stats} className="img-fluid" alt="" />
                            </Link>

                            {children()}
                        </Col>
                    </Row>
                </Container>
            </Layout>
        );
    }
}

export default withLocale(DietSettings);
