import React from 'react';
import PropTypes from 'prop-types';
import { createDateObject, formatIsoDate, addDays } from 'utils/dateFormatter';
import { withApollo } from 'react-apollo';
import deepClone from 'utils/deepClone';
import DayPlanQuery from './Fetch/DayPlanQuery';
import * as types from '../../../DayPlanTypes';

class DayPlanPrintConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
        start: PropTypes.instanceOf(Date).isRequired,
        end: PropTypes.instanceOf(Date).isRequired,
        client: PropTypes.shape({
            query: PropTypes.func.isRequired,
        }).isRequired,
    };

    state = {
        loading: true,
        dayPlanArray: undefined,
    };

    componentDidMount() {
        this.fetch();
    }

    componentDidUpdate(prevProps) {
        // Marh.min, Math.max cast dates to numbers
        const prevStart = Math.min(prevProps.start, prevProps.end);
        const prevEnd = Math.max(prevProps.start, prevProps.end);
        const start = Math.min(this.props.start, this.props.end);
        const end = Math.max(this.props.start, this.props.end);

        if (prevStart !== start || prevEnd !== end) {
            this.fetch();
        }
    }

    async fetch() {
        this.setState({ loading: true });

        const { start, end } = this.props;

        const dateArray = [];

        const startDate = new Date(Math.min(start, end));
        const endDate = new Date(Math.max(start, end));

        while (startDate <= endDate) {
            dateArray.push(formatIsoDate(startDate));
            startDate.setDate(startDate.getDate() + 1);
        }

        const promises = dateArray.map(date =>
            this.props.client.query({
                query: DayPlanQuery,
                variables: {
                    date,
                    canBeCopiedToDate: formatIsoDate(addDays(new Date(), 1)),
                },
                fetchPolicy: 'network-only',
            })
        );

        const resolvedPromises = await Promise.all(promises);

        const dayPlanArray = resolvedPromises.map(singlePlan => {
            const result = {};

            result.dayPlan = deepClone(singlePlan.data.me.dayPlan);
            result.availableDays = singlePlan.data.me.diet.availableDays;
            result.categories = singlePlan.data.me.diet.set.categories;
            result.hasActiveSubscription =
                undefined !==
                singlePlan.data.me.activeSubscriptions.find(
                    sub => sub.givesAccessToDiet
                );

            if (result.dayPlan.date) {
                result.dayPlan.date = createDateObject(result.dayPlan.date);
            }
            result.accessTo = singlePlan.data.me.diet.accessTo
                ? createDateObject(singlePlan.data.me.diet.accessTo)
                : undefined;
            result.dietAvailabilityDate =
                singlePlan.data.me.dayPlan.__typename ===
                    types.DIET_IN_FUTURE && singlePlan.data.me.dayPlan.date
                    ? createDateObject(singlePlan.data.me.dayPlan.date)
                    : undefined;
            result.goal = singlePlan.data.me.goal;
            result.isHolidayMenu = Boolean(singlePlan.data.me.diet.set.holiday);
            result.isTimeToNagForCurrentMeasurement =
                singlePlan.data.me.isTimeToNagForCurrentMeasurement;
            result.fastingStatistics = singlePlan.data.me.fastingStatistics;

            return result;
        });

        this.setState({
            loading: false,
            dayPlanArray,
        });
    }

    render() {
        const { children } = this.props;

        const result = {
            loading: this.state.loading,
            dayPlanArray: this.state.dayPlanArray,
        };

        return children(result);
    }
}

export default withApollo(DayPlanPrintConnectorApollo);
