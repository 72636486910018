import React from 'react';
import PropTypes from 'prop-types';
import InputDate from 'components/FormElements/InputDateControlled';
import { withLocale } from '@dietlabs/components';
import withFirebase from 'view/FirebaseAnalytics/withFirebase';
import apiDateFormat from 'components/Helpers/apiDateFormat';
import withStep from './StepContext/withStep';

const MIN_DATE_DAYS_IN_FUTURE = 1;
const MAX_DATE_DAYS_IN_FUTURE = 14;

const getDateStringDaysInFuture = days => {
    const date = new Date();
    date.setDate(date.getDate() + days);
    return apiDateFormat(date);
};

const getMinDateString = () =>
    getDateStringDaysInFuture(MIN_DATE_DAYS_IN_FUTURE);
const getMaxDateString = () =>
    getDateStringDaysInFuture(MAX_DATE_DAYS_IN_FUTURE);

class StepSexBirthdayHeight extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        startDate: PropTypes.string,
        handleInputChange: PropTypes.func.isRequired,
        changeNextBtnState: PropTypes.func.isRequired,
        errors: PropTypes.shape(),
        validateData: PropTypes.func.isRequired,
        trackFirebaseEvent: PropTypes.func.isRequired,
    };

    static defaultProps = {
        startDate: undefined,
        errors: undefined,
    };

    componentDidMount() {
        this.checkErrors();
        this.props.trackFirebaseEvent('screen_view ', {
            firebase_screen: 'diet_start_date',
        });
    }

    componentDidUpdate() {
        this.checkErrors();
    }

    checkErrors = () => {
        let errors = false;

        if (this.props.errors) {
            this.props.errors.details.forEach(error => {
                if (error.fieldName === 'startDate') {
                    errors = true;
                }
            });
        }

        this.props.changeNextBtnState(!(this.props.startDate && !errors));
    };

    render() {
        const { startDate, handleInputChange, errors } = this.props;

        return (
            <div className="full-screen3">
                <div className="mb-auto w-100">
                    <InputDate
                        label={this.props.t(
                            'diet-settings/final/start-date-question'
                        )}
                        name="startDate"
                        value={startDate}
                        min={getMinDateString()}
                        max={getMaxDateString()}
                        errors={errors}
                        handleChange={handleInputChange}
                        onBlur={() => this.props.validateData()}
                        data-test="start-date-input"
                    />

                    <p className="advice">
                        <strong>Rada dr Dąbrowskiej:</strong>
                        <br />
                        Pamiętaj o zaplanowaniu czasu na zakup składników
                        potrzebnych do przygotowania posiłków. Ich listę
                        otrzymasz po ułożeniu diety.
                    </p>
                </div>
            </div>
        );
    }
}

export default withFirebase(withStep(withLocale(StepSexBirthdayHeight)));
