import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';
import InputRadio from 'components/FormElements/InputRadioControlled';
import { withLocale } from '@dietlabs/components';
import withFirebase from 'view/FirebaseAnalytics/withFirebase';
import withStep from './StepContext/withStep';

class StepActivityLevel extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        activityLevel: PropTypes.string,
        handleInputChange: PropTypes.func.isRequired,
        changeNextBtnState: PropTypes.func.isRequired,
        trackFirebaseEvent: PropTypes.func.isRequired,
    };

    static defaultProps = {
        activityLevel: undefined,
    };

    componentDidMount() {
        this.props.changeNextBtnState(!this.props.activityLevel);
        this.props.trackFirebaseEvent('screen_view ', {
            firebase_screen: 'Frequency',
        });
    }

    componentDidUpdate() {
        this.props.changeNextBtnState(!this.props.activityLevel);
    }

    render() {
        const { activityLevel, handleInputChange } = this.props;

        return (
            <div className="full-screen3">
                <div className="my-auto w-100">
                    <FormGroup tag="fieldset">
                        <Label>
                            {this.props.t('diet-settings/activity-level/label')}
                        </Label>

                        <InputRadio
                            label={
                                <strong>
                                    {this.props.t(
                                        'diet-settings/activity-level/none'
                                    )}
                                </strong>
                            }
                            name="activityLevel"
                            value="ACTIVITY_LEVEL_NONE"
                            handleChange={handleInputChange}
                            checked={activityLevel === 'ACTIVITY_LEVEL_NONE'}
                            data-test="activity-level-none"
                        />

                        <InputRadio
                            label={
                                <strong>
                                    {this.props.t(
                                        'diet-settings/activity-level/low'
                                    )}
                                </strong>
                            }
                            name="activityLevel"
                            value="ACTIVITY_LEVEL_LOW"
                            handleChange={handleInputChange}
                            checked={activityLevel === 'ACTIVITY_LEVEL_LOW'}
                            data-test="activity-level-low"
                        />

                        <InputRadio
                            label={
                                <strong>
                                    {this.props.t(
                                        'diet-settings/activity-level/medium'
                                    )}
                                </strong>
                            }
                            name="activityLevel"
                            value="ACTIVITY_LEVEL_MEDIUM"
                            handleChange={handleInputChange}
                            checked={activityLevel === 'ACTIVITY_LEVEL_MEDIUM'}
                            data-test="activity-level-medium"
                        />

                        <InputRadio
                            label={
                                <strong>
                                    {this.props.t(
                                        'diet-settings/activity-level/high'
                                    )}
                                </strong>
                            }
                            name="activityLevel"
                            value="ACTIVITY_LEVEL_HIGH"
                            handleChange={handleInputChange}
                            checked={activityLevel === 'ACTIVITY_LEVEL_HIGH'}
                            data-test="activity-level-high"
                        />
                    </FormGroup>
                </div>
            </div>
        );
    }
}

export default withFirebase(withStep(withLocale(StepActivityLevel)));
