import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import RegisterMutation from './RegisterMutation';

const RegisterConnectorApollo = ({ children }) => {
    const [mutate] = useMutation(RegisterMutation);

    const createRegister = async request => {
        const variables = {
            request,
        };

        const response = await mutate({
            variables,
        });

        return response;
    };

    return children({
        registerAndActivateCoupon: createRegister,
    });
};

RegisterConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
};

export default RegisterConnectorApollo;
