import React, { Component } from 'react';
import dropImg from 'assets/icons/hydration/drop.png';

class HydrationDayProgressPreloader extends Component {
    render() {
        return (
            <div className="text-center">
                <div className="drop">
                    <img src={dropImg} className="img-fluid" alt="" />
                    <div className="value" />
                </div>
            </div>
        );
    }
}

export default HydrationDayProgressPreloader;
