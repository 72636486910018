import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { subDays } from 'utils/date';

import DayConnector from '@dietlabs/components/src/ApolloConnectors/Hydration/FetchHydrationDay/HydrationDayConnectorApollo';
import ChangeGoalConnector from '@dietlabs/components/src/ApolloConnectors/Hydration/ChangeGoal/HydrationDayChangeGoalConnectorApollo';
import PortionAddConnector from '@dietlabs/components/src/ApolloConnectors/Hydration/AddPortion/HydrationAddConnectorApollo';
import PortionRemoveConnector from '@dietlabs/components/src/ApolloConnectors/Hydration/RemovePortion/HydrationDayPortionRemoveConnectorApollo';

import DayContainer from './HydrationDayContainer';

class HydrationDayPage extends Component {
    state = {
        length: 7,
    };

    setLength = length => this.setState({ length });

    render() {
        const date = new Date();

        const to = new Date();
        const from = subDays(to, this.state.length - 1);

        return (
            <DayConnector date={date}>
                {({
                    loading,
                    cacheHit,
                    error,
                    data: { goal, hydrationSum } = {},
                    queryResult,
                }) => (
                    <ChangeGoalConnector date={date} queryResult={queryResult}>
                        {(changeGoal, changeGoalMessages) => (
                            <PortionAddConnector
                                date={date}
                                queryResult={queryResult}
                            >
                                {(createAdd, addPortionMessages) => (
                                    <PortionRemoveConnector
                                        date={date}
                                        queryResult={queryResult}
                                    >
                                        {(
                                            removeLastPortion,
                                            removePortionMessages
                                        ) => {
                                            return (
                                                <DayContainer
                                                    loading={loading}
                                                    cacheHit={cacheHit}
                                                    hasNetworkError={Boolean(
                                                        error
                                                    )}
                                                    date={date}
                                                    changeGoal={changeGoal}
                                                    changeGoalMessages={
                                                        changeGoalMessages
                                                    }
                                                    removeLastPortion={
                                                        removeLastPortion
                                                    }
                                                    removeLastPortionMessages={
                                                        removePortionMessages
                                                    }
                                                    goal={goal}
                                                    value={hydrationSum}
                                                    add={createAdd}
                                                    messages={
                                                        addPortionMessages
                                                    }
                                                    dateFrom={from}
                                                    dateTo={to}
                                                    historyLength={
                                                        this.state.length
                                                    }
                                                    setHistoryLength={
                                                        this.setLength
                                                    }
                                                />
                                            );
                                        }}
                                    </PortionRemoveConnector>
                                )}
                            </PortionAddConnector>
                        )}
                    </ChangeGoalConnector>
                )}
            </DayConnector>
        );
    }
}

export { HydrationDayPage };
export default withRouter(HydrationDayPage);
