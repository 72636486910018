import React from 'react';
import PropTypes from 'prop-types';

import withMobileAppMode from 'Layout/withMobileAppMode';

import Footer from './Footer';
import Menu from './Menu';

class Layout extends React.Component {
    static propTypes = {
        forceReloadLinks: PropTypes.bool,
        mobileAppMode: PropTypes.bool.isRequired,
        page: PropTypes.oneOf([
            'activities',
            'article-list',
            'cart',
            'cms-article',
            'cms-contact',
            'coupon',
            'diet',
            'diet-new',
            'diet-settings',
            'error',
            'hydration',
            'login',
            'measurement',
            'newsletter-unsubscribe',
            'promo-code',
            'quiz',
            'settings',
            'shopping-list',
            'should-be-external',
            'thnk-you',
            'workouts',
            'unauthorized-exception',
            'update',
            'activate',
            'fav-meals',
            'remove-account',
        ]).isRequired,
        children: PropTypes.node.isRequired,
    };

    static defaultProps = {
        forceReloadLinks: false,
    };

    render() {
        const { forceReloadLinks, mobileAppMode, page, children } = this.props;

        return (
            <div className={`page-template-${page}`} name="top">
                {!mobileAppMode ? (
                    <Menu forceReloadLinks={forceReloadLinks} />
                ) : (
                    ''
                )}
                <main>{children}</main>
                {!mobileAppMode ? (
                    <Footer forceReloadLinks={forceReloadLinks} />
                ) : (
                    ''
                )}
            </div>
        );
    }
}

export { Layout };
export default withMobileAppMode(Layout);
