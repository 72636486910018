import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Container, FormGroup, Alert } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import ShoppingCartDeliveryOption from 'view/ShoppingCart/Delivery/Option/ShoppingCartDeliveryOption';
import InputRadio from 'components/FormElements/InputRadioControlled2';
import ShoppingCartIndexDeliveryDataContainer from 'view/ShoppingCart/Index/Delivery/ShoppingCartIndexDeliveryDataContainer';
import Price from 'view/Price/Price';

import blik from 'assets/icons/checkout/blik.svg';
import mastercard from 'assets/icons/checkout/mastercard.svg';
import paypal from 'assets/icons/checkout/paypal.svg';
import payu from 'assets/icons/checkout/payu.svg';
import visa from 'assets/icons/checkout/visa.svg';

export class ShoppingCartIndexDeliveryComponent extends React.Component {
    componentDidMount() {
        this.handleOptions();
    }

    handleOptions() {
        if (
            !this.props.saleShippingId ||
            !this.props.options.filter(
                option => option.id === this.props.saleShippingId
            ).length
        ) {
            this.selectDefaultOption();
        }
    }

    selectDefaultOption() {
        const newSaleShippingId = this.props.options[0].id;
        this.props.setDeliveryOption(newSaleShippingId);
    }

    render() {
        return (
            <section className="payment-method-form">
                <Container>
                    {this.props.options.length > 1 ? (
                        <Fragment>
                            <h2>{this.props.t('cart/payment-method')}</h2>

                            <FormGroup tag="fieldset">
                                {this.props.options.map(method => {
                                    if (
                                        method.name ===
                                        'Otrzymasz dostęp do serwisu online'
                                    ) {
                                        return (
                                            <InputRadio
                                                key={`${method.name}_new`}
                                                data-test="delivery-option"
                                                label={[
                                                    <span key="payment1">
                                                        Płatności elektroniczne
                                                    </span>,
                                                    <div
                                                        key="payment2"
                                                        className="d-md-inline ml-md-3"
                                                    >
                                                        <img
                                                            src={blik}
                                                            className="payment-icon"
                                                            alt="Blik"
                                                        />
                                                        <img
                                                            src={payu}
                                                            className="payment-icon"
                                                            alt="PayU"
                                                        />
                                                        <img
                                                            src={paypal}
                                                            className="payment-icon"
                                                            alt="PayPal"
                                                        />
                                                        <img
                                                            src={visa}
                                                            className="payment-icon"
                                                            alt="Visa"
                                                        />
                                                        <img
                                                            src={mastercard}
                                                            className="payment-icon"
                                                            alt="MasterCard"
                                                        />
                                                    </div>,
                                                ]}
                                                id={`saleShippingId${method.id}`}
                                                name="saleShippingId"
                                                value={method.id}
                                                handleChange={event =>
                                                    this.props.setDeliveryOption(
                                                        event.target.value,
                                                        event.target.name
                                                    )
                                                }
                                                checked={
                                                    this.props
                                                        .saleShippingId ===
                                                    method.id
                                                }
                                            />
                                        );
                                    }
                                    return (
                                        <InputRadio
                                            key={`${method.name}`}
                                            data-test="delivery-option"
                                            label={method.name}
                                            id={`saleShippingId${method.id}`}
                                            name="saleShippingId"
                                            value={method.id}
                                            handleChange={event =>
                                                this.props.setDeliveryOption(
                                                    event.target.value,
                                                    event.target.name
                                                )
                                            }
                                            checked={
                                                this.props.saleShippingId ===
                                                method.id
                                            }
                                        />
                                    );
                                })}
                            </FormGroup>
                        </Fragment>
                    ) : null}

                    {this.props.isFreeDeliveryAvailable &&
                    this.props.freeFromCartValue ? (
                        <Alert color="success" data-test="free-delivery-info">
                            {this.props.t('cart/free-delivery-info', {
                                price: this.props.freeFromCartValue.toString(),
                            })}
                        </Alert>
                    ) : (
                        ''
                    )}

                    {this.props.areShippingDataNeeded ? (
                        <ShoppingCartIndexDeliveryDataContainer />
                    ) : (
                        ''
                    )}
                </Container>
            </section>
        );
    }
}

ShoppingCartIndexDeliveryComponent.propTypes = {
    t: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.instanceOf(ShoppingCartDeliveryOption))
        .isRequired,
    saleShippingId: PropTypes.number,
    areShippingDataNeeded: PropTypes.bool.isRequired,
    setDeliveryOption: PropTypes.func.isRequired,
    isFreeDeliveryAvailable: PropTypes.bool.isRequired,
    freeFromCartValue: PropTypes.instanceOf(Price),
};

ShoppingCartIndexDeliveryComponent.defaultProps = {
    saleShippingId: undefined,
    freeFromCartValue: undefined,
};

export default withLocale(ShoppingCartIndexDeliveryComponent);
