import React, { Component } from 'react';
import { Container, Row, Col, Button, Collapse } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import PropTypes from 'prop-types';
import Loader from 'Layout/Loader';
import { scroller, Element } from 'react-scroll';
import { ReactComponent as DownArrow } from 'assets/icons/chevron-down.svg';
import { withSnackbar } from 'react-simple-snackbar';

import favouriteFull from 'assets/icons/favourites/favourite-full.svg';
import favouriteEmpty from 'assets/icons/favourites/favourite-empty.svg';

import withFirebase from 'view/FirebaseAnalytics/withFirebase';

const steps = [5, 15, 30, 45, 60];

class DayPlanMeal extends Component {
    static propTypes = {
        preparationTime: PropTypes.shape({
            years: PropTypes.number,
            days: PropTypes.number,
            hours: PropTypes.number,
            minutes: PropTypes.number,
            seconds: PropTypes.number,
        }).isRequired,
        name: PropTypes.string.isRequired,
        kcal: PropTypes.number.isRequired,
        macro: PropTypes.shape({
            animalProtein: PropTypes.shape({
                grams: PropTypes.number.isRequired,
                kcal: PropTypes.number.isRequired,
                percentage: PropTypes.number.isRequired,
            }).isRequired,
            carbohydrates: PropTypes.shape({
                grams: PropTypes.number.isRequired,
                kcal: PropTypes.number.isRequired,
                percentage: PropTypes.number.isRequired,
            }).isRequired,
            fat: PropTypes.shape({
                grams: PropTypes.number.isRequired,
                kcal: PropTypes.number.isRequired,
                percentage: PropTypes.number.isRequired,
            }).isRequired,
            protein: PropTypes.shape({
                grams: PropTypes.number.isRequired,
                kcal: PropTypes.number.isRequired,
                percentage: PropTypes.number.isRequired,
            }).isRequired,
        }).isRequired,
        showMacros: PropTypes.bool.isRequired,
        t: PropTypes.func.isRequired,
        children: PropTypes.func.isRequired,
        originalId: PropTypes.number.isRequired,
        loadDayPlanMealReplacements: PropTypes.func.isRequired,
        renderMealReplacementsList: PropTypes.func.isRequired,
        print: PropTypes.bool.isRequired,
        mealId: PropTypes.number.isRequired,
        dietPhase: PropTypes.string.isRequired,
        canBeAddedToFavorites: PropTypes.bool.isRequired,
        addMealToFavorites: PropTypes.func.isRequired,
        removeMealFromFavorites: PropTypes.func.isRequired,
        openSnackbar: PropTypes.func.isRequired,
        isFavorited: PropTypes.bool.isRequired,
        trackFirebaseEvent: PropTypes.func.isRequired,
    };

    state = {
        mealCollapse: true,
        collapse: false,
        replacements: null,
        originalId: 0,
        preloadInside: false,
        isFavorited: this.props.isFavorited,
    };

    changeMealButtonRef = React.createRef();

    scrollAnchorRef = React.createRef();

    componentDidUpdate(prevProps) {
        if (
            prevProps.isFavorited !== this.props.isFavorited &&
            this.state.isFavorited !== this.props.isFavorited
        ) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ isFavorited: this.props.isFavorited });
        }
    }

    static calculatePreparationTime(minutes) {
        if (minutes === 0 || minutes == null) {
            return null;
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const step of steps) {
            if (minutes <= step) {
                return `<${step}`;
            }
        }
        return `>${steps[steps.length - 1]}`;
    }

    closeAndScroll() {
        scroller.scrollTo(`meal${this.props.mealId}`, {
            duration: 1000,
            delay: 0,
            smooth: true,
            offset: -56,
        });

        this.setState({
            collapse: false,
            preloadInside: true,
        });
    }

    replaceMealSuccess() {
        this.setState({ preloadInside: false });
    }

    handleClick() {
        if (this.state.collapse) {
            this.setState({ collapse: false });
        } else {
            this.setState({ preloadInside: true });
            this.loadMealReplacements();

            this.props.trackFirebaseEvent('user_action', {
                action: 'tap-change_meal',
                location: 'Dietplan',
                meal_id: this.props.mealId,
                meal_category: this.props.name,
            });
        }
    }

    handleMealClick() {
        this.setState(prevState => ({
            mealCollapse: !prevState.mealCollapse,
        }));

        this.props.trackFirebaseEvent('user_action', {
            action: 'tap-meal_details',
            location: 'Dietplan',
            meal_id: this.props.mealId,
            meal_category: this.props.name,
        });
    }

    loadMealReplacements = async () => {
        const request = {
            originalMealId: this.props.originalId,
            limit: 10,
            appendFavorites: true,
        };
        const response = await this.props.loadDayPlanMealReplacements(request);

        this.setState({
            collapse: true,
            replacements: response.data.me.diet.set.meal.replacements,
            originalId: response.data.me.diet.set.meal.original.id,
            preloadInside: false,
        });

        scroller.scrollTo(`changeMealButton${this.props.mealId}`, {
            duration: 500,
            delay: 0,
            smooth: true,
            offset: -56,
        });

        this.props.trackFirebaseEvent('screen_view', {
            firebase_screen: 'Change_meal',
        });
    };

    async handleAddRemoveMealToFavorites(showSnackbar) {
        if (!this.state.isFavorited) {
            try {
                const response = await this.props.addMealToFavorites(
                    this.props.mealId
                );
                if (
                    response.data.me.addMealToFavorites.__typename ===
                    'BasicMutationSuccessWithId'
                ) {
                    this.setState(prevState => ({
                        isFavorited: !prevState.isFavorited,
                    }));

                    if (showSnackbar) {
                        this.props.openSnackbar(
                            <p>{this.props.t('fav-meals/added')}</p>
                        );
                    }

                    this.props.trackFirebaseEvent('user_action', {
                        action: 'tap-add_favourites',
                        location: 'Dietplan',
                        meal_id: this.props.mealId,
                        meal_category: this.props.name,
                        diet_phase: this.props.dietPhase,
                    });
                }
            } catch (error) {
                throw new Error(`Failed to add meal to favorites: ${error}`);
            }
        } else {
            try {
                const response = await this.props.removeMealFromFavorites(
                    this.props.mealId
                );
                if (
                    response.data.me.removeMealFromFavoritesByMealId
                        .__typename === 'BasicMutationSuccess'
                ) {
                    this.setState(prevState => ({
                        isFavorited: !prevState.isFavorited,
                    }));

                    if (showSnackbar) {
                        this.props.openSnackbar(
                            <p>
                                {this.props.t('fav-meals/removed')}
                                <Button
                                    color="link"
                                    onClick={() =>
                                        this.handleAddRemoveMealToFavorites(
                                            false
                                        )
                                    }
                                >
                                    {this.props.t('fav-meals/back')}
                                </Button>
                            </p>
                        );
                    }
                }
            } catch (error) {
                throw new Error(
                    `Failed to remove meal from favorites: ${error}`
                );
            }
        }
    }

    render() {
        const preparationTime = this.constructor.calculatePreparationTime(
            this.props.preparationTime.minutes
        );

        const FAST_DIET = 'Post';
        const dietPhase = this.props.dietPhase;

        return (
            <section
                className="meal"
                ref={this.scrollAnchorRef}
                name={`meal${this.props.mealId}`}
            >
                <Container>
                    <div className="white-box">
                        <hr className="d-none d-print-block" />

                        {dietPhase !== FAST_DIET && this.props.showMacros ? (
                            <React.Fragment>
                                <h2>
                                    <strong>{this.props.name}:</strong>{' '}
                                    {this.props.kcal}
                                    &nbsp;kcal
                                    <Button
                                        color="primary"
                                        className={
                                            this.state.mealCollapse
                                                ? 'open collapse-arrow outline btn-auto-width'
                                                : 'collapse-arrow outline btn-auto-width'
                                        }
                                        onClick={() => this.handleMealClick()}
                                        type="button"
                                    >
                                        <span className="SVGInline">
                                            <DownArrow />
                                        </span>
                                    </Button>
                                </h2>
                                <p className="BTW">
                                    <span>
                                        {preparationTime ? (
                                            <span>
                                                {this.props.t(
                                                    'meal/preparation-time'
                                                )}
                                                {': '}
                                                <strong>
                                                    {preparationTime} min
                                                </strong>
                                            </span>
                                        ) : (
                                            ''
                                        )}
                                    </span>
                                    <span className="ml-3">
                                        {this.props.t('meal/proteins-short')}
                                        {': '}
                                        <strong>
                                            {this.props.macro.protein.grams}g
                                        </strong>
                                        &nbsp;
                                        {this.props.t('meal/fat-short')}
                                        {': '}
                                        <strong>
                                            {this.props.macro.fat.grams}g
                                        </strong>
                                        &nbsp;
                                        {this.props.t(
                                            'meal/carbohydrates-short'
                                        )}
                                        {': '}
                                        <strong>
                                            {
                                                this.props.macro.carbohydrates
                                                    .grams
                                            }
                                            g
                                        </strong>
                                    </span>
                                </p>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <h2>
                                    <strong>{this.props.name}</strong>
                                    <Button
                                        color="primary"
                                        className={
                                            this.state.mealCollapse
                                                ? 'open collapse-arrow outline btn-auto-width'
                                                : 'collapse-arrow outline btn-auto-width'
                                        }
                                        onClick={() => this.handleMealClick()}
                                        type="button"
                                    >
                                        <span className="SVGInline">
                                            <DownArrow />
                                        </span>
                                    </Button>
                                </h2>
                                <p className="BTW">
                                    {preparationTime ? (
                                        <span>
                                            {this.props.t(
                                                'meal/preparation-time'
                                            )}{' '}
                                            {preparationTime} min
                                        </span>
                                    ) : (
                                        ''
                                    )}
                                </p>
                            </React.Fragment>
                        )}

                        {this.props.canBeAddedToFavorites && (
                            <button
                                className="add-remove-fav mt-3"
                                type="button"
                                onClick={() =>
                                    this.handleAddRemoveMealToFavorites(true)
                                }
                            >
                                <img
                                    src={
                                        this.state.isFavorited
                                            ? favouriteFull
                                            : favouriteEmpty
                                    }
                                    className="mr-2"
                                    alt=""
                                />
                                {this.state.isFavorited
                                    ? this.props.t('fav-meals/is-in-favourites')
                                    : this.props.t(
                                          'fav-meals/add-to-favourites'
                                      )}
                            </button>
                        )}

                        <Collapse isOpen={this.state.mealCollapse}>
                            <hr />

                            {this.props.children()}

                            {!this.props.print &&
                            this.props.name !== 'Start' ? (
                                <Row>
                                    <Col xs="12">
                                        <div className="text-center">
                                            <Element
                                                name={`changeMealButton${this.props.mealId}`}
                                            >
                                                <Button
                                                    color="secondary"
                                                    innerRef={
                                                        this.changeMealButtonRef
                                                    }
                                                    onClick={() =>
                                                        this.handleClick()
                                                    }
                                                    data-test="change-meal-button"
                                                    className={
                                                        this.state.collapse
                                                            ? 'outline'
                                                            : ''
                                                    }
                                                >
                                                    {this.state.collapse
                                                        ? this.props.t(
                                                              'meal/exchange-collapse'
                                                          )
                                                        : this.props.t(
                                                              'meal/exchange-meal-button'
                                                          )}
                                                </Button>
                                            </Element>

                                            <Collapse
                                                isOpen={this.state.collapse}
                                            >
                                                {this.props.renderMealReplacementsList(
                                                    this.state.replacements,
                                                    this.state.originalId,
                                                    {
                                                        closeAndScroll: () =>
                                                            this.closeAndScroll(),
                                                        replaceMealSuccess: () =>
                                                            this.replaceMealSuccess(),
                                                    }
                                                )}
                                            </Collapse>
                                        </div>
                                    </Col>
                                </Row>
                            ) : (
                                ''
                            )}
                        </Collapse>
                    </div>
                </Container>

                {this.state.preloadInside ? <Loader fixed="no" /> : ''}
            </section>
        );
    }
}

export { DayPlanMeal };
export default withFirebase(withSnackbar(withLocale(DayPlanMeal)));
