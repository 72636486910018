import {
    ApolloClient,
    HttpLink,
    ApolloLink,
    InMemoryCache,
    concat,
} from '@apollo/client';
import { APP_VERSION } from 'consts';
import config from 'config/config';

const httpLink = new HttpLink({
    uri: config.apiGateway.url,
    credentials: 'same-origin',
    headers: {
        'X-AppEnvironment': process.env.REACT_APP_ENV,
        'X-AppVersion': `ReactWebApp/${APP_VERSION}`,
    },
});

const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers and set dynamic api uri
    operation.setContext(({ headers = {} }) => ({
        headers: {
            ...headers,
            'X-Authentication':
                headers['X-Authentication'] ||
                localStorage.getItem('token') ||
                null,
        },
        uri:
            localStorage.getItem('developerOptionsApiUri') ||
            config.apiGateway.url,
    }));

    return forward(operation);
});

const client = new ApolloClient({
    cache: new InMemoryCache({
        typePolicies: {
            UserShoppingListCategoryProduct: {
                keyFields: ['key'],
            },
            UserHydrationDay: {
                keyFields: ['key'],
            },
            UserDiet: {
                keyFields: ['id'],
            },
            UserDailyDietPlanType: {
                keyFields: ['id'],
            },
            UserDietSet: {
                keyFields: ['dietSetId'],
            },
            UserDailyDietPlanEventMeal: {
                keyFields: ['key'],
            },
            UserDailyDietMeal: {
                keyFields: ['key'],
            },
            UserActivity: {
                keyFields: ['id'],
            },
            UserDailyDietMealDishIngredient: {
                keyFields: ['key'],
            },
            UserMeasurement: {
                keyFields: [],
            },
        },
    }),
    link: concat(authMiddleware, httpLink),
    connectToDevTools: true,
});

export default client;
