import React, { Component } from 'react';
import glass from 'assets/icons/hydration/glass.svg';
import bottle from 'assets/icons/hydration/bottle.svg';
import custom from 'assets/icons/hydration/custom.svg';
import { Button } from 'reactstrap';

class HydrationAddPlaceholder extends Component {
    render() {
        return (
            <div className="text-center">
                <Button color="placeholder" className="btn-add">
                    <img src={glass} alt="" />
                    250 ml
                </Button>

                <Button color="placeholder" className="btn-add">
                    <img src={bottle} alt="" />
                    1000 ml
                </Button>

                <Button color="placeholder" className="btn-add">
                    <img src={custom} alt="" />
                    własne
                </Button>
            </div>
        );
    }
}

export default HydrationAddPlaceholder;
