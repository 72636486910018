import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

import { ReactComponent as LeftArrow } from 'assets/icons/chevron-left.svg';

class HydrationHistoryActions extends PureComponent {
    static propTypes = {
        to: PropTypes.string.isRequired,
        children: PropTypes.node.isRequired,
    };

    render() {
        return (
            <Button
                tag={Link}
                to={this.props.to}
                color="secondary outline"
                className="btn-back"
            >
                <span className="SVGInline">
                    <LeftArrow />
                </span>
                {this.props.children}
            </Button>
        );
    }
}

export { HydrationHistoryActions };
export default HydrationHistoryActions;
