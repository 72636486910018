import GraphQLFetch from '@dietlabs/components/src/GraphQL/GraphQLFetch';
import Price from 'view/Price/Price';
import {
    SALE_PRODUCT_LOAD_SEND_REQUEST,
    SALE_PRODUCT_LOAD_SUCCESS_RECEIVE_RESPONSE,
    SALE_PRODUCT_LOAD_FAILED_RECEIVE_RESPONSE,
} from './SaleProductLoadActionType';
import Query from './SaleProductLoadQuery';
import SaleProduct from '../SaleProduct';
import SaleProductPrice from '../Price/SaleProductPrice';

export const parseProducts = rawProducts => {
    const result = rawProducts.reduce((parsedProducts, rawProductVariant) => {
        const isValid =
            rawProductVariant.id !== undefined &&
            rawProductVariant.name !== undefined &&
            rawProductVariant.saleProduct !== undefined &&
            rawProductVariant.saleProduct.isVirtual !== undefined &&
            rawProductVariant.saleProduct.containsDiet !== undefined &&
            rawProductVariant.saleProduct.containsWorkout !== undefined &&
            rawProductVariant.saleProduct.tags !== undefined &&
            rawProductVariant.saleProduct.price !== undefined &&
            rawProductVariant.saleProduct.price.regular !== undefined &&
            rawProductVariant.saleProduct.price.regular.amount !== undefined &&
            rawProductVariant.saleProduct.price.regular.currency !==
                undefined &&
            rawProductVariant.saleProduct.isBestseller !== undefined &&
            rawProductVariant.saleProduct.shortDescription !== undefined &&
            rawProductVariant.saleProduct.longDescription !== undefined &&
            rawProductVariant.saleProduct.lengthLabel !== undefined;

        if (isValid) {
            parsedProducts[rawProductVariant.id] = new SaleProduct(
                rawProductVariant.id,
                rawProductVariant.name,
                new SaleProductPrice(
                    new Price(
                        rawProductVariant.saleProduct.price.regular.amount,
                        rawProductVariant.saleProduct.price.regular.currency
                    ),
                    rawProductVariant.saleProduct.price.promo
                        ? new Price(
                              rawProductVariant.saleProduct.price.promo.amount,
                              rawProductVariant.saleProduct.price.promo.currency
                          )
                        : null
                ),
                !rawProductVariant.saleProduct.isVirtual,
                rawProductVariant.saleProduct.containsDiet,
                rawProductVariant.saleProduct.containsWorkout,
                rawProductVariant.saleProduct.tags,
                rawProductVariant.saleProduct.isBestseller,
                rawProductVariant.saleProduct.shortDescription,
                rawProductVariant.saleProduct.longDescription,
                rawProductVariant.saleProduct.lengthLabel,
                rawProductVariant.saleProduct.vendor
                    ? rawProductVariant.saleProduct.vendor.identifier
                    : ''
            );
        }
        return parsedProducts;
    }, {});

    if (!Object.keys(result).length) {
        throw new Error();
    }

    return result;
};

export const saleProductLoadAction = (promoCode = null) => dispatch => {
    dispatch({ type: SALE_PRODUCT_LOAD_SEND_REQUEST });

    return GraphQLFetch.runQuery(Query, { promoCode }).then(res => {
        try {
            const products = parseProducts(res.data.sale.productVariants);

            dispatch({
                type: SALE_PRODUCT_LOAD_SUCCESS_RECEIVE_RESPONSE,
                products,
            });

            return products;
        } catch (error) {
            dispatch({ type: SALE_PRODUCT_LOAD_FAILED_RECEIVE_RESPONSE });

            return {};
        }
    });
};
