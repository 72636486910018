import React from 'react';
import withLoading from 'Layout/withLoading';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import Layout from 'Layout/Layout';
import { withLocale } from '@dietlabs/components';
import { Container } from 'reactstrap';
import HpbaMarkdown from 'Content/utils/HpbaMarkdown';
import ContactPlaceholder from './ContactPlaceholder';
import ContactForm from './ContactForm';

class ContactContainer extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        content: PropTypes.shape({
            content: PropTypes.shape({
                html: PropTypes.string,
                markdown: PropTypes.string,
            }),
        }).isRequired,
        userData: PropTypes.shape({
            name: PropTypes.string,
            email: PropTypes.string,
        }),
        contactEmail: PropTypes.shape({
            sendEmail: PropTypes.func,
            response: PropTypes.shape({
                details: PropTypes.string,
                code: PropTypes.number,
            }),
            loading: PropTypes.bool,
            error: PropTypes.shape(),
        }).isRequired,
    };

    static defaultProps = {
        userData: undefined,
    };

    render() {
        return (
            <Layout page="cms-contact">
                <Helmet>
                    <title>
                        Kontakt z Dietetykiem ✓ Dieta dr Ewy Dąbrowskiej®
                    </title>
                    <meta
                        name="description"
                        content="Kontakt z oficjalnym, certyfikowanym Dietetykiem aplikacji Dieta dr Ewy Dąbrowskiej | Zapytaj o dietę, post i aplikację"
                    />
                </Helmet>
                <div className="bg-white">
                    <Container>
                        <header>
                            <h1 className="text-center d-none d-md-block">
                                {this.props.t('contact/title')}
                            </h1>
                        </header>
                        <h2>{this.props.t('contact/faq')}</h2>
                        <HpbaMarkdown
                            content={this.props.content.content.markdown}
                        />
                        <ContactForm
                            name={
                                this.props.userData
                                    ? this.props.userData.name
                                    : ''
                            }
                            email={
                                this.props.userData
                                    ? this.props.userData.email
                                    : ''
                            }
                            contactEmail={this.props.contactEmail}
                        />
                    </Container>
                </div>
            </Layout>
        );
    }
}

export default withLoading(withLocale(ContactContainer), ContactPlaceholder);
