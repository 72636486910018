import React, { PureComponent } from 'react';

import beetrootImg from 'assets/images/beetroot.png';
import carrotImg from 'assets/images/carrot.png';
import broccoliImg from 'assets/images/broccoli.png';
import appleImg from 'assets/images/apple.png';

class LongInlineLoader extends PureComponent {
    render() {
        return (
            <div className="text-center longInlineLoader" role="presentation">
                <img
                    className="longInlineLoader__produce longInlineLoader__produce--beetroot"
                    alt=""
                    src={beetrootImg}
                />
                <img
                    className="longInlineLoader__produce longInlineLoader__produce--carrot"
                    alt=""
                    src={carrotImg}
                />
                <img
                    className="longInlineLoader__produce longInlineLoader__produce--broccoli"
                    alt=""
                    src={broccoliImg}
                />
                <img
                    className="longInlineLoader__produce longInlineLoader__produce--apple"
                    alt=""
                    src={appleImg}
                />
            </div>
        );
    }
}

export default LongInlineLoader;
