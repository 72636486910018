import gql from 'graphql-tag';

const query = gql`
    query UserData {
        me {
            id
        }
    }
`;

export default query;
