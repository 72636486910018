import gql from 'graphql-tag';

const query = gql`
    query MealDetails(
        $userDietSetId: Int!
        $mealType: Int!
        $dietMealId: Int!
        $limit: Int!
        $prependWithOriginalMeal: Boolean
    ) {
        me {
            id
            dailyDietMeal(
                userDietSetId: $userDietSetId
                mealType: $mealType
                dietMealId: $dietMealId
            ) {
                id
                key
                replacements(
                    limit: $limit
                    prependWithOriginalMeal: $prependWithOriginalMeal
                ) {
                    id
                    dishes {
                        id
                        name
                        ingredients {
                            name
                        }
                    }
                    preparationImageUrl
                    publicName
                    isFavorited
                }
            }
        }
    }
`;

export default query;
