import gql from 'graphql-tag';

const query = gql`
    query MealDetails(
        $userDietSetId: Int!
        $mealType: Int!
        $dietMealId: Int!
    ) {
        me {
            id
            dailyDietMeal(
                userDietSetId: $userDietSetId
                mealType: $mealType
                dietMealId: $dietMealId
            ) {
                id
                mealType {
                    type
                    name
                }
                name
                key
                kcal
                preparationVideoUrl
                preparationImageUrl
                canBeAddedToFavorites
                isFavorite
                isEaten
                macro {
                    protein {
                        grams
                        kcal
                        percentage
                    }
                    animalProtein {
                        grams
                        kcal
                        percentage
                    }
                    fat {
                        grams
                        kcal
                        percentage
                    }
                    carbohydrates {
                        grams
                        kcal
                        percentage
                    }
                }
                preparationTime {
                    years
                    months
                    days
                    hours
                    minutes
                    seconds
                }
                dishes {
                    id
                    key
                    name
                    recipe
                    recipeNote
                    isPortioned
                    portions
                    portionsTotal
                    isFirstOccurrence
                    isLastOccurrence
                    ingredients {
                        key
                        productAmountId
                        productId
                        name
                        hasReplacement
                        category {
                            id
                            isSeasoning
                            isOptional
                            name
                        }
                        measurements {
                            amount
                            unit
                            text
                        }
                        original {
                            productId
                            name
                            measurements {
                                amount
                                unit
                                text
                            }
                        }
                        replacements {
                            productId
                            name
                            measurements {
                                amount
                                unit
                                text
                            }
                        }
                        saleProductVariant {
                            link
                        }
                    }
                }
            }
        }
    }
`;

export default query;
