import React, { Component } from 'react';
import {
    Container,
    Alert,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import Odometer from 'react-odometerjs';
import PropTypes from 'prop-types';
import Layout from 'Layout/Layout';
import { reportWarning } from '@dietlabs/utils';
import { Link } from 'react-router-dom';
import { PATHS } from 'config/paths';
import { PATH_MEASUREMENT_ADD } from 'Measurement/routes';
import { formatIsoDate } from 'utils/dateFormatter';
import { getHolidayIconName } from 'DayPlan/utils/getHolidayIconName';
import MessageWithButton from 'Layout/Messages/MessageWithButton';
import withGTM from 'view/GoogleTagManager/withGTM';
import { EVENT_CATEGORY_DIET_PLAN } from 'view/GoogleTagManager/EventCategories';
import { holidayIcons, IMAGE_SHARING_URL } from 'consts';
import { callNativeSharing } from 'components/Helpers/callNativeSharing';
import withMobileAppMode from 'Layout/withMobileAppMode';
import compareVersions from 'compare-versions';
import withFirebase from 'view/FirebaseAnalytics/withFirebase';
import { callNativeApp } from 'components/Helpers/callNativeApp';
import fastingStatusFasting from 'assets/icons/if/fasting-status-fasting.svg';
import fastingStatusEating from 'assets/icons/if/fasting-status-eating.svg';
import { isToday } from 'utils/date';
import { PATH_DAY_PLAN_INDEX } from '../DayPlanPaths';

class DayPlan extends Component {
    static propTypes = {
        date: PropTypes.instanceOf(Date).isRequired,
        kcal: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        originalPhase: PropTypes.shape({
            name: PropTypes.string.isRequired,
            colour: PropTypes.string.isRequired,
        }).isRequired,
        t: PropTypes.func.isRequired,
        children: PropTypes.func.isRequired,
        renderTimeline: PropTypes.func.isRequired,
        availableDays: PropTypes.arrayOf(PropTypes.object.isRequired)
            .isRequired,
        categories: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        goal: PropTypes.shape({
            reachedBecauseOfLoseWeight: PropTypes.bool.isRequired,
            reachedBecauseOfPutOnWeight: PropTypes.bool.isRequired,
            lostBecauseOfLoseWeight: PropTypes.bool.isRequired,
            lostBecauseOfPutOnWeight: PropTypes.bool.isRequired,
        }).isRequired,
        isHolidayMenu: PropTypes.bool.isRequired,
        isTimeToNagForCurrentMeasurement: PropTypes.bool.isRequired,
        trackEvent: PropTypes.func.isRequired,
        print: PropTypes.bool.isRequired,
        dayPlanForTomorrow: PropTypes.shape({
            __typename: PropTypes.string.isRequired,
            events: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        }),
        protein: PropTypes.number.isRequired,
        fat: PropTypes.number.isRequired,
        carbohydrates: PropTypes.number.isRequired,
        showMacros: PropTypes.bool.isRequired,
        renderMealLinks: PropTypes.func.isRequired,
        fastingStatistics: PropTypes.shape({
            dietsCount: PropTypes.number,
            currentDietLength: PropTypes.number,
            currentDietProgress: PropTypes.number,
        }).isRequired,
        mobileAppMode: PropTypes.bool.isRequired,
        mobileApp: PropTypes.shape({
            active: PropTypes.bool,
            os: PropTypes.string,
            version: PropTypes.string,
        }).isRequired,
        fastType: PropTypes.string.isRequired,
        handleLoadDayReplacements: PropTypes.func.isRequired,
        trackFirebaseEvent: PropTypes.func.isRequired,
        intermittentFasting: PropTypes.shape({
            activeCycle: PropTypes.shape({
                phase: PropTypes.string,
                phaseFinishPlannedTo: PropTypes.instanceOf(Date),
            }),
        }).isRequired,
    };

    static defaultProps = {
        dayPlanForTomorrow: undefined,
    };

    state = {
        odometer: 0,
        showModal: false,
    };

    toggle = () => {
        this.setState(prevState => ({
            showModal: !prevState.showModal,
        }));
    };

    componentDidMount() {
        this.setState({ odometer: this.props.kcal });
        this.trackView();

        this.props.trackFirebaseEvent('screen_view ', {
            firebase_screen: 'Dietplan',
            diet_phase: this.props.name,
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.kcal !== this.props.kcal) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                odometer: this.props.kcal,
            });
        }

        if (formatIsoDate(prevProps.date) !== formatIsoDate(this.props.date)) {
            this.trackView();
        }
    }

    trackView() {
        const now = new Date();
        const today = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate()
        );

        const dayDelta = Math.floor(
            (this.props.date - today) / 1000 / 60 / 60 / 24
        );

        const label = dayDelta > 0 ? `+${dayDelta}` : dayDelta;
        this.props.trackEvent(EVENT_CATEGORY_DIET_PLAN, 'view', label);
    }

    renderIntermittentFastingAlert() {
        if (this.props.intermittentFasting && this.props.mobileAppMode) {
            const { activeCycle } = this.props.intermittentFasting;

            if (activeCycle) {
                let alertColor;
                let alertMsg;
                let alertIcon;

                const now = new Date();
                const endOfPhase = new Date(activeCycle.phaseFinishPlannedTo);
                const hours =
                    endOfPhase.getHours() < 10
                        ? `0${endOfPhase.getHours()}`
                        : endOfPhase.getHours();
                const minutes =
                    endOfPhase.getMinutes() < 10
                        ? `0${endOfPhase.getMinutes()}`
                        : endOfPhase.getMinutes();

                if (activeCycle.phase === 'PHASE_FASTING') {
                    alertColor = 'danger';
                    alertIcon = fastingStatusFasting;

                    if (now > endOfPhase) {
                        alertMsg = this.props.t(
                            'intetmittent-fasting/end-of-post'
                        );

                        this.props.trackFirebaseEvent('screen_view', {
                            firebase_screen: 'Dietplan',
                            fasting_status: 'fastingexceeded',
                        });
                    } else {
                        alertMsg = isToday(endOfPhase)
                            ? `${this.props.t(
                                  'intetmittent-fasting/next-eat-window-today'
                              )}, ${hours}:${minutes}`
                            : `${this.props.t(
                                  'intetmittent-fasting/next-eat-window-tomorrow'
                              )}, ${hours}:${minutes}`;

                        this.props.trackFirebaseEvent('screen_view', {
                            firebase_screen: 'Dietplan',
                            fasting_status: 'fasting',
                        });
                    }
                } else if (activeCycle.phase === 'PHASE_EATING') {
                    alertColor = 'info';
                    alertIcon = fastingStatusEating;
                    alertMsg = `${this.props.t(
                        'intetmittent-fasting/eat-window-to'
                    )} ${hours}:${minutes}`;

                    this.props.trackFirebaseEvent('screen_view', {
                        firebase_screen: 'Dayplan',
                        fasting_status: 'eatingwindow',
                    });
                }

                return (
                    <Alert
                        color={alertColor}
                        className="mb-4 text-left clickable"
                        data-test="if"
                        onClick={() => {
                            this.props.trackFirebaseEvent('user_action', {
                                action: 'tap-fasting_notification',
                                location: 'Dayplan',
                            });

                            return callNativeApp(
                                '/',
                                'intermittent-fasting',
                                true
                            );
                        }}
                    >
                        <div className="d-flex align-items-center">
                            <img src={alertIcon} className="mr-2" alt="" />
                            <p className="mb-0">{alertMsg}</p>
                        </div>
                    </Alert>
                );
            }
        }

        return null;
    }

    renderGoalAlert() {
        if (this.props.goal) {
            if (
                this.props.goal.lostBecauseOfLoseWeight ||
                this.props.goal.lostBecauseOfPutOnWeight
            ) {
                return (
                    <MessageWithButton
                        color="danger"
                        renderButton={() => (
                            <Button
                                color="primary"
                                tag={Link}
                                to={PATHS.MAKE_DECISION}
                            >
                                {this.props.t('info-pages/goal-lost/button')}
                            </Button>
                        )}
                        data-test="goal-lost"
                    >
                        <h2 className="alert-heading">
                            {this.props.t('info-pages/goal-lost/title')}
                        </h2>
                        <p>{this.props.t('info-pages/goal-lost/content')}</p>
                    </MessageWithButton>
                );
            }

            if (
                this.props.goal.reachedBecauseOfLoseWeight ||
                this.props.goal.reachedBecauseOfPutOnWeight
            ) {
                return (
                    <MessageWithButton
                        color="success"
                        renderButton={() => (
                            <Button
                                color="secondary"
                                tag={Link}
                                to={PATHS.MAKE_DECISION}
                            >
                                {this.props.t('info-pages/goal-reached/button')}
                            </Button>
                        )}
                        data-test="goal-reached"
                    >
                        <h2 className="alert-heading">
                            {this.props.t('info-pages/goal-reached/title')}
                        </h2>
                        <p>{this.props.t('info-pages/goal-reached/content')}</p>
                    </MessageWithButton>
                );
            }
        }

        return '';
    }

    renderMeasurementAlert() {
        if (this.props.isTimeToNagForCurrentMeasurement) {
            return (
                <MessageWithButton
                    color="danger"
                    renderButton={() => (
                        <Button
                            color="primary"
                            tag={Link}
                            to={{
                                pathname: PATH_MEASUREMENT_ADD,
                                state: {
                                    from: {
                                        pathname: `${PATH_DAY_PLAN_INDEX}/${formatIsoDate(
                                            this.props.date
                                        )}`,
                                    },
                                },
                            }}
                        >
                            {this.props.t(
                                'info-pages/current-weight-needed/button'
                            )}
                        </Button>
                    )}
                    data-test="measurement"
                >
                    <p>
                        {this.props.t('alert/periodic-measurements-1')}
                        <br />
                        {this.props.t('alert/periodic-measurements-2')}
                    </p>
                </MessageWithButton>
            );
        }

        return '';
    }

    renderHolidayAlert() {
        const isThisHoliday = getHolidayIconName(
            this.props.availableDays,
            formatIsoDate(this.props.date)
        );
        if (isThisHoliday && !this.props.isHolidayMenu) {
            return (
                <Alert
                    color="info"
                    className="mb-4 text-left clickable"
                    data-test="holiday"
                >
                    <div>
                        {this.renderHolidayIcon(this.props.date)}{' '}
                        <p>Pokaż jadłospis specjalny dla tego dnia</p>
                        <Button
                            color="secondary"
                            className="d-block mt-2"
                            onClick={() =>
                                this.props.handleLoadDayReplacements()
                            }
                            data-test="show-holiday-menu"
                        >
                            {this.props.t('show-special-menu')}
                        </Button>
                    </div>
                </Alert>
            );
        }

        return '';
    }

    renderHolidayIcon(date) {
        const iconName = getHolidayIconName(
            this.props.availableDays,
            formatIsoDate(date)
        );
        if (iconName) {
            const Icon = holidayIcons[iconName];
            if (Icon) {
                return (
                    <span className="SVGInline" data-test="holiday-icon">
                        <Icon />
                    </span>
                );
            }

            reportWarning(new Error(`Unknown holiday icon: ${iconName}`));
        }

        return '';
    }

    renderTomorrowAlert() {
        const tomorrowAlert = [];
        if (
            this.props.dayPlanForTomorrow &&
            Object.keys(this.props.dayPlanForTomorrow).length !== 0
        ) {
            if (
                this.props.dayPlanForTomorrow.__typename ===
                'UserDayPlanSuccess'
            ) {
                this.props.dayPlanForTomorrow.events.forEach(event => {
                    if (event.__typename === 'Meal') {
                        event.dishes.forEach(dish => {
                            if (dish.recipeNoteForPreviousDay) {
                                tomorrowAlert.push(
                                    dish.recipeNoteForPreviousDay
                                );
                            }
                        });
                    }
                });
            }
        }

        const uniqTomorrowAlert = [...new Set(tomorrowAlert)];

        if (uniqTomorrowAlert.length > 0) {
            return (
                <Alert
                    color="info"
                    className="mb-4 text-left"
                    data-test="tomorrow-alert"
                >
                    <h4>{this.props.t('next-day-notification')}</h4>
                    <ul className="list">
                        {uniqTomorrowAlert.map((alert, idx, arr) => (
                            <li key={arr.indexOf(alert)}>{alert}</li>
                        ))}
                    </ul>
                </Alert>
            );
        }

        return '';
    }

    renderDietPhase() {
        const FAST_DIET = 'Post';

        let phaseName;

        if (this.props.name === FAST_DIET) {
            let fastType = '';

            if (this.props.fastType) {
                fastType = this.props.t(this.props.fastType);
            }

            if (this.props.categories[0]) {
                fastType = this.props.categories[0].name.toLowerCase();
            }
            phaseName = `${this.props.originalPhase.name} ${fastType}`;

            if (isToday(this.props.date)) {
                phaseName = `${this.props.originalPhase.name} ${fastType} ${this.props.fastingStatistics.currentDietProgress}/${this.props.fastingStatistics.currentDietLength}/${this.props.fastingStatistics.dietsCount}`;
            }
        } else {
            phaseName = this.props.originalPhase.name;
        }

        let displayShareButton = false;
        if (
            this.props.name === FAST_DIET &&
            this.props.mobileApp.os &&
            this.props.mobileApp.version &&
            isToday(this.props.date)
        ) {
            if (
                (this.props.mobileApp.os.toLowerCase().startsWith('ios') &&
                    compareVersions.compare(
                        this.props.mobileApp.version,
                        '1.9.2',
                        '>='
                    )) ||
                (this.props.mobileApp.os.toLowerCase().startsWith('android') &&
                    compareVersions.compare(
                        this.props.mobileApp.version,
                        '16',
                        '>='
                    ))
            ) {
                displayShareButton = true;
            }
        }

        return (
            <React.Fragment>
                <div
                    className={`diet-phase ${this.props.originalPhase.colour}`}
                >
                    {phaseName}
                </div>

                {displayShareButton ? (
                    <Button
                        className="diet-phase share-button ml-3"
                        onClick={this.toggle}
                    >
                        {this.props.t('share')}
                    </Button>
                ) : (
                    ''
                )}

                <Modal
                    isOpen={this.state.showModal}
                    toggle={this.toggle}
                    centered
                    size="lg"
                >
                    <ModalHeader toggle={this.toggle} />
                    <ModalBody className="text-center pt-0">
                        <div
                            className={`diet-phase ${this.props.originalPhase.colour} mb-3`}
                        >
                            {phaseName}
                        </div>
                        <h3>{this.props.t('share/how-to-read')}</h3>
                        <p>
                            <span className="fast-code">
                                <strong>
                                    {
                                        this.props.fastingStatistics
                                            .currentDietProgress
                                    }
                                </strong>
                                /
                                {this.props.fastingStatistics.currentDietLength}
                                /{this.props.fastingStatistics.dietsCount}
                            </span>{' '}
                            - {this.props.t('share/current-diet-progress')}
                            <br />
                            <span className="fast-code">
                                {
                                    this.props.fastingStatistics
                                        .currentDietProgress
                                }
                                /
                                <strong>
                                    {
                                        this.props.fastingStatistics
                                            .currentDietLength
                                    }
                                </strong>
                                /{this.props.fastingStatistics.dietsCount}
                            </span>{' '}
                            - {this.props.t('share/current-diet-length')}
                            <br />
                            <span className="fast-code">
                                {
                                    this.props.fastingStatistics
                                        .currentDietProgress
                                }
                                /
                                {this.props.fastingStatistics.currentDietLength}
                                /
                                <strong>
                                    {this.props.fastingStatistics.dietsCount}
                                </strong>
                            </span>{' '}
                            - {this.props.t('share/diets-count')}
                        </p>
                        <hr />
                        <Button
                            className="diet-phase share-button mb-3"
                            onClick={() => callNativeSharing(IMAGE_SHARING_URL)}
                        >
                            {this.props.t('share')}
                        </Button>
                        <h3>{this.props.t('share/title')}</h3>
                        <p>{this.props.t('share/description')}</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={() => callNativeSharing(IMAGE_SHARING_URL)}
                        >
                            {this.props.t('share')}
                        </Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }

    render() {
        return (
            <Layout page="diet">
                {this.props.renderTimeline('top')}

                {this.props.renderMealLinks()}

                <header>
                    <Container className="text-center">
                        {!this.props.print && (
                            <React.Fragment>
                                {this.renderIntermittentFastingAlert()}
                                {this.renderGoalAlert()}
                                {this.renderGoalAlert()}
                                {this.renderMeasurementAlert()}
                                {this.renderTomorrowAlert()}
                                {this.renderHolidayAlert()}
                            </React.Fragment>
                        )}

                        <h1>
                            {this.props.t(
                                `meal/weekday${this.props.date.getDay()}`
                            )}

                            {this.props.showMacros ? (
                                <React.Fragment>
                                    ,{' '}
                                    <span className="text-nowrap">
                                        {this.props.print ? (
                                            this.props.kcal
                                        ) : (
                                            <Odometer
                                                value={this.state.odometer}
                                                format="d"
                                            />
                                        )}{' '}
                                        kcal
                                    </span>
                                </React.Fragment>
                            ) : (
                                ''
                            )}
                        </h1>

                        {this.props.showMacros ? (
                            <p className="BTW">
                                {this.props.t('meal/proteins-short')}
                                {': '}
                                <strong>{this.props.protein}%</strong>
                                &nbsp;
                                {this.props.t('meal/fat-short')}
                                {': '}
                                <strong>{this.props.fat}%</strong>
                                &nbsp;
                                {this.props.t('meal/carbohydrates-short')}
                                {': '}
                                <strong>{this.props.carbohydrates}%</strong>
                            </p>
                        ) : (
                            ''
                        )}
                        {this.renderDietPhase()}
                    </Container>
                </header>
                {this.props.children()}
            </Layout>
        );
    }
}
export { DayPlan };
export default withFirebase(withMobileAppMode(withGTM(withLocale(DayPlan))));
