import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import ewa from 'assets/images/dr_ewa.jpg';
import { PATHS } from 'config/paths';
import { withLocale } from '@dietlabs/components';
import Layout from 'Layout/Layout';

class AfterLongBreak extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        renderTimeline: PropTypes.func,
        dietId: PropTypes.number.isRequired,
    };

    static defaultProps = {
        renderTimeline: undefined,
    };

    renderMessage() {
        let dietPhase;

        switch (this.props.dietId) {
            case 1:
                dietPhase = this.props.t(
                    'diet-settings/diet-programme/fast-preparation/title'
                );
                break;
            case 2:
                dietPhase = this.props.t(
                    'diet-settings/diet-programme/fast/title'
                );
                break;
            case 3:
                dietPhase = this.props.t(
                    'diet-settings/diet-programme/healthy-eating/title'
                );
                break;
            case 4:
                dietPhase = this.props.t(
                    'diet-settings/diet-programme/healthy-eating/title'
                );
                break;
            case 5:
                dietPhase = this.props.t(
                    'diet-settings/diet-programme/fast-preparation/title'
                );
                break;
            case 6:
                dietPhase = this.props.t(
                    'diet-settings/diet-programme/fast-exit/title'
                );
                break;
            case 7:
                dietPhase = this.props.t(
                    'diet-settings/diet-programme/fast-exit/title'
                );
                break;
            default:
                dietPhase = this.props.t(
                    'diet-settings/diet-programme/fast-preparation/title'
                );
        }

        return this.props.t('info-pages/after-long-break', {
            phase: dietPhase.toLowerCase(),
        });
    }

    render() {
        return (
            <Layout page="diet">
                {this.props.renderTimeline && this.props.renderTimeline('top')}
                <section className="bg-white">
                    <Container>
                        <Row>
                            <Col xs="12" className="text-center">
                                <img
                                    className="mb-4"
                                    src={ewa}
                                    role="presentation"
                                    alt=""
                                />
                                <p>{this.renderMessage()}</p>

                                <Button
                                    color="secondary"
                                    className="mb-4"
                                    tag={Link}
                                    to={PATHS.DIET_SETTINGS}
                                >
                                    {this.props.t('menu/diet/settings')}
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Layout>
        );
    }
}

export { AfterLongBreak };
export default withLocale(AfterLongBreak);
