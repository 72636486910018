import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import MealDetailsQuery from './MealDetailsQuery';

const MealDetailsConnectorApollo = ({
    children,
    userDietSetId,
    mealType,
    dietMealId,
}) => {
    const { loading, error, data, refetch } = useQuery(MealDetailsQuery, {
        variables: {
            userDietSetId,
            mealType,
            dietMealId,
        },
    });

    const result = {
        loading,
        error,
        refetch,
    };

    if (!loading) {
        result.meal = data.me.dailyDietMeal;
    }

    return children(result);
};

MealDetailsConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
    userDietSetId: PropTypes.number.isRequired,
    mealType: PropTypes.number.isRequired,
    dietMealId: PropTypes.number.isRequired,
};

export default MealDetailsConnectorApollo;
