import React from 'react';
import { ApolloConsumer } from '@apollo/client';

const withApolloClient = ChildComponent => props => (
    <ApolloConsumer>
        {client => <ChildComponent {...props} client={client} />}
    </ApolloConsumer>
);

export default withApolloClient;
