import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import PropTypes from 'prop-types';

class DayPlanTraining extends Component {
    static propTypes = {
        activities: PropTypes.arrayOf(PropTypes.object).isRequired,
        burnedCalories: PropTypes.number.isRequired,
        duration: PropTypes.number.isRequired,
        t: PropTypes.func.isRequired,
    };

    render() {
        return (
            <section className="training">
                <Container>
                    <h2>
                        {this.props.t('activities/training')}{' '}
                        <span className="text-lowercase">
                            {this.props.t(this.props.activities[0].timeOfDay)}:
                        </span>{' '}
                        {this.props.burnedCalories}
                        &nbsp;kcal | {this.props.t('activities/duration')}:{' '}
                        {this.props.duration}
                        &nbsp;min
                    </h2>
                    <hr />

                    <ul className="activities-list">
                        {this.props.activities.map(activity => (
                            <li key={activity.duration}>
                                <span className="float-right">
                                    {activity.duration}
                                    &nbsp;min |&nbsp;
                                    {activity.burnedCalories}
                                    &nbsp;kcal
                                </span>
                                {activity.type.fullName}
                            </li>
                        ))}
                    </ul>
                </Container>
            </section>
        );
    }
}

export default withLocale(DayPlanTraining);
