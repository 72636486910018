const clearDailyDietPlanCacheObjects = client => {
    const serializedState = client.cache.extract();
    const userId = Object.values(serializedState).filter(
        item => item.__typename === 'Me'
    )[0].id;
    const meObject = serializedState[`Me:${userId}`];

    const dailyDietPlanKeys = Object.keys(meObject).filter(item =>
        item.includes('dailyDietPlan')
    );
    dailyDietPlanKeys.forEach(key => {
        client.cache.evict({
            id: `Me:${userId}`,
            fieldName: key,
        });
    });
    client.cache.gc();
};

export default clearDailyDietPlanCacheObjects;
