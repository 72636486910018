import ShoppingListIndexPage from 'ShoppingList/Index/ShoppingListIndexPage';
import compareVersions from 'compare-versions';

const PATH_SHOPPING_LIST_INDEX = '/shopping-list';

const mobileApp = JSON.parse(global.localStorage.getItem('mobileApp'));

function getMobileAppTabIdentifier() {
    if (mobileApp && mobileApp.os) {
        if (mobileApp.os.startsWith('ios')) {
            if (compareVersions(mobileApp.version, '2.0.0') >= 0) {
                return 'diet';
            }
            return 'shopping-list';
        }
        if (mobileApp.os.startsWith('android')) {
            return 'diet';
        }
    }
    return 'shopping-list';
}

const routes = [
    {
        path: PATH_SHOPPING_LIST_INDEX,
        component: ShoppingListIndexPage,
        private: true,
        exact: false,
        title: 'route-title/shopping-list',
        mobileAppTabIdentifier: getMobileAppTabIdentifier(),
    },
];

export { PATH_SHOPPING_LIST_INDEX, routes };
