import PropTypes from 'prop-types';
import { createDateObject } from 'utils/dateFormatter';
import { useQuery } from '@apollo/client';

import SettingsQuery from './CurrentSettingsQuery';

const SettingsConnectorApollo = ({ children }) => {
    const { loading, data } = useQuery(SettingsQuery);

    let result = {
        loading,
        hydrationSettings: {},
        rawData: {},
    };

    if (!loading) {
        result.rawData = data;

        result.systemOfMeasures = data.me.systemOfMeasures;
        result.agreements = data.me.agreements;

        const {
            email,
            systemOfMeasures,
            hasRegisteredDevices,
            agreements,
            diet,
            videoWorkout: {
                accessTo: videoWorkoutAccessTo,
                lifetimeAccess: videoWorkoutLifetimeAccess,
            },
            activeSubscriptions: subscriptions,
            passwordIsSet,
            hydrationSettings,
        } = result.rawData.me;

        result = {
            ...result,
            email,
            systemOfMeasures,
            hasRegisteredDevices,
            agreements,
            dietAccessTo: createDateObject(diet.accessTo),
            dietLifetimeAccess: diet.lifetimeAccess,
            videoWorkoutAccessTo: createDateObject(videoWorkoutAccessTo),
            videoWorkoutLifetimeAccess,
            subscriptions: subscriptions.map(sub => ({
                givesAccessToDiet: sub.givesAccessToDiet,
                givesAccessToVideoWorkouts: sub.givesAccessToVideoWorkouts,
                renewalDate: createDateObject(sub.renewalDate),
                operator: sub.operator,
            })),
            passwordIsSet,
            hydrationSettings,
            hasExternalProviderSetUp: data.me.hasExternalProviderSetUp,
            isAbleToLoginWithFacebook: data.me.isAbleToLoginWithFacebook,
            isAbleToLoginWithGoogle: data.me.isAbleToLoginWithGoogle,
            isAbleToLoginWithApple: data.me.isAbleToLoginWithApple,
        };
    }

    return children(result);
};

SettingsConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
};

export default SettingsConnectorApollo;
