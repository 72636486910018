import React from 'react';
import withLoading from 'Layout/withLoading';
import PropTypes from 'prop-types';
import deepClone from 'utils/deepClone';
import { scrollToTop } from 'components/Helpers/scrollToTop';
import { Redirect } from 'react-router';
import { PATH_DAY_PLAN_INDEX } from 'DayPlan/DayPlanPaths';
import { formatIsoDate, addDays } from 'utils/dateFormatter';
import { withLocale } from '@dietlabs/components';
import { validateFields } from 'view/Validation/validateFields';
import DateFormatRule from 'view/Validation/ValidationRules/DateFormatRule';
import RequiredRule from 'view/Validation/ValidationRules/RequiredRule';
import withFirebase from 'view/FirebaseAnalytics/withFirebase';
import DietSettingsPlaceholder from './DietSettingsPlaceholder';
import DietSettings from './DietSettings';

import StepProvider from '../Steps/StepContext/StepProvider';
import StepActions from '../Steps/StepActions';

import StepStart from '../Steps/StepStart';
import StepAcceptance from '../Steps/StepAcceptance';
import StepDietProgramme from '../Steps/StepDietProgramme';
import StepFastPreparationDays from '../Steps/StepFastPreparationDays';
import StepFastType from '../Steps/StepFastType';
import StepFastDays from '../Steps/StepFastDays';
import StepDietType from '../Steps/StepDietType';
import StepFastDayOfTheWeek from '../Steps/StepFastDayOfTheWeek';
import StepSexBirthdatHeight from '../Steps/StepSexBirthdatHeight';
import StepWeight from '../Steps/StepWeight';
import StepGoal from '../Steps/StepGoal';
import StepWorkType from '../Steps/StepWorkType';
import StepActivityLevel from '../Steps/StepActivityLevel';
import StepStartDate from '../Steps/StepStartDate';
import StepFinish from '../Steps/StepFinish';

import dietSettingsSteps from '../dietSettingsSteps';
import { dietSettingsGetNextStep } from '../utils/NextStep/DietSettingsNextStepAction';
import { dietSettingsGetPreviousStep } from '../utils/PreviousStep/DietSettingsPreviousStepAction';
import { dietSettingsGetProgress } from '../utils/Progress/DietSettingsProgressAction';

const firstStep = (dietSettingsSteps[0] || {}).id;

if (!firstStep) {
    throw new Error('First diet settings step not configured.');
}

class DietSettingsIndexContainer extends React.Component {
    state = {
        systemOfMeasures: this.props.dietSettings.systemOfMeasures,
        acceptance: undefined,
        currentStep: this.props.dietSettings.diet.hadEver
            ? 'diet-programme'
            : firstStep,
        progress: 0,
        dietProgramme: this.props.dietSettings.diet.dietProgramme || undefined,
        fastPreparationDays:
            this.props.dietSettings.diet.fastPreparationDays || 7,
        fastDays: this.props.dietSettings.diet.fastDays || 7,
        fastType: this.props.dietSettings.diet.fastType || undefined,
        dietType: this.props.dietSettings.diet.dietType || undefined,
        fastDayOfTheWeek:
            this.props.dietSettings.diet.fastDayOfTheWeek || undefined,
        sex: this.props.dietSettings.sex || undefined,
        dateOfBirth: this.props.dietSettings.birthDate || undefined,
        height: {
            value:
                // eslint-disable-next-line no-nested-ternary
                this.props.dietSettings.height &&
                this.props.dietSettings.height.value > 100
                    ? this.props.dietSettings.height.value
                    : this.props.dietSettings.systemOfMeasures === 'si'
                    ? 160
                    : 60,
            // eslint-disable-next-line no-nested-ternary
            unit: this.props.dietSettings.height
                ? this.props.dietSettings.height.unit
                : this.props.dietSettings.systemOfMeasures === 'si'
                ? 'cm'
                : 'in',
        },
        weight: {
            value: this.props.dietSettings.lastMeasurement.weight[0]
                ? this.props.dietSettings.lastMeasurement.weight[0].value
                : undefined,
            // eslint-disable-next-line no-nested-ternary
            unit: this.props.dietSettings.lastMeasurement.weight[0]
                ? this.props.dietSettings.lastMeasurement.weight[0].unit
                : this.props.dietSettings.systemOfMeasures === 'si'
                ? 'kg'
                : 'lb',
        },
        dietMode: this.props.dietSettings.diet.mode || undefined,
        goalWeight: {
            value:
                (this.props.dietSettings.goalWeight || {}).value || undefined,
            unit:
                (this.props.dietSettings.goalWeight || {}).unit ||
                (this.props.dietSettings.systemOfMeasures === 'si'
                    ? 'kg'
                    : 'lb'),
        },
        regularActivityLevel:
            this.props.dietSettings.diet.regularActivityLevel || undefined,
        activityLevel: this.props.dietSettings.diet.activityLevel || undefined,
        startDate: formatIsoDate(addDays(new Date(), 2)),
        isNextButtonDisabled: false,
        errors: undefined,
        redirect: false,
        generateDietError: false,
        preloadNextStep: false,
    };

    validationRules = {
        dateOfBirth: [
            new RequiredRule({ translator: this.props.t }),
            new DateFormatRule({ translator: this.props.t }),
        ],
        startDate: [
            new RequiredRule({ translator: this.props.t }),
            new DateFormatRule({ translator: this.props.t }),
        ],
    };

    static propTypes = {
        dietSettings: PropTypes.shape().isRequired,
        validateData: PropTypes.func.isRequired,
        generateDiet: PropTypes.func.isRequired,
        t: PropTypes.func.isRequired,
        trackFirebaseEvent: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.updateProgressBar(this.state.currentStep);
    }

    handleMarketingEvent = () => {
        switch (this.state.currentStep) {
            case 'diet-programme':
                if (
                    this.state.dietProgramme !==
                    this.props.dietSettings.diet.dietProgramme
                ) {
                    this.props.trackFirebaseEvent('user_action', {
                        action: 'tap-diet',
                        location: 'diet',
                        selected_diet: this.state.dietProgramme,
                    });
                }
                break;
            case 'fast-preparation-days':
                if (
                    this.state.fastPreparationDays !==
                    String(this.props.dietSettings.diet.fastPreparationDays)
                ) {
                    this.props.trackFirebaseEvent('user_action', {
                        action: 'tap-diet_fasting_preparation',
                        location: 'diet-fasting_preparation',
                        fasting_time: this.state.fastPreparationDays,
                    });
                }
                break;
            case 'fast-type':
                if (
                    this.state.fastType !==
                    this.props.dietSettings.diet.fastType
                ) {
                    this.props.trackFirebaseEvent('user_action', {
                        action: 'tap-diet_fast_type',
                        location: 'diet-fast_type',
                        fast_type: this.state.fastType,
                    });
                }
                break;
            case 'fast-days':
                if (
                    this.state.fastDays !==
                    String(this.props.dietSettings.diet.fastDays)
                ) {
                    this.props.trackFirebaseEvent('user_action', {
                        action: 'tap-diet_fasting',
                        location: 'diet_fasting',
                        fasting_time: this.state.fastDays,
                    });
                }
                break;
            case 'fast-day-of-the-week':
                if (
                    this.state.fastDayOfTheWeek !==
                    this.props.dietSettings.diet.fastDayOfTheWeek
                ) {
                    this.props.trackFirebaseEvent('user_action', {
                        action: 'tap-1_day_fasting',
                        location: '1_day_fasting',
                        selected_1_day_fasting: this.state.fastDayOfTheWeek,
                    });
                }
                break;
            case 'diet-type':
                if (
                    this.state.dietType !==
                    this.props.dietSettings.diet.dietType
                ) {
                    this.props.trackFirebaseEvent('user_action', {
                        action: 'tap-diet_fasting',
                        location: 'diet_type',
                        fasting_time: this.state.dietType,
                    });
                }
                break;
            case 'sex-brithday-height':
                if (this.state.sex !== this.props.dietSettings.sex) {
                    this.props.trackFirebaseEvent('user_action', {
                        action: 'tap-gender',
                        location: 'data',
                        selected_gender: this.state.sex,
                    });
                }
                if (
                    this.state.dateOfBirth !== this.props.dietSettings.birthDate
                ) {
                    this.props.trackFirebaseEvent('user_action', {
                        action: 'tap-year',
                        location: 'data',
                        selected_gender: this.state.dateOfBirth,
                    });
                }
                if (
                    JSON.stringify(this.state.height) !==
                    JSON.stringify(this.props.dietSettings.height)
                ) {
                    this.props.trackFirebaseEvent('user_action', {
                        action: 'tap-height',
                        location: 'data',
                        selected_height: this.state.height.value,
                    });
                }
                break;
            case 'weight':
                if (
                    JSON.stringify(this.state.weight) !==
                    JSON.stringify(
                        this.props.dietSettings.lastMeasurement.weight[0]
                    )
                ) {
                    this.props.trackFirebaseEvent('user_action', {
                        action: 'tap-weight',
                        location: 'weight',
                        selected_weight: this.state.weight.value,
                        weight_unit: this.state.weight.unit,
                    });
                }
                break;
            case 'goal':
                if (this.state.dietMode !== this.props.dietSettings.diet.mode) {
                    this.props.trackFirebaseEvent('user_action', {
                        action: 'tap-goal',
                        location: 'goal_target_weight',
                        selected_goal: this.state.dietMode,
                    });
                }
                if (
                    JSON.stringify(this.state.goalWeight) !==
                    JSON.stringify(this.props.dietSettings.goalWeight)
                ) {
                    this.props.trackFirebaseEvent('user_action', {
                        action: 'tap-target-weight',
                        location: 'goal_target_weight',
                        target_weight: this.state.goalWeight.value,
                        weight_unit: this.state.goalWeight.unit,
                    });
                }
                break;
            case 'work-type':
                if (
                    this.state.regularActivityLevel !==
                    this.props.dietSettings.diet.regularActivityLevel
                ) {
                    this.props.trackFirebaseEvent('user_action', {
                        action: 'tap-activity',
                        location: 'activity',
                        selected_goal: this.state.regularActivityLevel,
                    });
                }
                break;
            case 'activity-level':
                if (
                    this.state.activityLevel !==
                    this.props.dietSettings.diet.activityLevel
                ) {
                    this.props.trackFirebaseEvent('user_action', {
                        action: 'tap-frequency',
                        location: 'frequency',
                        selected_frequency: this.state.activityLevel,
                    });
                }
                break;
            case 'start-date':
                this.props.trackFirebaseEvent('user_action', {
                    action: 'tap-diet-start-date',
                    location: 'diet_start_date',
                    selected_diet_start_date: this.state.startDate,
                });
                break;
            default:
                break;
        }
    };

    handleInputChange = event => {
        const { name, value } = event.target;
        const fieldName = name.split('.')[0];
        const valInt = parseInt(value, 10);
        const valFloat = Math.round(parseFloat(value) * 10) / 10;

        if (fieldName === 'weight' || fieldName === 'goalWeight') {
            this.setState(prevState => ({
                [fieldName]: {
                    ...prevState[fieldName],
                    value: valFloat,
                },
            }));
        } else if (fieldName === 'height') {
            this.setState(prevState => ({
                [fieldName]: {
                    ...prevState[fieldName],
                    value: valInt,
                },
            }));
        } else if (
            fieldName === 'fastPreparationDays' ||
            fieldName === 'fastDays'
        ) {
            this.setState({
                [fieldName]: valInt,
            });
        } else if (
            (fieldName === 'dateOfBirth' || fieldName === 'startDate') &&
            event.target.value === ''
        ) {
            this.setState({
                [fieldName]: undefined,
            });
        } else if (fieldName === 'acceptance') {
            this.setState({
                [fieldName]: event.target.checked
                    ? new Date().toISOString().replace(/\.[0-9]{3}/gi, '')
                    : undefined,
            });
        } else {
            this.setState({ [fieldName]: event.target.value });
        }

        const validableFields = [
            'dateOfBirth',
            'weight',
            'goalWeight',
            'startDate',
        ];

        if (this.state.errors) {
            this.state.errors.details.forEach(error => {
                if (
                    (error.fieldName === fieldName ||
                        error.fieldName === `${fieldName}.value`) &&
                    validableFields.includes(fieldName)
                ) {
                    setTimeout(() => {
                        this.validateData();
                    }, 1000);
                }
            });
        }
    };

    createRequest() {
        const request = {
            dietProgramme: this.state.dietProgramme,
            fastPreparationDays: this.state.fastPreparationDays,
            fastDays: this.state.fastDays,
            fastType: this.state.fastType,
            dietType: this.state.dietType,
            fastDayOfTheWeek: this.state.fastDayOfTheWeek,
            sex: this.state.sex,
            dateOfBirth: this.state.dateOfBirth,
            height: this.state.height,
            weight: this.state.weight.value ? this.state.weight : undefined,
            dietMode: this.state.dietMode,
            goalWeight: this.state.goalWeight.value
                ? this.state.goalWeight
                : undefined,
            regularActivityLevel: this.state.regularActivityLevel,
            activityLevel: this.state.activityLevel,
            startDate: this.state.startDate,
            riskAcceptanceDate: this.state.acceptance,
        };

        if (request.dietMode === 'stabilization') {
            request.goalWeight = deepClone(request.weight);
        }

        return request;
    }

    validateData = async () => {
        const frontEndErrors = validateFields(
            this.validationRules,
            this.state,
            this.props.t
        );

        if (frontEndErrors.length === 0) {
            const request = this.createRequest();
            const response = await this.props.validateData(request);
            this.setState({
                errors: response.data.me.validateDietSettings,
            });
            return response.data.me.validateDietSettings;
        }

        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                details: frontEndErrors,
            },
        }));

        return this.state.errors;
    };

    generateDiet = async () => {
        // reset generate diet error
        this.setState({ generateDietError: false });
        const request = this.createRequest();

        try {
            const response = await this.props.generateDiet(request);
            const { code } = response.data.me.generateDiet;
            if (code === 200) {
                this.setState({ redirect: true });
            } else {
                this.setState({ generateDietError: true });
                throw new Error(
                    `Failed to generate diet, got status code ${code}`
                );
            }
        } catch (e) {
            this.setState({ generateDietError: true });
            throw new Error(`Failed to generate diet, got error: ${e}`);
        }
    };

    prevStep = () => {
        const dietProgramme = {
            dietProgramme: this.state.dietProgramme || 'full',
        };
        const state = { ...this.props.dietSettings, ...dietProgramme };

        const prevStep = dietSettingsGetPreviousStep(
            dietSettingsSteps,
            state,
            this.state.currentStep
        );

        const wrapper = document.getElementById('wrapper');
        wrapper.classList.add('animated', 'animated-fast', 'fadeOut');

        setTimeout(() => wrapper.classList.remove(...wrapper.classList), 200);
        setTimeout(() => wrapper.classList.add('hide'), 200);
        setTimeout(() => this.changeStep(prevStep), 200);
        setTimeout(() => wrapper.classList.add('animated', 'fadeIn'), 200);
    };

    nextStep = async () => {
        let errors = false;

        this.handleMarketingEvent();

        if (this.state.currentStep === 'sex-brithday-height') {
            this.setState({ preloadNextStep: true });
            const responseErrors = await this.validateData();
            responseErrors.details.forEach(error => {
                if (
                    error.fieldName === 'sex' ||
                    error.fieldName === 'dateOfBirth' ||
                    error.fieldName === 'height.value'
                ) {
                    errors = true;
                }
            });
        } else if (this.state.currentStep === 'weight') {
            this.setState({ preloadNextStep: true });
            const responseErrors = await this.validateData();
            responseErrors.details.forEach(error => {
                if (error.fieldName === 'weight.value') {
                    errors = true;
                }
            });
        } else if (this.state.currentStep === 'goal') {
            this.setState({ preloadNextStep: true });
            const responseErrors = await this.validateData();
            responseErrors.details.forEach(error => {
                if (error.fieldName === 'goalWeight.value') {
                    errors = true;
                }
            });
        } else if (this.state.currentStep === 'start-date') {
            this.setState({ preloadNextStep: true });
            const responseErrors = await this.validateData();
            responseErrors.details.forEach(error => {
                if (error.fieldName === 'startDate') {
                    errors = true;
                }
            });
        }

        if (!errors) {
            this.setState({ errors: undefined });

            const dietProgramme = {
                dietProgramme: this.state.dietProgramme || 'full',
            };
            const state = { ...this.props.dietSettings, ...dietProgramme };

            const nextStep = dietSettingsGetNextStep(
                dietSettingsSteps,
                state,
                this.state.currentStep
            );

            setTimeout(() => {
                this.setState({
                    preloadNextStep: false,
                });
            }, 200);

            const wrapper = document.getElementById('wrapper');
            wrapper.classList.add('animated', 'animated-fast', 'fadeOut');

            setTimeout(
                () => wrapper.classList.remove(...wrapper.classList),
                200
            );
            setTimeout(() => wrapper.classList.add('hide'), 200);

            setTimeout(() => this.changeStep(nextStep), 200);
            setTimeout(() => wrapper.classList.add('animated', 'fadeIn'), 200);
        } else {
            this.setState({ preloadNextStep: false });
        }
    };

    changeStep = nextStep => {
        scrollToTop();
        this.setState({
            currentStep: nextStep,
        });
        this.updateProgressBar(nextStep);
    };

    updateProgressBar = nextStep => {
        const dietProgramme = {
            dietProgramme: this.state.dietProgramme || 'full',
        };
        const state = { ...this.props.dietSettings, ...dietProgramme };

        const progress = dietSettingsGetProgress(
            dietSettingsSteps,
            state,
            nextStep
        );

        if (this.state.progress !== progress) {
            this.setState({ progress });
        }
    };

    changeNextBtnState = isDisable => {
        if (this.state.isNextButtonDisabled !== isDisable) {
            this.setState({ isNextButtonDisabled: isDisable });
        }
    };

    renderStep = currentStep => {
        switch (currentStep) {
            case 'start':
                return <StepStart name={this.props.dietSettings.name} />;
            case 'acceptance':
                return <StepAcceptance acceptance={this.state.acceptance} />;
            case 'diet-programme':
                return (
                    <StepDietProgramme
                        dietProgramme={this.state.dietProgramme}
                    />
                );
            case 'fast-preparation-days':
                return (
                    <StepFastPreparationDays
                        fastPreparationDays={this.state.fastPreparationDays}
                    />
                );
            case 'fast-type':
                return <StepFastType fastType={this.state.fastType} />;
            case 'fast-days':
                return <StepFastDays fastDays={this.state.fastDays} />;
            case 'diet-type':
                return <StepDietType dietType={this.state.dietType} />;
            case 'fast-day-of-the-week':
                return (
                    <StepFastDayOfTheWeek
                        fastDayOfTheWeek={this.state.fastDayOfTheWeek}
                    />
                );
            case 'sex-brithday-height':
                return (
                    <StepSexBirthdatHeight
                        sex={this.state.sex}
                        systemOfMeasures={this.state.systemOfMeasures}
                        height={this.state.height}
                        dateOfBirth={this.state.dateOfBirth}
                        errors={this.state.errors}
                        validateData={this.validateData}
                    />
                );
            case 'weight':
                return (
                    <StepWeight
                        weight={this.state.weight}
                        errors={this.state.errors}
                        validateData={this.validateData}
                    />
                );
            case 'goal':
                return (
                    <StepGoal
                        dietMode={this.state.dietMode}
                        goalWeight={this.state.goalWeight}
                        errors={this.state.errors}
                        validateData={this.validateData}
                    />
                );
            case 'work-type':
                return (
                    <StepWorkType
                        regularActivityLevel={this.state.regularActivityLevel}
                    />
                );
            case 'activity-level':
                return (
                    <StepActivityLevel
                        activityLevel={this.state.activityLevel}
                    />
                );
            case 'start-date':
                return (
                    <StepStartDate
                        startDate={this.state.startDate}
                        errors={this.state.errors}
                        validateData={this.validateData}
                    />
                );
            case 'finish':
                return (
                    <StepFinish
                        generateDiet={this.generateDiet}
                        generateDietError={this.state.generateDietError}
                    />
                );

            default:
                return <StepStart />;
        }
    };

    renderAction = () => (
        <StepProvider
            prevStep={() => this.prevStep}
            nextStep={() => this.nextStep}
            handleInputChange={this.handleInputChange}
            changeNextBtnState={this.changeNextBtnState}
            preload={this.state.preloadNextStep}
        >
            <StepActions
                isNextButtonDisabled={this.state.isNextButtonDisabled}
                currentStep={this.state.currentStep}
            />
        </StepProvider>
    );

    render() {
        if (this.state.redirect) {
            return (
                <Redirect
                    to={{
                        pathname: `${PATH_DAY_PLAN_INDEX}/${formatIsoDate(
                            new Date()
                        )}`,
                    }}
                    targetTab="diet"
                />
            );
        }

        return (
            <DietSettings
                progress={this.state.progress}
                renderAction={this.renderAction}
            >
                {() => (
                    <React.Fragment>
                        <StepProvider
                            prevStep={() => this.prevStep}
                            nextStep={() => this.nextStep}
                            handleInputChange={this.handleInputChange}
                            changeNextBtnState={this.changeNextBtnState}
                            preload={this.state.preloadNextStep}
                        >
                            <div id="wrapper" className="wrapper">
                                {this.renderStep(this.state.currentStep)}
                            </div>
                        </StepProvider>
                    </React.Fragment>
                )}
            </DietSettings>
        );
    }
}

export default withFirebase(
    withLoading(withLocale(DietSettingsIndexContainer), DietSettingsPlaceholder)
);
