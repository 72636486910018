import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';
import weightNeeded from 'assets/images/measurement_img.png';
import { PATH_MEASUREMENT_ADD } from 'Measurement/routes';
import { withLocale } from '@dietlabs/components';
import Layout from 'Layout/Layout';
import { PATH_DAY_PLAN_INDEX } from '../DayPlanPaths';

class CurrentWeightRequired extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        renderTimeline: PropTypes.func.isRequired,
    };

    render() {
        return (
            <Layout page="diet">
                {this.props.renderTimeline('top')}
                <section className="bg-white mt-5 pt-5">
                    <Container>
                        <Row>
                            <Col
                                xs="12"
                                md={{ size: 6, offset: 3 }}
                                className="text-center"
                            >
                                <img
                                    srcSet={`${weightNeeded}`}
                                    sizes="50px"
                                    alt=""
                                    className="img-fluid mb-4"
                                />
                                <h2 className="title-medium semi-bold">
                                    {this.props.t(
                                        'info-pages/current-weight-needed/title'
                                    )}
                                </h2>
                                <p>
                                    {this.props.t(
                                        'info-pages/current-weight-needed/content'
                                    )}
                                </p>
                                <Button
                                    color="primary"
                                    className="w-100"
                                    tag={Link}
                                    to={{
                                        pathname: PATH_MEASUREMENT_ADD,
                                        state: {
                                            from: {
                                                pathname: PATH_DAY_PLAN_INDEX,
                                            },
                                        },
                                    }}
                                >
                                    {this.props.t(
                                        'info-pages/current-weight-needed/button'
                                    )}
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Layout>
        );
    }
}

export { CurrentWeightRequired };
export default withLocale(CurrentWeightRequired);
