import { PATH_CONTACT } from 'Contact/routes';
import { PATHS } from '../paths';

export default [
    {
        path: PATHS.EXTERNAL.HOME,
        externalUrl: PATHS.EXTERNAL.HOME,
        translationKey: 'menu/home',
    },
    {
        path: PATH_CONTACT,
        translationKey: 'menu/contact',
    },
    {
        path: PATHS.AUTH.LOGIN,
        translationKey: 'menu/login',
    },
];
