import PropTypes from 'prop-types';
import { useMutation, useApolloClient } from '@apollo/client';
import clearDailyDietPlanCacheObjects from 'Apollo/clearDailyDietPlanCacheObjects';
import DietModeChangeMutation from './DietModeChangeMutation';

const DietModeChangeConnectorApollo = ({ children }) => {
    const client = useApolloClient();
    const [mutate] = useMutation(DietModeChangeMutation);

    const createDietModeChange = async request => {
        const serializedState = client.cache.extract();
        const userId = Object.values(serializedState).filter(
            item => item.__typename === 'Me'
        )[0].id;

        const variables = request;

        const response = await mutate({
            variables,
            update: cache => {
                cache.modify({
                    id: `Me:${userId}`,
                    fields: {
                        goal() {
                            return {
                                reachedBecauseOfLoseWeight: false,
                                reachedBecauseOfPutOnWeight: false,
                                lostBecauseOfLoseWeight: false,
                                lostBecauseOfPutOnWeight: false,
                            };
                        },
                    },
                });
            },
        });

        clearDailyDietPlanCacheObjects(client);

        return response;
    };

    return children({
        dietModeChange: createDietModeChange,
    });
};

DietModeChangeConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
};

export default DietModeChangeConnectorApollo;
