import React, { Component } from 'react';
import {
    Row,
    Col,
    Button,
    Collapse,
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
    InputGroup,
    Input,
} from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import PropTypes from 'prop-types';
import Loader from 'Layout/Loader';
import { scroller, animateScroll, Element } from 'react-scroll';

class DayPlanChangeDay extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        loadDayPlanDayReplacements: PropTypes.func.isRequired,
        renderOriginalDay: PropTypes.func.isRequired,
        children: PropTypes.func.isRequired,
        onRef: PropTypes.func.isRequired,
        date: PropTypes.instanceOf(Date).isRequired,
    };

    state = {
        collapse: false,
        replacements: [],
        original: null,
        dietSetId: 0,
        search: '',
        preloadInside: false,
        preloadOutside: false,
    };

    changeDayButtonRef = React.createRef();

    componentDidUpdate(prevProps) {
        if (prevProps.date !== this.props.date) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ collapse: false });
        }
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    changeDaySuccess() {
        animateScroll.scrollToTop({
            duration: 3000,
            delay: 0,
            smooth: true,
        });

        this.setState({ collapse: false });
    }

    handleClick() {
        if (this.state.collapse) {
            this.setState({ collapse: false });
        } else {
            this.setState({ preloadOutside: true });
            this.loadDayReplacements(this.state.search);
        }
    }

    handleSubmit = event => {
        event.preventDefault();
        this.setState({ preloadInside: true });
        this.loadDayReplacements(this.state.search);
    };

    handleChange = event => {
        this.setState({ search: event.target.value });
    };

    loadDayReplacements = async search => {
        const response = await this.props.loadDayPlanDayReplacements(search);

        scroller.scrollTo('changeDayButton', {
            duration: 500,
            delay: 0,
            smooth: true,
            offset: -56,
        });
        this.setState({
            collapse: true,
            replacements: response.data.me.diet.set.replacements,
            original: response.data.me.diet.set.original,
            dietSetId: response.data.me.diet.set.dietSetId,
            preloadInside: false,
            preloadOutside: false,
        });
    };

    render() {
        return (
            <Row>
                <Col xs="12">
                    <div>
                        <Element name="changeDayButton">
                            <Button
                                color="secondary"
                                innerRef={this.changeDayButtonRef}
                                onClick={() => this.handleClick()}
                                data-test="change-day-button"
                                className={this.state.collapse ? 'outline' : ''}
                                style={{ width: '15rem' }}
                            >
                                {this.state.collapse
                                    ? this.props.t('meal/exchange-collapse')
                                    : this.props.t('meal/exchange-day-button')}
                            </Button>
                        </Element>
                        <Collapse isOpen={this.state.collapse}>
                            <Card>
                                <CardBody>
                                    <Form
                                        onSubmit={this.handleSubmit}
                                        data-test="search-form"
                                    >
                                        <FormGroup row>
                                            <Col xs="12">
                                                <Label
                                                    for="search"
                                                    className="mr-sm-2"
                                                >
                                                    {this.props.t(
                                                        'meal/show-menus-contain'
                                                    )}
                                                </Label>
                                            </Col>
                                            <Col lg="9">
                                                <InputGroup className="form-control">
                                                    <Input
                                                        type="search"
                                                        placeholder={this.props.t(
                                                            'meal/name-of-dish-or-ingredient'
                                                        )}
                                                        value={this.state.value}
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                        data-test="search-input"
                                                    />
                                                </InputGroup>
                                            </Col>
                                            <Col lg="3" className="text-center">
                                                <Button
                                                    color="secondary"
                                                    className="mt-3 mt-lg-0"
                                                >
                                                    {this.props.t(
                                                        'meal/search'
                                                    )}
                                                </Button>
                                            </Col>
                                        </FormGroup>
                                    </Form>
                                    <hr />

                                    {this.state.replacements.length > 0 ? (
                                        <React.Fragment>
                                            {this.state.original.dietSetId !==
                                            this.state.dietSetId ? (
                                                <React.Fragment>
                                                    {this.props.t(
                                                        'meal/original-day'
                                                    )}
                                                    :
                                                    <ul
                                                        className="ingredients"
                                                        data-test="oryginal-day"
                                                    >
                                                        {this.props.renderOriginalDay(
                                                            this.state.original,
                                                            this.state.search,
                                                            {
                                                                changeDaySuccess: () =>
                                                                    this.changeDaySuccess(),
                                                            }
                                                        )}
                                                    </ul>
                                                </React.Fragment>
                                            ) : (
                                                ''
                                            )}
                                            {this.props.t('meal/exchange-for')}:
                                            <ul
                                                className="ingredients"
                                                data-test="day-replacements"
                                            >
                                                {this.props.children(
                                                    this.state.replacements,
                                                    this.state.search,
                                                    {
                                                        changeDaySuccess: () =>
                                                            this.changeDaySuccess(),
                                                    }
                                                )}
                                            </ul>
                                        </React.Fragment>
                                    ) : (
                                        this.props.t(
                                            'meal/there-are-no-replacements'
                                        )
                                    )}

                                    {this.state.preloadInside ? (
                                        <Loader fixed="no" />
                                    ) : (
                                        ''
                                    )}
                                </CardBody>
                            </Card>
                        </Collapse>
                    </div>
                </Col>
                {this.state.preloadOutside ? <Loader /> : ''}
            </Row>
        );
    }
}

export { DayPlanChangeDay };
export default withLocale(DayPlanChangeDay);
