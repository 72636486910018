import React from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import UserDataQuery from './UserDataQuery';

class UserDataOnDemandConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
        client: PropTypes.shape({
            query: PropTypes.func.isRequired,
        }).isRequired,
    };

    fetch = async () => {
        const result = await this.props.client.query({
            query: UserDataQuery,
            fetchPolicy: 'network-only',
        });

        return result;
    };

    render() {
        const { children } = this.props;

        return children({
            userDataOnDemand: this.fetch,
        });
    }
}

export default withApollo(UserDataOnDemandConnectorApollo);
