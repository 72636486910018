import React from 'react';
import NewsletterUnsubscribeConnectorApollo from '@dietlabs/components/src/ApolloConnectors/Newsletter/Unsubscribe/NewsletterUnsubscribeConnectorApollo';
import NewsletterUnsubscribeContainer from './NewsletterUnsubscribeContainer';

class NewsletterUnsubscribePage extends React.Component {
    static propTypes = {};

    render() {
        return (
            <NewsletterUnsubscribeConnectorApollo>
                {({ ...unsubscribe }) => (
                    <NewsletterUnsubscribeContainer {...unsubscribe} />
                )}
            </NewsletterUnsubscribeConnectorApollo>
        );
    }
}

export default NewsletterUnsubscribePage;
