import { PATH_SETTINGS } from 'UserSettings/routes';
import { PATH_CONTACT } from 'Contact/routes';
import { PATHS } from '../paths';

export default [
    {
        path: PATH_SETTINGS,
        translationKey: 'menu/settings',
    },
    {
        path: PATH_CONTACT,
        translationKey: 'menu/contact',
    },
    {
        path: PATHS.AUTH.LOGOUT,
        translationKey: 'menu/logout',
    },
];
