import React from 'react';
// import PropTypes from 'prop-types';
import { Container, Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withLocale } from '@dietlabs/components';
import { GOOGLE_PLAY_URL, APP_STORE_URL } from 'consts';
import appStoreIcon from 'assets/icons/app_store_badge.svg';
import googlePlayIcon from 'assets/icons/google_play_badge.svg';
import loginImage from 'assets/images/thankyou/login_img.png';
import loginImage2x from 'assets/images/thankyou/login_img@2x.png';
import remindPassword from 'assets/images/thankyou/remind_password.png';
import { PATHS } from 'config/paths';

const ShoppingCartThankYouContentComponent = ({
    extraOffset,
    isPast48Hours,
}) => (
    <section className="py-5 bg-white">
        <Container>
            <Row>
                <Col md={{ size: 8, offset: extraOffset && 2 }}>
                    {isPast48Hours ? (
                        <>
                            <p className="text-center">
                                Przed Tobą 3 proste kroki:
                            </p>
                            <ol>
                                <li>
                                    Zaloguj się
                                    <p>
                                        Użyj opcji{' '}
                                        <strong>
                                            Mam już konto, loguje się
                                        </strong>{' '}
                                        widocznej na dole ekranu startowego
                                    </p>
                                    <Row>
                                        <Col xs="12" md="6">
                                            <img
                                                src={remindPassword}
                                                alt="login"
                                                className="img-fluid"
                                            />
                                        </Col>
                                    </Row>
                                </li>
                                <li>
                                    Nie pamiętasz hasła?
                                    <p>
                                        Pomożemy Ci! Wystarczy, że skorzystasz z
                                        opcji przypomnienia:
                                    </p>
                                    <Button
                                        color="primary"
                                        className="text-left"
                                        tag={Link}
                                        to={PATHS.AUTH.REMIND_PASSWORD}
                                    >
                                        NIE PAMIĘTAM HASŁA
                                    </Button>
                                </li>
                                <li>
                                    Wykorzystaj pełnię możliwości aplikacji!
                                    <p>
                                        Post w wariancie klasycznym i
                                        uproszczonym, łatwe przygotowanie i
                                        smaczne wyjście z postu, 3 rodzaje
                                        codziennej, zdrowej diety, ekspercka
                                        wiedza i 80+ relaksacyjnych ścieżek.
                                        Zadbaj o siebie w pełni!
                                    </p>
                                </li>
                            </ol>
                        </>
                    ) : (
                        <>
                            <h2>Jak rozpocząć korzystanie z aplikacji?</h2>
                            <p className="">
                                Jeśli nie masz jeszcze konta w aplikacji,
                                wykonaj następujące kroki:
                            </p>

                            <ol>
                                <li>
                                    Dokończ rejestrację swojego konta
                                    <p>
                                        Wysłaliśmy do Ciebie mail z
                                        potwierdzeniem zamówienia oraz{' '}
                                        <strong>
                                            linkiem, który pozwala na utworzenie
                                            hasła do Twojego konta w aplikacji.
                                        </strong>{' '}
                                        Odczytaj wiadomość i dokończ
                                        rejestrację. <br />
                                        Jeśli nie otrzymałaś wiadomości, sprawdź
                                        folder spam.
                                    </p>
                                </li>
                                <li>
                                    Pobierz aplikację na swój telefon
                                    <p>
                                        Aplikacja dostępna na urządzenia z
                                        systemem iOS oraz Android.
                                    </p>
                                    <a
                                        href={APP_STORE_URL}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="mr-3"
                                    >
                                        <img
                                            src={appStoreIcon}
                                            alt="App Store"
                                        />
                                    </a>
                                    <a
                                        href={GOOGLE_PLAY_URL}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img
                                            src={googlePlayIcon}
                                            alt="Google Play"
                                        />
                                    </a>
                                </li>
                                <li>
                                    Zaloguj się w aplikacji
                                    <p>
                                        Użyj opcji <strong>Zaloguj się</strong>{' '}
                                        na widoku startowym aplikacji.
                                    </p>
                                    <img
                                        srcSet={`${loginImage} 1x, ${loginImage2x} 2x`}
                                        src={loginImage}
                                        alt="login"
                                    />
                                </li>
                                <li>
                                    Korzystaj z aplikacji
                                    <p>
                                        Pyszna i zdrowa dieta Dr Dąbrowskiej
                                        czeka na Ciebie w aplikacji. Do dzieła!
                                    </p>
                                </li>
                            </ol>
                        </>
                    )}
                </Col>
            </Row>
        </Container>
    </section>
);
ShoppingCartThankYouContentComponent.propTypes = {
    // t: PropTypes.func.isRequired,
    // isDietInPayment: PropTypes.bool.isRequired,
    // isTrainingInPayment: PropTypes.bool.isRequired,
    // isCod: PropTypes.bool.isRequired,
    // containsPhysicalProduct: PropTypes.bool.isRequired,
};

export default withLocale(ShoppingCartThankYouContentComponent);
