import { useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import { formatIsoDate, addDays } from 'utils/dateFormatter';
import PropTypes from 'prop-types';
import DaiyDietPlanQuery from './DailyDietPlanQuery';

const DailyDietPlanCacheConnectorApollo = ({ children, date }) => {
    const client = useApolloClient();
    const requestIdleCallbackIds = [];

    useEffect(() => {
        return () => {
            if (window.cancelIdleCallback) {
                requestIdleCallbackIds.forEach(id => {
                    window.cancelIdleCallback(id);
                });
            }
        };
    }, []);

    const idle = callback => {
        if (!window.requestIdleCallback) {
            callback();
            return;
        }
        requestIdleCallbackIds.push(window.requestIdleCallback(callback));
    };

    const createFetch = () => {
        const cacheDayIndex = [1, -1, 2, 3];

        cacheDayIndex.forEach(dayIndex => {
            idle(() =>
                client.query({
                    query: DaiyDietPlanQuery,
                    variables: {
                        date: formatIsoDate(addDays(date, dayIndex)),
                        canBeCopiedToDate: formatIsoDate(
                            addDays(date, dayIndex + 1)
                        ),
                    },
                })
            );
        });
    };

    return children({
        prefetchSurroundingDays: () => createFetch(),
    });
};

DailyDietPlanCacheConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
    date: PropTypes.instanceOf(Date).isRequired,
};

export default DailyDietPlanCacheConnectorApollo;
