export const MEASUREMENT_TYPES = {
    WEIGHT: 'weight',
    HIP: 'hip',
    WAIST: 'waist',
    THIGH: 'thigh',
    CHEST: 'chest',
    ARM: 'arm',
};

export const PERIOD_END = new Date();
export const PERIOD_LENGTH = 31;
