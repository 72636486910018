import gql from 'graphql-tag';
import * as types from 'DailyDietPlan/DayPlanTypes';

const query = gql`
    query DailyPlan($today: Date!) {
        me {
            id
            recipeNotesForNextDay: dailyDietPlan(date: $today) {
                __typename
                ... on ${types.DAY_PLAN} {
                    id
                    recipeNotesForNextDay
                }
            }
            goal {
                reachedBecauseOfLoseWeight
                reachedBecauseOfPutOnWeight
                lostBecauseOfLoseWeight
                lostBecauseOfPutOnWeight
            }
            diet {
                dietId: id
                fastType
                availableDays {
                    date
                    holiday {
                        identifier
                        name
                        description
                        icon
                    }
                    dayPlan {
                        dietSetId
                        diet {
                            id
                            name
                            phase {
                                identifier
                                name
                            }
                        }
                    }
                }
                accessTo
            }
            activeSubscriptions {
                givesAccessToDiet
            }
            isTimeToNagForCurrentMeasurement
            fastingStatistics {
                dietsCount
                currentDietLength
                currentDietProgress
            }
            intermittentFasting{
                activeCycle{
                    phase
                    phaseFinishPlannedTo
                }
            }
        }
    }
`;

export default query;
