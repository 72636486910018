import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import ewa from 'assets/images/dr_ewa.jpg';
import { PATHS } from 'config/paths';
import { withLocale } from '@dietlabs/components';
import Layout from 'Layout/Layout';

class DietCompleted extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        renderTimeline: PropTypes.func,
        dietId: PropTypes.number.isRequired,
        dietEndDate: PropTypes.instanceOf(Date).isRequired,
    };

    static defaultProps = {
        renderTimeline: undefined,
    };

    renderMessage() {
        let dietPhase;

        switch (this.props.dietId) {
            case 1:
                dietPhase = this.props.t(
                    'diet-settings/diet-programme/fast-preparation/title'
                );
                break;
            case 2:
                dietPhase = this.props.t(
                    'diet-settings/diet-programme/fast/title'
                );
                break;
            case 3:
                throw new Error('Diet completed shouldnt occured. Diet id = 3');
            case 4:
                throw new Error('Diet completed shouldnt occured. Diet id = 4');
            case 5:
                dietPhase = this.props.t(
                    'diet-settings/diet-programme/fast-preparation/title'
                );
                break;
            case 6:
                dietPhase = this.props.t(
                    'diet-settings/diet-programme/fast-exit/title'
                );
                break;
            case 7:
                dietPhase = this.props.t(
                    'diet-settings/diet-programme/fast-exit/title'
                );
                break;
            case 8:
                dietPhase = this.props.t(
                    'diet-settings/diet-programme/fast/title'
                );
                break;
            case 9:
                dietPhase = this.props.t(
                    'diet-settings/diet-programme/fast-preparation/title'
                );
                break;
            case 10:
                dietPhase = this.props.t(
                    'diet-settings/diet-programme/fast-exit/title'
                );
                break;
            default:
                dietPhase = this.props.t(
                    'diet-settings/diet-programme/fast-preparation/title'
                );
        }

        const today = new Date();

        if (today < this.props.dietEndDate) {
            const count = Math.ceil(
                (this.props.dietEndDate.getTime() - today.getTime()) /
                    (1000 * 60 * 60 * 24)
            );

            return this.props.t('info-pages/diet-completed/in-future', {
                count,
                phase: dietPhase,
            });
        }

        return this.props.t('info-pages/diet-completed/now', {
            phase: dietPhase.toLowerCase(),
        });
    }

    render() {
        return (
            <Layout page="diet">
                {this.props.renderTimeline && this.props.renderTimeline('top')}
                <section className="bg-white">
                    <Container>
                        <Row>
                            <Col xs="12" className="text-center">
                                <img
                                    className="mb-4"
                                    src={ewa}
                                    role="presentation"
                                    alt=""
                                />
                                <p>{this.renderMessage()}</p>

                                <Button
                                    color="secondary"
                                    className="mb-4"
                                    tag={Link}
                                    to={PATHS.DIET_SETTINGS}
                                >
                                    {this.props.t('menu/diet/settings')}
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Layout>
        );
    }
}

export { DietCompleted };
export default withLocale(DietCompleted);
