import React from 'react';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import TableRow, {
    TABLE_ROW_SIZE_BIG,
    TABLE_ROW_SIZE_NORMAL,
} from 'Layout/Table/TableRow';
import TableRowCell, {
    TABLE_ROW_CELL_ALIGN_RIGHT,
} from 'Layout/Table/TableRowCell';
import { Link } from 'react-router-dom';
import Change from 'Layout/Change';
import { PATH_MEASUREMENT_EDIT } from 'Measurement/routes';

export class CurrentMeasurementsTableRow extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        label: PropTypes.string.isRequired,
        value: PropTypes.number,
        unit: PropTypes.string,
        change: PropTypes.number,
        date: PropTypes.string,
    };

    static defaultProps = {
        value: undefined,
        unit: undefined,
        change: undefined,
        date: undefined,
    };

    render() {
        const { t, label, date, value, unit, change } = this.props;

        if (date && value && unit) {
            return (
                <TableRow
                    data-test="with-data"
                    size={change ? TABLE_ROW_SIZE_BIG : TABLE_ROW_SIZE_NORMAL}
                    redirectToOnClick={
                        PATH_MEASUREMENT_EDIT.split(':')[0] + date
                    }
                >
                    <TableRowCell th>
                        <Link to={PATH_MEASUREMENT_EDIT.split(':')[0] + date}>
                            {label}
                        </Link>
                    </TableRowCell>
                    <TableRowCell>
                        <Link to={PATH_MEASUREMENT_EDIT.split(':')[0] + date}>
                            {`${value} ${unit}`}
                        </Link>
                    </TableRowCell>
                    <TableRowCell aligment={TABLE_ROW_CELL_ALIGN_RIGHT}>
                        <Link to={PATH_MEASUREMENT_EDIT.split(':')[0] + date}>
                            <Change
                                label={`${change} ${unit}`}
                                difference={change}
                            />
                        </Link>
                    </TableRowCell>
                </TableRow>
            );
        }

        return (
            <TableRow data-test="no-data">
                <TableRowCell th>{label}</TableRowCell>
                <TableRowCell>{t('no-data')}</TableRowCell>
                <TableRowCell />
            </TableRow>
        );
    }
}
export default withLocale(CurrentMeasurementsTableRow);
