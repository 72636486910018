import PropTypes from 'prop-types';
import { useMutation, useApolloClient } from '@apollo/client';
import DietSettingsGenerateDietMutation from 'DietSettings/GraphQL/DietSettingsGenerateDietMutation';
import DietSettingsQuery from 'DietSettings/GraphQL/DietSettingsQuery';
import clearDailyDietPlanCacheObjects from 'Apollo/clearDailyDietPlanCacheObjects';

const DietSettingsGenerateDietConnectorApollo = ({ children }) => {
    const client = useApolloClient();

    const [generateDiet] = useMutation(DietSettingsGenerateDietMutation, {
        refetchQueries: [DietSettingsQuery],
    });

    const createGenerateDiet = async request => {
        const variables = {
            request,
        };

        const response = await generateDiet({
            variables,
        });

        clearDailyDietPlanCacheObjects(client);

        return response;
    };

    return children({
        generateDiet: createGenerateDiet,
    });
};

DietSettingsGenerateDietConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
};

export default DietSettingsGenerateDietConnectorApollo;
