import gql from 'graphql-tag';

const query = gql`
    query DietSettings {
        me {
            id
            name
            email
            systemOfMeasures
            sex
            height {
                value
                unit
            }
            goalWeight {
                value
                unit
            }
            birthDate
            diet {
                id
                mode
                activityLevel
                regularActivityLevel
                dietProgramme
                fastPreparationDays
                fastType
                fastDays
                dietType
                fastDayOfTheWeek
                hadEver
            }
            lastMeasurement: measurement {
                weight(request: { limit: 1 }) {
                    value
                    unit
                }
            }
            firstMeasurement: measurement {
                weight(request: { limit: 1, order: asc }) {
                    value
                    unit
                }
            }
        }
    }
`;

export default query;
