import gql from 'graphql-tag';

const query = gql`
    query ShoppingCartIndexLoad($promoCode: String) {
        sale {
            productVariants(promoCode: $promoCode) {
                id
                name
                saleProduct {
                    price {
                        regular {
                            amount
                            currency
                        }
                        promo {
                            amount
                            currency
                        }
                    }
                    isVirtual
                    containsDiet
                    containsWorkout
                    tags
                    isBestseller
                    shortDescription
                    longDescription
                    lengthLabel
                    vendor {
                        identifier
                    }
                }
            }
            shippingOptions {
                id
                name
                onlyVirtualProducts
                requiresAddress
                price {
                    amount
                    currency
                }
                freeFromCartValue {
                    amount
                    currency
                }
                countries {
                    code
                    name
                }
                isCod
            }
        }
        me {
            shipping {
                name
                address: street
                postalCode
                city
                phone
                country {
                    code
                    name
                }
            }
        }
    }
`;

export default query;
