export default {
    apiKey: 'AIzaSyBs3Z2Tkz2lMJI64cY83EcwTD7Dtgm2XK4',
    authDomain: 'dieta-dr-ewy-dabrowskiej.firebaseapp.com',
    databaseURL: 'https://dieta-dr-ewy-dabrowskiej.firebaseio.com',
    projectId: 'dieta-dr-ewy-dabrowskiej',
    storageBucket: 'dieta-dr-ewy-dabrowskiej.appspot.com',
    messagingSenderId: '352392791292',
    appId: '1:352392791292:web:b6bfa64b4884a411e00379',
    measurementId: 'G-JH2X3QXNKE',
};
