import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';
import InputRadio from 'components/FormElements/InputRadioControlled';
import { withLocale } from '@dietlabs/components';
import withFirebase from 'view/FirebaseAnalytics/withFirebase';
import withStep from './StepContext/withStep';

class StepWorkType extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        regularActivityLevel: PropTypes.string,
        handleInputChange: PropTypes.func.isRequired,
        changeNextBtnState: PropTypes.func.isRequired,
        trackFirebaseEvent: PropTypes.func.isRequired,
    };

    static defaultProps = {
        regularActivityLevel: undefined,
    };

    componentDidMount() {
        this.props.changeNextBtnState(!this.props.regularActivityLevel);
        this.props.trackFirebaseEvent('screen_view ', {
            firebase_screen: 'activity',
        });
    }

    componentDidUpdate() {
        this.props.changeNextBtnState(!this.props.regularActivityLevel);
    }

    render() {
        const { regularActivityLevel, handleInputChange } = this.props;

        return (
            <div className="full-screen3">
                <div className="my-auto w-100">
                    <FormGroup tag="fieldset">
                        <Label>
                            {this.props.t('diet-settings/work-type/label')}
                        </Label>

                        <InputRadio
                            label={[
                                <strong key="little" className="d-block">
                                    {this.props.t(
                                        'diet-settings/work-type/low/title'
                                    )}
                                </strong>,
                                this.props.t(
                                    'diet-settings/work-type/low/description'
                                ),
                            ]}
                            name="regularActivityLevel"
                            value="low"
                            handleChange={handleInputChange}
                            checked={regularActivityLevel === 'low'}
                            data-test="work-type-low"
                        />

                        <InputRadio
                            label={[
                                <strong key="medium" className="d-block">
                                    {this.props.t(
                                        'diet-settings/work-type/medium/title'
                                    )}
                                </strong>,
                                this.props.t(
                                    'diet-settings/work-type/medium/description'
                                ),
                            ]}
                            name="regularActivityLevel"
                            value="medium"
                            handleChange={handleInputChange}
                            checked={regularActivityLevel === 'medium'}
                            data-test="work-type-medium"
                        />

                        <InputRadio
                            label={[
                                <strong key="medium" className="d-block">
                                    {this.props.t(
                                        'diet-settings/work-type/high/title'
                                    )}
                                </strong>,
                                this.props.t(
                                    'diet-settings/work-type/high/description'
                                ),
                            ]}
                            name="regularActivityLevel"
                            value="high"
                            handleChange={handleInputChange}
                            checked={regularActivityLevel === 'high'}
                            data-test="work-type-high"
                        />
                    </FormGroup>
                </div>
            </div>
        );
    }
}

export default withFirebase(withStep(withLocale(StepWorkType)));
