import React, { Component } from 'react';
import {
    Container,
    Row,
    Col,
    Button,
    Alert,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import PropTypes from 'prop-types';
import Layout from 'Layout/Layout';
import { Link } from 'react-router-dom';
import { PATHS } from 'config/paths';
import { PATH_MEASUREMENT_ADD } from 'Measurement/routes';
import { formatIsoDate } from 'utils/dateFormatter';
import { getHolidayIconName } from 'DailyDietPlan/utils/getHolidayIconName';
import withGTM from 'view/GoogleTagManager/withGTM';
import withMobileAppMode from 'Layout/withMobileAppMode';
import { CSSTransition } from 'react-transition-group';
import { callNativeAppRemoveLastRoute } from 'components/Helpers/callNativeAppRemoveLastRoute';
import { callNativeSharing } from 'components/Helpers/callNativeSharing';
import Footer from 'Layout/Footer';
import withFirebase from 'view/FirebaseAnalytics/withFirebase';
import { isToday } from 'utils/date';
import { holidayIcons, IMAGE_SHARING_URL } from 'consts';
import { callNativeApp } from 'components/Helpers/callNativeApp';
import fastingStatusFasting from 'assets/icons/if/fasting-status-fasting.svg';
import fastingStatusEating from 'assets/icons/if/fasting-status-eating.svg';
import { PATH_DAY_PLAN_INDEX } from '../../DayPlanPaths';

class DayPlan extends Component {
    static propTypes = {
        date: PropTypes.instanceOf(Date).isRequired,
        kcal: PropTypes.number.isRequired,
        phase: PropTypes.shape({
            identifier: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            colour: PropTypes.string.isRequired,
        }).isRequired,
        t: PropTypes.func.isRequired,
        children: PropTypes.func.isRequired,
        renderTimeline: PropTypes.func.isRequired,
        availableDays: PropTypes.arrayOf(PropTypes.object.isRequired)
            .isRequired,
        goal: PropTypes.shape({
            reachedBecauseOfLoseWeight: PropTypes.bool.isRequired,
            reachedBecauseOfPutOnWeight: PropTypes.bool.isRequired,
            lostBecauseOfLoseWeight: PropTypes.bool.isRequired,
            lostBecauseOfPutOnWeight: PropTypes.bool.isRequired,
        }).isRequired,
        isHolidayMenu: PropTypes.bool.isRequired,
        isTimeToNagForCurrentMeasurement: PropTypes.bool.isRequired,
        // trackEvent: PropTypes.func.isRequired,
        recipeNotesForNextDay: PropTypes.arrayOf(PropTypes.string).isRequired,
        renderMealLinks: PropTypes.func.isRequired,
        macro: PropTypes.shape({
            animalProtein: PropTypes.shape({
                grams: PropTypes.number.isRequired,
                kcal: PropTypes.number.isRequired,
                percentage: PropTypes.number.isRequired,
            }).isRequired,
            carbohydrates: PropTypes.shape({
                grams: PropTypes.number.isRequired,
                kcal: PropTypes.number.isRequired,
                percentage: PropTypes.number.isRequired,
            }).isRequired,
            fat: PropTypes.shape({
                grams: PropTypes.number.isRequired,
                kcal: PropTypes.number.isRequired,
                percentage: PropTypes.number.isRequired,
            }).isRequired,
            protein: PropTypes.shape({
                grams: PropTypes.number.isRequired,
                kcal: PropTypes.number.isRequired,
                percentage: PropTypes.number.isRequired,
            }).isRequired,
        }).isRequired,
        fastingStatistics: PropTypes.shape({
            dietsCount: PropTypes.number,
            currentDietLength: PropTypes.number,
            currentDietProgress: PropTypes.number,
        }).isRequired,
        mobileApp: PropTypes.shape({
            active: PropTypes.bool,
            os: PropTypes.string,
            version: PropTypes.string,
        }).isRequired,
        showModal: PropTypes.bool.isRequired,
        renderMealDetails: PropTypes.func.isRequired,
        showModalWithReplacements: PropTypes.bool.isRequired,
        renderMealReplacementsList: PropTypes.func.isRequired,
        renderChangeDay: PropTypes.func.isRequired,
        toggleModalWithDayReplacements: PropTypes.func.isRequired,
        showModalWithDayReplacements: PropTypes.bool.isRequired,
        mobileAppMode: PropTypes.bool.isRequired,
        trackFirebaseEvent: PropTypes.func.isRequired,
        name: PropTypes.string.isRequired,
        categories: PropTypes.arrayOf(PropTypes.shape()).isRequired,

        showMacros: PropTypes.bool.isRequired,
        fastType: PropTypes.string.isRequired,

        intermittentFasting: PropTypes.shape({
            activeCycle: PropTypes.shape({
                phase: PropTypes.string,
                phaseFinishPlannedTo: PropTypes.instanceOf(Date),
            }),
        }).isRequired,
    };

    state = {
        showModal: false,
    };

    toggle = () => {
        this.setState(prevState => ({
            showModal: !prevState.showModal,
        }));
    };

    componentDidMount() {
        this.props.trackFirebaseEvent('screen_view ', {
            firebase_screen: 'Dayplan',
        });
    }

    renderGoalAlert() {
        if (this.props.goal) {
            if (
                this.props.goal.lostBecauseOfLoseWeight ||
                this.props.goal.lostBecauseOfPutOnWeight
            ) {
                return (
                    <Alert color="danger" data-test="goal-lost">
                        <h4>{this.props.t('info-pages/goal-lost/title')}</h4>
                        <p className="pink mb-3">
                            {this.props.t('info-pages/goal-lost/content')}
                        </p>
                        <Button
                            color="primary"
                            size="sm"
                            tag={Link}
                            to={PATHS.MAKE_DECISION}
                        >
                            {this.props.t('info-pages/goal-lost/button')}
                        </Button>
                    </Alert>
                );
            }

            if (
                this.props.goal.reachedBecauseOfLoseWeight ||
                this.props.goal.reachedBecauseOfPutOnWeight
            ) {
                return (
                    <Alert color="info" data-test="goal-reached">
                        <h4>{this.props.t('info-pages/goal-reached/title')}</h4>
                        <p className="navy mb-3">
                            {this.props.t('info-pages/goal-reached/content')}
                        </p>
                        <Button
                            color="primary"
                            size="sm"
                            tag={Link}
                            to={PATHS.MAKE_DECISION}
                        >
                            {this.props.t('info-pages/goal-reached/button')}
                        </Button>
                    </Alert>
                );
            }
        }

        return '';
    }

    renderMeasurementAlert() {
        if (this.props.isTimeToNagForCurrentMeasurement) {
            return (
                <Alert color="info">
                    <p>
                        {this.props.t('alert/periodic-measurements-1')}
                        <br />
                        <br />
                        {this.props.t('alert/periodic-measurements-2')}
                    </p>
                    <Button
                        color="primary"
                        size="sm"
                        tag={Link}
                        to={{
                            pathname: PATH_MEASUREMENT_ADD,
                            state: {
                                from: {
                                    pathname: `${PATH_DAY_PLAN_INDEX}/${formatIsoDate(
                                        this.props.date
                                    )}`,
                                },
                            },
                        }}
                    >
                        {this.props.t(
                            'info-pages/current-weight-needed/button'
                        )}
                    </Button>
                </Alert>
            );
        }

        return '';
    }

    renderHolidayAlert() {
        const isThisHoliday = getHolidayIconName(
            this.props.availableDays,
            formatIsoDate(this.props.date)
        );
        if (isThisHoliday && !this.props.isHolidayMenu) {
            return (
                <Alert
                    color="info"
                    className="mb-4 text-left clickable"
                    data-test="holiday"
                >
                    <div>
                        <p>
                            {this.renderHolidayIcon(this.props.date)} Pokaż
                            jadłospis specjalny dla tego dnia
                        </p>
                        <Button
                            color="secondary"
                            className="d-block mt-2"
                            onClick={() =>
                                this.props.toggleModalWithDayReplacements()
                            }
                            data-test="show-holiday-menu"
                        >
                            {this.props.t('show-special-menu')}
                        </Button>
                    </div>
                </Alert>
            );
        }

        return '';
    }

    renderHolidayIcon(date) {
        const iconName = getHolidayIconName(
            this.props.availableDays,
            formatIsoDate(date)
        );
        if (iconName) {
            const Icon = holidayIcons[iconName];
            if (Icon) {
                return (
                    <span className="SVGInline" data-test="holiday-icon">
                        <Icon />
                    </span>
                );
            }
        }

        return '';
    }

    renderTomorrowAlert() {
        let tomorrowAlert = [];

        if (
            this.props.recipeNotesForNextDay &&
            Object.keys(this.props.recipeNotesForNextDay).length !== 0
        ) {
            tomorrowAlert = [...this.props.recipeNotesForNextDay];
        }

        const uniqTomorrowAlert = [...new Set(tomorrowAlert)];

        if (uniqTomorrowAlert.length > 0) {
            return (
                <Alert
                    color="success"
                    className="text-left"
                    data-test="tomorrow-alert"
                >
                    <h4>{this.props.t('next-day-notification')}</h4>
                    <ul>
                        {uniqTomorrowAlert.map((alert, idx, arr) => (
                            <li key={arr.indexOf(alert)}>{alert}</li>
                        ))}
                    </ul>
                </Alert>
            );
        }

        return '';
    }

    renderIntermittentFastingAlert() {
        if (this.props.intermittentFasting && this.props.mobileAppMode) {
            const { activeCycle } = this.props.intermittentFasting;

            if (activeCycle) {
                let alertColor;
                let alertMsg;
                let alertIcon;

                const now = new Date();
                const endOfPhase = new Date(activeCycle.phaseFinishPlannedTo);
                const hours =
                    endOfPhase.getHours() < 10
                        ? `0${endOfPhase.getHours()}`
                        : endOfPhase.getHours();
                const minutes =
                    endOfPhase.getMinutes() < 10
                        ? `0${endOfPhase.getMinutes()}`
                        : endOfPhase.getMinutes();

                if (activeCycle.phase === 'PHASE_FASTING') {
                    alertColor = 'danger';
                    alertIcon = fastingStatusFasting;

                    if (now > endOfPhase) {
                        alertMsg = this.props.t(
                            'intetmittent-fasting/end-of-post'
                        );

                        this.props.trackFirebaseEvent('screen_view', {
                            firebase_screen: 'Dietplan',
                            fasting_status: 'fastingexceeded',
                        });
                    } else {
                        alertMsg = isToday(endOfPhase)
                            ? `${this.props.t(
                                  'intetmittent-fasting/next-eat-window-today'
                              )}, ${hours}:${minutes}`
                            : `${this.props.t(
                                  'intetmittent-fasting/next-eat-window-tomorrow'
                              )}, ${hours}:${minutes}`;

                        this.props.trackFirebaseEvent('screen_view', {
                            firebase_screen: 'Dietplan',
                            fasting_status: 'fasting',
                        });
                    }
                } else if (activeCycle.phase === 'PHASE_EATING') {
                    alertColor = 'info';
                    alertIcon = fastingStatusEating;
                    alertMsg = `${this.props.t(
                        'intetmittent-fasting/eat-window-to'
                    )} ${hours}:${minutes}`;

                    this.props.trackFirebaseEvent('screen_view', {
                        firebase_screen: 'Dayplan',
                        fasting_status: 'eatingwindow',
                    });
                }

                return (
                    <Alert
                        color={alertColor}
                        className="mb-4 text-left clickable"
                        data-test="if"
                        onClick={() => {
                            this.props.trackFirebaseEvent('user_action', {
                                action: 'tap-fasting_notification',
                                location: 'Dayplan',
                            });

                            return callNativeApp(
                                '/',
                                'intermittent-fasting',
                                true
                            );
                        }}
                    >
                        <div className="d-flex align-items-center">
                            <img src={alertIcon} className="mr-2" alt="" />
                            <p className="mb-0">{alertMsg}</p>
                        </div>
                    </Alert>
                );
            }
        }

        return null;
    }

    renderDietPhase() {
        const FAST_DIET = 'Post';

        let phaseName;

        if (this.props.name === FAST_DIET) {
            let fastType = '';

            if (this.props.fastType) {
                fastType = this.props.t(this.props.fastType);
            }

            if (this.props.categories[0]) {
                fastType = this.props.categories[0].name.toLowerCase();
            }
            phaseName = `${this.props.phase.name} ${fastType}`;

            if (isToday(this.props.date)) {
                phaseName = `${this.props.phase.name} ${fastType} ${this.props.fastingStatistics.currentDietProgress}/${this.props.fastingStatistics.currentDietLength}/${this.props.fastingStatistics.dietsCount}`;
            }
        } else {
            phaseName = this.props.phase.name;
        }

        let displayShareButton = false;
        if (
            this.props.name === FAST_DIET &&
            this.props.mobileApp.os &&
            this.props.mobileApp.version &&
            isToday(this.props.date)
        ) {
            displayShareButton = true;
        }

        return (
            <React.Fragment>
                <div className={`diet-phase ${this.props.phase.colour}`}>
                    {phaseName}
                </div>

                {displayShareButton ? (
                    <Button
                        className="diet-phase share-button ml-3"
                        onClick={this.toggle}
                    >
                        {this.props.t('share')}
                    </Button>
                ) : (
                    ''
                )}

                <Modal
                    isOpen={this.state.showModal}
                    toggle={this.toggle}
                    centered
                    size="lg"
                >
                    <ModalHeader toggle={this.toggle} />
                    <ModalBody className="text-center pt-0">
                        <div
                            className={`diet-phase ${this.props.phase.colour} mb-3`}
                        >
                            {phaseName}
                        </div>
                        <h3>{this.props.t('share/how-to-read')}</h3>
                        <p>
                            <span className="fast-code">
                                <strong>
                                    {
                                        this.props.fastingStatistics
                                            .currentDietProgress
                                    }
                                </strong>
                                /
                                {this.props.fastingStatistics.currentDietLength}
                                /{this.props.fastingStatistics.dietsCount}
                            </span>{' '}
                            - {this.props.t('share/current-diet-progress')}
                            <br />
                            <span className="fast-code">
                                {
                                    this.props.fastingStatistics
                                        .currentDietProgress
                                }
                                /
                                <strong>
                                    {
                                        this.props.fastingStatistics
                                            .currentDietLength
                                    }
                                </strong>
                                /{this.props.fastingStatistics.dietsCount}
                            </span>{' '}
                            - {this.props.t('share/current-diet-length')}
                            <br />
                            <span className="fast-code">
                                {
                                    this.props.fastingStatistics
                                        .currentDietProgress
                                }
                                /
                                {this.props.fastingStatistics.currentDietLength}
                                /
                                <strong>
                                    {this.props.fastingStatistics.dietsCount}
                                </strong>
                            </span>{' '}
                            - {this.props.t('share/diets-count')}
                        </p>
                        <hr />
                        <Button
                            className="diet-phase share-button mb-3"
                            onClick={() => callNativeSharing(IMAGE_SHARING_URL)}
                        >
                            {this.props.t('share')}
                        </Button>
                        <h3>{this.props.t('share/title')}</h3>
                        <p>{this.props.t('share/description')}</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={() => callNativeSharing(IMAGE_SHARING_URL)}
                        >
                            {this.props.t('share')}
                        </Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }

    render() {
        return (
            <Layout page="diet-new">
                <div
                    className={
                        this.props.showModal ||
                        this.props.showModalWithReplacements
                            ? 'modalContainer showModal'
                            : 'modalContainer'
                    }
                >
                    {this.props.renderTimeline('top')}

                    <header>
                        <Container className="text-center">
                            <Row>
                                <Col lg={{ size: 8, offset: 2 }}>
                                    {this.renderIntermittentFastingAlert()}
                                    {this.renderGoalAlert()}
                                    {this.renderMeasurementAlert()}
                                    {this.renderTomorrowAlert()}
                                    {this.renderHolidayAlert()}

                                    <h1 className="heading-2">
                                        {this.props.t(
                                            `meal/weekday${this.props.date.getDay()}`
                                        )}

                                        {this.props.showMacros ? (
                                            <React.Fragment>
                                                ,{' '}
                                                <span className="text-nowrap">
                                                    {this.props.kcal} kcal
                                                </span>
                                            </React.Fragment>
                                        ) : (
                                            ''
                                        )}
                                    </h1>
                                    {this.props.showMacros ? (
                                        <p className="BTW">
                                            {this.props.t(
                                                'meal/proteins-short'
                                            )}
                                            {': '}
                                            <strong>
                                                {
                                                    this.props.macro.protein
                                                        .percentage
                                                }
                                                %
                                            </strong>
                                            &nbsp;
                                            {this.props.t('meal/fat-short')}
                                            {': '}
                                            <strong>
                                                {
                                                    this.props.macro.fat
                                                        .percentage
                                                }
                                                %
                                            </strong>
                                            &nbsp;
                                            {this.props.t(
                                                'meal/carbohydrates-short'
                                            )}
                                            {': '}
                                            <strong>
                                                {
                                                    this.props.macro
                                                        .carbohydrates
                                                        .percentage
                                                }
                                                %
                                            </strong>
                                        </p>
                                    ) : (
                                        ''
                                    )}
                                    {this.renderDietPhase()}
                                </Col>
                            </Row>
                        </Container>
                    </header>
                    {this.props.children()}
                </div>

                <CSSTransition
                    in={this.props.showModal}
                    timeout={500}
                    classNames="view"
                    unmountOnExit
                >
                    <div className="view bg-white">
                        {this.props.renderMealLinks()}
                        {this.props.renderMealDetails()}
                        {!this.props.mobileAppMode && (
                            <Footer forceReloadLinks={false} />
                        )}
                    </div>
                </CSSTransition>

                <CSSTransition
                    in={this.props.showModalWithReplacements}
                    timeout={500}
                    classNames="view"
                    unmountOnExit
                >
                    <div className="view bg-light-grey">
                        {this.props.renderMealReplacementsList()}
                        {!this.props.mobileAppMode && (
                            <section className="pt-0">
                                <Container className="text-center">
                                    <Row>
                                        <Col md={{ size: 4, offset: 4 }}>
                                            <Button
                                                color="secondary"
                                                outline
                                                onClick={() => {
                                                    window.history.back();
                                                    callNativeAppRemoveLastRoute(
                                                        'diet'
                                                    );
                                                }}
                                                type="button"
                                            >
                                                {this.props.t('cancel')}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Container>
                            </section>
                        )}
                        {!this.props.mobileAppMode && (
                            <Footer forceReloadLinks={false} />
                        )}
                    </div>
                </CSSTransition>

                <CSSTransition
                    in={this.props.showModalWithDayReplacements}
                    timeout={500}
                    classNames="view"
                    unmountOnExit
                >
                    <div className="view bg-light-grey">
                        {this.props.renderChangeDay()}

                        {!this.props.mobileAppMode && (
                            <section className="pt-0">
                                <Container className="text-center">
                                    <Row>
                                        <Col md={{ size: 4, offset: 4 }}>
                                            <Button
                                                color="secondary"
                                                outline
                                                onClick={() => {
                                                    window.history.back();
                                                    callNativeAppRemoveLastRoute(
                                                        'diet'
                                                    );
                                                }}
                                                type="button"
                                            >
                                                {this.props.t('cancel')}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Container>
                            </section>
                        )}
                        {!this.props.mobileAppMode && (
                            <Footer forceReloadLinks={false} />
                        )}
                    </div>
                </CSSTransition>
            </Layout>
        );
    }
}
export { DayPlan };
export default withFirebase(withMobileAppMode(withGTM(withLocale(DayPlan))));
