import React, { Component } from 'react';
import { withLocale } from '@dietlabs/components';
import { Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

import ewa from 'assets/images/dr_ewa.jpg';
import appStore from 'assets/icons/app_store_badge.svg';
import googlePlay from 'assets/icons/google_play_badge.svg';

import Layout from 'Layout/Layout';

import { GOOGLE_PLAY_URL, APP_STORE_URL } from 'consts';

class ConfirmPage extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    render() {
        return (
            <Layout page="diet">
                <section className="bg-white">
                    <Container>
                        <Row>
                            <Col xs="12" className="text-center">
                                <img className="img-fluid" src={ewa} alt="" />
                                <h1>{this.props.t('auth/confirm/header')}</h1>
                                <h3>{this.props.t('auth/confirm/content1')}</h3>
                                <p>{this.props.t('auth/confirm/content2')}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" className="text-center mt-5">
                                <p>{this.props.t('auth/confirm/mobileApps')}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                xs="12"
                                className="text-center authConfirm__apStoreLinks"
                            >
                                <a href={GOOGLE_PLAY_URL}>
                                    <img src={googlePlay} alt="Google Play" />
                                </a>

                                <a href={APP_STORE_URL}>
                                    <img src={appStore} alt="App store" />
                                </a>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Layout>
        );
    }
}

export default withLocale(ConfirmPage);
