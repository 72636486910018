import React from 'react';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import { FormGroup } from 'reactstrap';
import InputCheckbox from 'components/FormElements/InputCheckboxControlled2';
import withStep from './StepContext/withStep';

class StepAcceptance extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    static propTypes = {
        t: PropTypes.func.isRequired,
        acceptance: PropTypes.string,
        handleInputChange: PropTypes.func.isRequired,
        changeNextBtnState: PropTypes.func.isRequired,
    };

    static defaultProps = {
        acceptance: undefined,
    };

    componentDidMount() {
        this.props.changeNextBtnState(!this.props.acceptance);
    }

    componentDidUpdate() {
        this.props.changeNextBtnState(!this.props.acceptance);
    }

    render() {
        const { acceptance, handleInputChange } = this.props;

        return (
            <div className="full-screen2">
                <div className="my-auto">
                    <h2 className="acceptance">
                        {this.props.t('diet-settings/acceptance/title')}
                    </h2>
                    <p className="acceptance">
                        {this.props.t('diet-settings/acceptance/msg1')}
                    </p>
                    <p className="acceptance">
                        {this.props.t('diet-settings/acceptance/msg2')}
                    </p>
                    <p className="acceptance">
                        {this.props.t('diet-settings/acceptance/msg3')}
                    </p>
                    <FormGroup>
                        <InputCheckbox
                            label={this.props.t(
                                'diet-settings/acceptance/acceptance'
                            )}
                            id="acceptance"
                            name="acceptance"
                            value={acceptance}
                            handleChange={handleInputChange}
                            checked={Boolean(this.props.acceptance)}
                        />
                    </FormGroup>
                </div>
            </div>
        );
    }
}

export default withStep(withLocale(StepAcceptance));
