import React from 'react';
import { Container, Row, Col, Form } from 'reactstrap';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import UserAgreementToReceiveNewsletterMutation from '@dietlabs/components/src/ApolloConnectors/UserSettings/Notifications/UserAgreementToReceiveNewsletterMutation';
import UserAgreementToNotifyAboutDietCourseMutation from '@dietlabs/components/src/ApolloConnectors/UserSettings/Notifications/UserAgreementToNotifyAboutDietCourseMutation';
import InputSwitch from '../../components/FormElements/InputSwitchControlled2';
import withLoading from '../../Layout/withLoading';
import NotificationsSettingsFormPlaceholder from './NotificationsSettingsFormPlaceholder';

export const NotificationsSettings = props => {
    return (
        <Container className={props.className}>
            <Row>
                <Col xs="12" md={{ size: 6, offset: 3 }}>
                    <div className="white-box">
                        <Form>
                            <h2>{props.t('settings/notifications')}</h2>

                            <InputSwitch
                                label={props.t(
                                    'settings/agreements/diet-course'
                                )}
                                id="dietCourse"
                                name="dietCourse"
                                value={props.agreements.dietCourse}
                                handleChange={event =>
                                    props.onNotifyChange(
                                        UserAgreementToNotifyAboutDietCourseMutation,
                                        'dietCourse',
                                        event.target.checked
                                    )
                                }
                                checked={props.agreements.dietCourse}
                            />
                            <InputSwitch
                                label={props.t(
                                    'settings/agreements/newsletter'
                                )}
                                id="newsletter"
                                name="newsletter"
                                value={props.agreements.newsletter}
                                handleChange={event =>
                                    props.onNotifyChange(
                                        UserAgreementToReceiveNewsletterMutation,
                                        'newsletter',
                                        event.target.checked
                                    )
                                }
                                checked={props.agreements.newsletter}
                            />
                            <p>
                                {props.t('settings/agreements/newsletter-info')}
                            </p>
                            {props.children}
                        </Form>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

NotificationsSettings.propTypes = {
    className: PropTypes.string,
    agreements: PropTypes.shape({
        dietCourse: PropTypes.bool.isRequired,
        newsletter: PropTypes.bool.isRequired,
    }).isRequired,
    onNotifyChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    children: PropTypes.node,
};

NotificationsSettings.defaultProps = {
    className: null,
    children: null,
};

export default withLoading(
    withLocale(NotificationsSettings),
    NotificationsSettingsFormPlaceholder
);
