import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { createDateObject, formatIsoDate } from 'utils/dateFormatter';
import * as types from 'DailyDietPlan/DayPlanTypes';
import DietPlanQuery from './DietPlanQuery';

const DailyPlanConnectorApollo = ({ children }) => {
    const { loading, error, data, refetch } = useQuery(DietPlanQuery, {
        variables: {
            today: formatIsoDate(new Date()),
        },
    });

    const TRIMMED_DAYS = 180;

    const trimDates = availableDays => {
        const trimmed = availableDays.slice(-TRIMMED_DAYS);
        return trimmed;
    };

    const result = {
        loading,
        error,
        refetch,
    };

    if (!loading) {
        result.availableDays = trimDates(data.me.diet.availableDays);
        global.localStorage.setItem(
            'availableDays',
            JSON.stringify(result.availableDays)
        );
        result.hasActiveSubscription =
            undefined !==
            data.me.activeSubscriptions.find(sub => sub.givesAccessToDiet);
        result.accessTo = data.me.diet.accessTo
            ? createDateObject(data.me.diet.accessTo)
            : undefined;
        result.goal = data.me.goal;
        result.isTimeToNagForCurrentMeasurement =
            data.me.isTimeToNagForCurrentMeasurement;
        result.fastingStatistics = data.me.fastingStatistics;
        result.favMeals = data.me.favoriteMeals;
        result.lastAvailableDay =
            data.me.diet.availableDays.length > 0
                ? data.me.diet.availableDays.slice(-1)[0].date
                : '0';
        if (data.me.recipeNotesForNextDay.__typename === types.DAY_PLAN) {
            result.recipeNotesForNextDay = [
                ...data.me.recipeNotesForNextDay.recipeNotesForNextDay,
            ];
        }
        result.fastType = data.me.diet.fastType;
        result.intermittentFasting = data.me.intermittentFasting;
    }

    return children(result);
};

DailyPlanConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
};

export default DailyPlanConnectorApollo;
