import React, { Component } from 'react';
import Loader from 'Layout/Loader';
import Layout from 'Layout/Layout';

class DayPlanIndexPlaceholder extends Component {
    render() {
        return (
            <Layout page="fav-meals">
                <div className="full-height">
                    <Loader />
                </div>
            </Layout>
        );
    }
}

export default DayPlanIndexPlaceholder;
