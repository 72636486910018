import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { formatIsoDate } from 'utils/dateFormatter';
import DayPlanSetMealEatenMutation from './DayPlanSetMealEatenMutation';

const SetMealEatenConnectorApollo = ({ children, date }) => {
    const [setMealEaten, { data, loading, error }] = useMutation(
        DayPlanSetMealEatenMutation
    );

    const optimisticResponse = {
        me: {
            dietSetMealEaten: {
                __typename: 'BasicMutationSuccess',
                code: 200,
            },
        },
    };

    const createSetMealEaten = async ({ setId, mealId, eaten, mealKey }) => {
        await setMealEaten({
            variables: {
                date: formatIsoDate(date),
                setId,
                mealId,
                eaten,
            },
            optimisticResponse,
            update: cache => {
                cache.modify({
                    id: `UserDailyDietPlanEventMeal:{"key":"${mealKey}"}`,
                    fields: {
                        isEaten(existing) {
                            return !existing;
                        },
                    },
                    broadcast: true,
                });
                cache.modify({
                    id: `UserDailyDietMeal:{"key":"${mealKey}"}`,
                    fields: {
                        isEaten(existing) {
                            return !existing;
                        },
                    },
                    broadcast: true,
                });
            },
        });
    };

    return children({
        setMealEaten: {
            set: createSetMealEaten,
            response: data,
            loading,
            error,
        },
    });
};

SetMealEatenConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
    date: PropTypes.instanceOf(Date).isRequired,
};

export default SetMealEatenConnectorApollo;
