import compareVersions from 'compare-versions';
import DayPage from './Day/HydrationDayPage';
import HistoryPage from './History/HydrationHistoryPage';
import { PATH_HYDRATION, PATH_HYDRATION_HISTORY } from './paths';

const mobileApp = JSON.parse(global.localStorage.getItem('mobileApp'));

function getMobileAppTabIdentifier() {
    if (mobileApp && mobileApp.os) {
        if (mobileApp.os.startsWith('ios')) {
            if (compareVersions(mobileApp.version, '4.0.0') >= 0) {
                return 'diet';
            }
            return 'hydration';
        }
        if (mobileApp.os.startsWith('android')) {
            if (Number(mobileApp.version) >= 13820) {
                return 'diet';
            }
            return 'hydration';
        }
    }
    return 'hydration';
}

const routes = [
    {
        path: PATH_HYDRATION,
        component: DayPage,
        private: true,
        exact: true,
        title: 'route-title/hydration-day',
        mobileAppTabIdentifier: getMobileAppTabIdentifier(),
    },
    {
        path: PATH_HYDRATION_HISTORY,
        component: HistoryPage,
        private: true,
        exact: true,
        title: 'route-title/hydration-history',
        mobileAppTabIdentifier: getMobileAppTabIdentifier(),
    },
];

export default routes;
