/* global Android  */

export function callNativeApp(to, targetTab, skipUrl) {
    if (targetTab) {
        try {
            if (Android) {
                Android.callNativeApp(window.location.origin + to, targetTab);
            }
        } catch (err) {
            const target = {
                tab: targetTab,
            };

            if (!skipUrl) {
                target.url = window.location.origin + to;
            }

            if (window.webkit) {
                window.webkit.messageHandlers.buttonClicked.postMessage(target);
            }
        }
    }
}
