import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';
import InputRadio from 'components/FormElements/InputRadioControlled';
import { withLocale } from '@dietlabs/components';
import withFirebase from 'view/FirebaseAnalytics/withFirebase';
import withStep from './StepContext/withStep';

class StepFastDayOfTheWeek extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        fastDayOfTheWeek: PropTypes.string,
        handleInputChange: PropTypes.func.isRequired,
        changeNextBtnState: PropTypes.func.isRequired,
        trackFirebaseEvent: PropTypes.func.isRequired,
    };

    static defaultProps = {
        fastDayOfTheWeek: null,
    };

    componentDidMount() {
        this.props.changeNextBtnState(!this.props.fastDayOfTheWeek);
        this.props.trackFirebaseEvent('screen_view ', {
            firebase_screen: '1_day_fasting',
        });
    }

    componentDidUpdate() {
        this.props.changeNextBtnState(!this.props.fastDayOfTheWeek);
    }

    render() {
        const { fastDayOfTheWeek, handleInputChange } = this.props;

        return (
            <div className="full-screen3">
                <div className="my-auto">
                    <FormGroup tag="fieldset">
                        <Label>
                            {this.props.t(
                                'diet-settings/fast-day-of-the-week/label'
                            )}{' '}
                            <span className="secondary">
                                {this.props.t(
                                    'diet-settings/diet-type/label/stage'
                                )}
                            </span>
                        </Label>
                        <InputRadio
                            label={
                                <strong>
                                    {this.props.t(
                                        'diet-settings/fast-day-of-the-week/mon'
                                    )}
                                </strong>
                            }
                            name="fastDayOfTheWeek"
                            value="mon"
                            handleChange={handleInputChange}
                            checked={fastDayOfTheWeek === 'mon'}
                            data-test="fast-day-monday"
                            className="d-inline-block"
                            rounded
                        />
                        <InputRadio
                            label={
                                <strong>
                                    {this.props.t(
                                        'diet-settings/fast-day-of-the-week/tue'
                                    )}
                                </strong>
                            }
                            name="fastDayOfTheWeek"
                            value="tue"
                            handleChange={handleInputChange}
                            checked={fastDayOfTheWeek === 'tue'}
                            data-test="fast-day-tuesday"
                            className="d-inline-block"
                            rounded
                        />
                        <InputRadio
                            label={
                                <strong>
                                    {this.props.t(
                                        'diet-settings/fast-day-of-the-week/wed'
                                    )}
                                </strong>
                            }
                            name="fastDayOfTheWeek"
                            value="wed"
                            handleChange={handleInputChange}
                            checked={fastDayOfTheWeek === 'wed'}
                            data-test="fast-day-wednesday"
                            className="d-inline-block"
                            rounded
                        />
                        <InputRadio
                            label={
                                <strong>
                                    {this.props.t(
                                        'diet-settings/fast-day-of-the-week/thur'
                                    )}
                                </strong>
                            }
                            name="fastDayOfTheWeek"
                            value="thu"
                            handleChange={handleInputChange}
                            checked={fastDayOfTheWeek === 'thu'}
                            data-test="fast-day-thursday"
                            className="d-inline-block"
                            rounded
                        />
                        <InputRadio
                            label={
                                <strong>
                                    {this.props.t(
                                        'diet-settings/fast-day-of-the-week/fri'
                                    )}
                                </strong>
                            }
                            name="fastDayOfTheWeek"
                            value="fri"
                            handleChange={handleInputChange}
                            checked={fastDayOfTheWeek === 'fri'}
                            data-test="fast-day-friday"
                            className="d-inline-block"
                            rounded
                        />
                        <InputRadio
                            label={
                                <strong>
                                    {this.props.t(
                                        'diet-settings/fast-day-of-the-week/sat'
                                    )}
                                </strong>
                            }
                            name="fastDayOfTheWeek"
                            value="sat"
                            handleChange={handleInputChange}
                            checked={fastDayOfTheWeek === 'sat'}
                            data-test="fast-day-saturday"
                            className="d-inline-block"
                            rounded
                        />
                        <InputRadio
                            label={
                                <strong>
                                    {this.props.t(
                                        'diet-settings/fast-day-of-the-week/sun'
                                    )}
                                </strong>
                            }
                            name="fastDayOfTheWeek"
                            value="sun"
                            handleChange={handleInputChange}
                            checked={fastDayOfTheWeek === 'sun'}
                            data-test="fast-day-sunday"
                            className="d-inline-block"
                            rounded
                        />
                    </FormGroup>

                    <p className="text-center small">
                        {this.props.t(
                            'diet-settings/fast-day-of-the-week/info'
                        )}
                    </p>
                </div>
            </div>
        );
    }
}

export default withFirebase(withStep(withLocale(StepFastDayOfTheWeek)));
