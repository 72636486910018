import React from 'react';
import withLoading from 'Layout/withLoading';
import PropTypes from 'prop-types';
import Layout from 'Layout/Layout';
import { createDateObject } from 'utils/dateFormatter';
import { withLocale } from '@dietlabs/components';
import { Container, Row, Col, Alert } from 'reactstrap';
import HpbaMarkdown from 'Content/utils/HpbaMarkdown';
import ContentPlaceholder from 'Content/Index/ContentPlaceholder';
import BackLink from 'Layout/BackLink';
import withFirebase from 'view/FirebaseAnalytics/withFirebase';
import { PATH_ALL_ARTICLES } from '../paths';

class Article extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        article: PropTypes.shape({
            id: PropTypes.number.isRequired,
            title: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
            lead: PropTypes.shape({
                html: PropTypes.string,
                markdown: PropTypes.string,
            }),
            content: PropTypes.shape({
                html: PropTypes.string,
                markdown: PropTypes.string,
            }),
            photoUrl: PropTypes.string.isRequired,
            summary: PropTypes.shape({
                html: PropTypes.string,
                markdown: PropTypes.string,
            }),
            category: PropTypes.shape({
                name: PropTypes.string,
            }).isRequired,
        }).isRequired,
        accessTo: PropTypes.string.isRequired,
        trackFirebaseEvent: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.props.trackFirebaseEvent('Article_pageview', {
            title: this.props.article.title,
        });
    }

    renderLead = () => {
        if (this.props.article.lead.markdown !== '') {
            return (
                <React.Fragment>
                    <div className="article-lead">
                        <HpbaMarkdown
                            content={this.props.article.lead.markdown}
                        />
                    </div>
                    <hr />
                </React.Fragment>
            );
        }

        return '';
    };

    renderContent = hasAccess => {
        if (this.props.article.content.markdown !== '' && hasAccess) {
            return (
                <React.Fragment>
                    <div className="cms-content">
                        <HpbaMarkdown
                            content={this.props.article.content.markdown}
                        />
                        <div className="clearfix" />
                    </div>
                    <hr />
                </React.Fragment>
            );
        }

        return '';
    };

    renderSummary = hasAccess => {
        if (this.props.article.summary.markdown !== '' && hasAccess) {
            return (
                <React.Fragment>
                    <div className="article-summary">
                        <HpbaMarkdown
                            content={this.props.article.summary.markdown}
                        />
                    </div>
                    <hr />
                </React.Fragment>
            );
        }
        return '';
    };

    render() {
        let hasAccess = false;

        if (this.props.accessTo) {
            const now = new Date();
            const today = new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate()
            );
            const date = createDateObject(this.props.accessTo);
            if (date >= today) {
                hasAccess = true;
            }
        }

        return (
            <Layout page="cms-article">
                <div className="bg-white">
                    <header>
                        <Container>
                            <Row>
                                <Col md={{ size: 8, offset: 2 }}>
                                    <span
                                        className={`category ${this.props.article.category.name.toLowerCase()}`}
                                    >
                                        {this.props.article.category.name}
                                    </span>
                                    <h1>{this.props.article.title}</h1>
                                </Col>
                            </Row>
                        </Container>
                    </header>
                    <section className="pt-0">
                        <Container>
                            <Row>
                                <Col md={{ size: 8, offset: 2 }}>
                                    <div className="article-img">
                                        <img
                                            className="img-fluid"
                                            src={this.props.article.photoUrl}
                                            alt=""
                                        />
                                    </div>
                                    {this.renderLead()}
                                    {this.renderContent(hasAccess)}
                                    {this.renderSummary(hasAccess)}

                                    {!hasAccess ? (
                                        <Alert color="danger">
                                            {this.props.t(
                                                'knowledge/no-access'
                                            )}
                                        </Alert>
                                    ) : (
                                        ''
                                    )}
                                </Col>
                            </Row>
                            <div className="text-center mt-3">
                                <BackLink to={PATH_ALL_ARTICLES}>
                                    {this.props.t('button/go-back')}
                                </BackLink>
                            </div>
                        </Container>
                    </section>
                </div>
            </Layout>
        );
    }
}

export default withLoading(
    withFirebase(withLocale(Article)),
    ContentPlaceholder
);
