import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import DietSettingsValidateQuery from 'DietSettings/GraphQL/DietSettingsValidateQuery';

const DietSettingsValidateDataConnectorApollo = ({ children }) => {
    const [validate] = useLazyQuery(DietSettingsValidateQuery);

    const fetch = async request => {
        const result = await validate({
            variables: {
                request,
            },
            fetchPolicy: 'no-cache',
        });

        return result;
    };

    return children({
        validateData: request => fetch(request),
    });
};

DietSettingsValidateDataConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
};

export default DietSettingsValidateDataConnectorApollo;
