import gql from 'graphql-tag';

const query = gql`
    query SalePaymentGetByToken($token: String!) {
        sale {
            payment {
                getByToken(token: $token) {
                    id
                    description
                    status
                    isCod
                    price {
                        amount
                        currency
                    }
                    products {
                        id
                        amount
                        price {
                            amount
                            currency
                        }
                        saleProductVariant {
                            id
                            saleProduct {
                                name
                                price {
                                    regular {
                                        amount
                                        currency
                                    }
                                    promo {
                                        amount
                                        currency
                                    }
                                }
                                isVirtual
                                containsDiet
                                containsWorkout
                                tags
                                isBestseller
                                longDescription
                                lengthLabel
                                vendor {
                                    identifier
                                }
                            }
                        }
                    }
                    shipping {
                        price {
                            amount
                            currency
                        }
                        saleShipping {
                            id
                        }
                    }
                    shipment {
                        name
                        address
                        postalCode
                        city
                        country {
                            code
                            name
                        }
                        phone
                    }
                    user {
                        id
                        email
                    }
                }
            }
        }
    }
`;

export default query;
