import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import withGTM from 'view/GoogleTagManager/withGTM';

import { withLocale } from '@dietlabs/components';
import PropTypes from 'prop-types';
import { EVENT_CATEGORY_DIET_PLAN } from 'view/GoogleTagManager/EventCategories';

class DayPlanSummary extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        canBeCopiedToDate: PropTypes.bool.isRequired,
        copyDayPlan: PropTypes.func.isRequired,
        alreadyCopied: PropTypes.bool.isRequired,
        print: PropTypes.bool.isRequired,
        trackEvent: PropTypes.func.isRequired,
        children: PropTypes.func.isRequired,
    };

    state = {
        alreadyClicked: this.props.alreadyCopied,
    };

    componentDidUpdate(prevProps) {
        if (prevProps.alreadyCopied !== this.props.alreadyCopied) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ alreadyClicked: this.props.alreadyCopied });
        }
    }

    copyDay = async () => {
        this.setState({ alreadyClicked: true });
        const response = await this.props.copyDayPlan();
        if (
            response.data.me.dietSetCopy.__typename === 'BasicMutationSuccess'
        ) {
            this.props.trackEvent(EVENT_CATEGORY_DIET_PLAN, 'copy to next day');
        } else {
            throw new Error(
                `Copy day plan to next day failed: ${response.data.me.dietSetCopy.__typename}`
            );
        }
    };

    render() {
        const { canBeCopiedToDate, print, t, children } = this.props;

        return (
            <section className="summary">
                <Container className="text-center">
                    {canBeCopiedToDate && !print ? (
                        <Row>
                            <Col xs="12" sm={{ size: 8, offset: 2 }}>
                                <Button
                                    color="secondary"
                                    className={
                                        this.state.alreadyClicked
                                            ? 'done mb-3'
                                            : 'mb-3'
                                    }
                                    disabled={this.state.alreadyClicked}
                                    onClick={() => this.copyDay()}
                                    data-test="copy-button"
                                    style={{ width: '15rem' }}
                                >
                                    {this.state.alreadyClicked
                                        ? t('meal/copy-day-button-done')
                                        : t('meal/copy-day-button')}
                                </Button>
                            </Col>
                        </Row>
                    ) : (
                        ''
                    )}

                    {!print ? children() : ''}
                </Container>
            </section>
        );
    }
}

export { DayPlanSummary };
export default withGTM(withLocale(DayPlanSummary));
