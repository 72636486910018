import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { Container, Button } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import withOnCriticalError from 'view/ErrorBoundary/withOnCriticalError';
import { PATHS } from 'config/paths';
import ShoppingCartConfirmPaymentOnlineContainer from 'view/ShoppingCart/Confirm/Payment/ShoppingCartConfirmPaymentOnlineContainer';
import ShoppingCartConfirmPaymentCodContainer from 'view/ShoppingCart/Confirm/Payment/ShoppingCartConfirmPaymentCodContainer';
import Payment from 'view/Payment/Payment';
import ShoppingCartOrderProductsSummaryComponent from 'components/ShoppingCart/Order/ShoppingCartOrderProductsSummaryComponent';
import ShoppingCartOrderDeliverySummaryComponent from 'components/ShoppingCart/Order/ShoppingCartOrderDeliverySummaryComponent';
import ShoppingCartConfirmPlaceholder from 'components/ShoppingCart/Confirm/ShoppingCartConfirmPlaceholder';
import Layout from 'Layout/Layout';

export class ShoppingCartConfirmComponent extends React.Component {
    state = {
        initialized: false,
    };

    async componentDidMount() {
        try {
            if (!this.props.payment.isInit() && !this.props.payment.isCod()) {
                const clonedPayment = await this.props.clonePayment(
                    this.props.payment.token
                );
                const redirectTo =
                    PATHS.CART.CONFIRM.split(':')[0] + clonedPayment.token;

                this.setState({
                    initialized: true,
                    redirectTo,
                });
            } else {
                this.setState({ initialized: true });
            }
        } catch (error) {
            this.props.onCriticalError(error);
        }
    }

    render() {
        if (this.state.initialized) {
            if (this.state.redirectTo) {
                if (this.state.redirectTo !== global.location.pathname) {
                    return <Redirect to={this.state.redirectTo} />;
                }
                return '';
            }

            const { payment } = this.props;

            const backToCart = payment.isInit() ? (
                <section className="text-center">
                    <Container>
                        <Button
                            data-test="back-to-cart"
                            tag={Link}
                            to={PATHS.CART.INDEX}
                            color="link"
                        >
                            {this.props.t('cart/back-to-cart')}
                        </Button>
                    </Container>
                </section>
            ) : (
                ''
            );

            return (
                <Layout page="cart">
                    <header className="d-none d-md-block">
                        <h1 className="text-center ">
                            {this.props.t('cart/order-summary')}
                        </h1>
                    </header>

                    <ShoppingCartOrderProductsSummaryComponent
                        products={payment.products}
                        productsPrice={payment.productsPrice}
                        deliveryOptionPrice={payment.deliveryOptionPrice}
                        totalPrice={payment.totalPrice}
                    />

                    {payment.requiresDelivery() ? (
                        <ShoppingCartOrderDeliverySummaryComponent
                            delivery={payment.delivery}
                            isAbleToProcess={payment.isInit()}
                        />
                    ) : (
                        ''
                    )}

                    {payment.isCod() ? (
                        <ShoppingCartConfirmPaymentCodContainer
                            isAbleToProcess={payment.isInit()}
                            token={payment.token}
                        />
                    ) : (
                        <ShoppingCartConfirmPaymentOnlineContainer
                            token={payment.token}
                            products={payment.products}
                            user={payment.user}
                        />
                    )}

                    {backToCart}
                </Layout>
            );
        }
        return <ShoppingCartConfirmPlaceholder />;
    }
}

ShoppingCartConfirmComponent.propTypes = {
    payment: PropTypes.instanceOf(Payment).isRequired,
    clonePayment: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    onCriticalError: PropTypes.func.isRequired,
};

export default withOnCriticalError(withLocale(ShoppingCartConfirmComponent));
