import React from 'react';
import PropTypes from 'prop-types';
import { Button, Container, Row, Col } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import Layout from 'Layout/Layout';

export class ShoppingCartIndexPlaceholder extends React.Component {
    render() {
        return (
            <Layout page="cart">
                <header>
                    <h1 className="text-center d-none d-md-block">
                        {this.props.t('cart/title')}
                    </h1>
                </header>
                <section className="cart-product">
                    <Container>
                        <Row>
                            <Col>
                                <div className="placeholder">&nbsp;</div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="cart-summary">
                    <Container>
                        <h2>{this.props.t('cart/summary')}</h2>
                        <Row>
                            <Col>{this.props.t('cart/summary/products')}</Col>
                            <Col className="price">
                                <div className="placeholder">&nbsp;</div>
                            </Col>
                        </Row>
                        <Row className="delivery-option-price">
                            <Col>{this.props.t('cart/summary/delivery')}</Col>
                            <Col className="price">
                                <div className="placeholder">&nbsp;</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <strong>
                                    {this.props.t('cart/summary/total')}
                                </strong>
                            </Col>
                            <Col className="price">
                                <div className="placeholder">&nbsp;</div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="text-center">
                    <Container>
                        <Button color="placeholder">
                            {this.props.t('cart/pay')}
                        </Button>
                    </Container>
                </section>
            </Layout>
        );
    }
}

ShoppingCartIndexPlaceholder.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withLocale(ShoppingCartIndexPlaceholder);
