import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import ContentConnectorApollo from '@dietlabs/components/src/ApolloConnectors/Content/ContentConnectorApollo';
import ContentContainer from './ContentContainer';

class DietFaqPage extends React.Component {
    static propTypes = {
        match: PropTypes.shape({
            params: PropTypes.shape({
                content: PropTypes.string,
            }).isRequired,
        }).isRequired,
    };

    render() {
        return (
            <ContentConnectorApollo
                identifier={this.props.match.params.content}
            >
                {({ ...contentData }) => <ContentContainer {...contentData} />}
            </ContentConnectorApollo>
        );
    }
}

export default withRouter(DietFaqPage);
