import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Layout from 'Layout/Layout';
import withMobileAppMode from 'Layout/withMobileAppMode';
import { callNativeApp } from 'components/Helpers/callNativeApp';
import { Container, Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';

import { PATHS } from 'config/paths';
import { withLocale } from '@dietlabs/components';
import signet from 'assets/images/sygnet_dr_dabrowska.jpg';

class PaymentPending extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        token: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        mobileAppMode: PropTypes.bool.isRequired,
    };

    renderAction() {
        if (this.props.mobileAppMode) {
            return (
                <Button
                    color="primary"
                    className="mb-4"
                    onClick={() => callNativeApp('/', 'buy-diet')}
                >
                    {this.props.t('button/buy-subscribtion')}
                </Button>
            );
        }

        return (
            <Link
                className="mb-4 btn btn-primary"
                to={PATHS.CART.CONFIRM.replace(':token', this.props.token)}
            >
                {this.props.t('payment/pending/make-payment')}
            </Link>
        );
    }

    render() {
        const { t, description: title } = this.props;

        return (
            <Layout page="payment-pending">
                <section className="bg-white">
                    <Container>
                        <Row>
                            <Col xs="12" className="text-center">
                                <img
                                    className="mb-4"
                                    src={signet}
                                    role="presentation"
                                    alt=""
                                />
                                <h1>
                                    {t(`payment/pending/title`, {
                                        products: title,
                                    })}
                                </h1>

                                <p className="text-left">
                                    {t('payment/pending/description')}
                                </p>
                                <p className="text-left">
                                    {t(
                                        'payment/pending/make-payment-description'
                                    )}
                                </p>
                                <p>{this.renderAction()}</p>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Layout>
        );
    }
}

export { PaymentPending };
export default withMobileAppMode(withLocale(PaymentPending));
