export default [
    { id: 'start' },
    {
        id: 'acceptance',
        isVisible: state => !state.diet.hadEver,
    },
    {
        id: 'diet-programme',
        fork: {
            chooseChild: state => state.dietProgramme,
            children: {
                full: [
                    { id: 'fast-preparation-days' },
                    { id: 'fast-type' },
                    { id: 'fast-days' },
                    { id: 'diet-type' },
                    { id: 'fast-day-of-the-week' },
                    { id: 'sex-brithday-height' },
                    { id: 'weight' },
                    { id: 'goal' },
                    { id: 'work-type' },
                    { id: 'activity-level' },
                    { id: 'start-date' },
                    { id: 'finish' },
                ],
                fastPreparation: [
                    { id: 'fast-preparation-days' },
                    { id: 'diet-type' },
                    { id: 'sex-brithday-height' },
                    { id: 'weight' },
                    { id: 'goal' },
                    { id: 'work-type' },
                    { id: 'activity-level' },
                    { id: 'start-date' },
                    { id: 'finish' },
                ],
                fast: [
                    { id: 'fast-type' },
                    { id: 'fast-days' },
                    { id: 'sex-brithday-height' },
                    { id: 'weight' },
                    { id: 'goal' },
                    { id: 'work-type' },
                    { id: 'activity-level' },
                    { id: 'start-date' },
                    { id: 'finish' },
                ],
                fastExit: [
                    { id: 'diet-type' },
                    { id: 'fast-day-of-the-week' },
                    { id: 'sex-brithday-height' },
                    { id: 'weight' },
                    { id: 'goal' },
                    { id: 'work-type' },
                    { id: 'activity-level' },
                    { id: 'start-date' },
                    { id: 'finish' },
                ],
                regular: [
                    { id: 'diet-type' },
                    { id: 'fast-day-of-the-week' },
                    { id: 'sex-brithday-height' },
                    { id: 'weight' },
                    { id: 'goal' },
                    { id: 'work-type' },
                    { id: 'activity-level' },
                    { id: 'start-date' },
                    { id: 'finish' },
                ],
            },
        },
    },
];
