import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, CustomInput } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import withFirebase from 'view/FirebaseAnalytics/withFirebase';
import withStep from './StepContext/withStep';

class StepFastDays extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        fastDays: PropTypes.number.isRequired,
        handleInputChange: PropTypes.func.isRequired,
        changeNextBtnState: PropTypes.func.isRequired,
        trackFirebaseEvent: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.props.changeNextBtnState(!this.props.fastDays);
        this.props.trackFirebaseEvent('screen_view ', {
            firebase_screen: 'diet-fasting',
        });
    }

    componentDidUpdate() {
        this.props.changeNextBtnState(!this.props.fastDays);
    }

    render() {
        return (
            <div className="full-screen3">
                <div className="mb-auto w-100">
                    <Label>
                        {this.props.t('diet-settings/fast-days/title')}
                    </Label>

                    <FormGroup tag="fieldset">
                        <div className="card">
                            <h2 className="text-center">
                                {this.props.t(
                                    'diet-settings/fast-days/fast/title'
                                )}
                            </h2>
                            <h3 className="text-center">
                                <span className="big-number">
                                    {this.props.fastDays}
                                </span>{' '}
                                {this.props.t('diet-settings/fast-days/days')}
                            </h3>

                            <CustomInput
                                type="range"
                                name="fastDays"
                                id="fastDays"
                                min="1"
                                max="14"
                                step="1"
                                style={{
                                    '--value': `${(this.props.fastDays / 14) *
                                        100 -
                                        2}%`,
                                }}
                                value={this.props.fastDays}
                                onChange={this.props.handleInputChange}
                            />

                            {this.props.fastDays < 7 && (
                                <p className="small mt-3 mb-0 text-center">
                                    {this.props.t(
                                        'diet-settings/fast-days/fast/info'
                                    )}
                                </p>
                            )}
                        </div>
                    </FormGroup>
                </div>
            </div>
        );
    }
}

export default withFirebase(withStep(withLocale(StepFastDays)));
