import { combineReducers } from 'redux';

import ArticleReducer from 'view/Article/ArticleReducer';
import { SALE_SOURCES_NAMESPACE } from './view/SaleSources/SaleSourcesNamespace';
import SaleSourcesReducer from './view/SaleSources/SaleSourcesReducer';
import { PROMO_CODE_NAMESPACE } from './view/PromoCode/PromoCodeNamespace';
import PromoCodeReducer from './view/PromoCode/PromoCodeReducer';
import TRACKING_NAMESPACE from './view/Tracking/TrackingNamespace';
import TrackingReducer from './view/Tracking/TrackingReducer';
import ShoppingCartReducer from './view/ShoppingCart/ShoppingCartReducer';
import SaleProductReducer from './view/Sale/Product/SaleProductReducer';

const rootReducer = combineReducers({
    articleModule: ArticleReducer,
    [SALE_SOURCES_NAMESPACE]: SaleSourcesReducer,
    [PROMO_CODE_NAMESPACE]: PromoCodeReducer,
    [TRACKING_NAMESPACE]: TrackingReducer,
    shoppingCart: ShoppingCartReducer,
    saleProducts: SaleProductReducer,
});

export default rootReducer;
