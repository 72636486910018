/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from 'react';
import { Container } from 'reactstrap';

import withMobileAppMode from 'Layout/withMobileAppMode';

import { ReactComponent as LeftArrow } from 'assets/icons/chevron-left.svg';
import { ReactComponent as RightArrow } from 'assets/icons/chevron-right.svg';

const DAY_COUNT = 17;

class TimelinePlaceholder extends PureComponent {
    repeat = (times, func) => {
        const result = [];
        for (let i = 0; i < times; i += 1) {
            result.push(func(i));
        }
        return result;
    };

    render() {
        return (
            <div className="timeline-container hidden">
                <section className="timeline">
                    <Container>
                        <nav>
                            <div className="timeline-arrow left">
                                <span className="SVGInline">
                                    <LeftArrow />
                                </span>
                            </div>
                            <div className="timeline-arrow right">
                                <span className="SVGInline">
                                    <RightArrow />
                                </span>
                            </div>

                            <ul>
                                {this.repeat(DAY_COUNT, key => (
                                    <li className="nav-item" key={key}>
                                        <a
                                            className="nav-link placeholder"
                                            tabIndex="-1"
                                            role="presentation"
                                        >
                                            &nbsp;
                                        </a>
                                    </li>
                                ))
                                /* eslint-enable jsx-a11y/anchor-is-valid */
                                }
                            </ul>
                        </nav>
                    </Container>
                </section>
            </div>
        );
    }
}

export { TimelinePlaceholder };
export default withMobileAppMode(TimelinePlaceholder);
