import React from 'react';

import UserNameEmailConnectorApollo from '@dietlabs/components/src/ApolloConnectors/Contact/Fetch/UserNameEmailConnectorApollo';
import ContentConnectorApollo from '@dietlabs/components/src/ApolloConnectors/Content/ContentConnectorApollo';
import SendEmailConnectorApollo from '@dietlabs/components/src/ApolloConnectors/Contact/SendEmailConnectorApollo';
import ContactContainer from './ContactContainer';

class ContactPage extends React.Component {
    static propTypes = {};

    render() {
        return (
            <ContentConnectorApollo identifier="faq:dietitian">
                {({ ...contentData }) => (
                    <UserNameEmailConnectorApollo>
                        {({ ...userData }) => (
                            <SendEmailConnectorApollo>
                                {({ contactEmail }) => {
                                    return (
                                        <ContactContainer
                                            content={contentData.content}
                                            userData={userData.userData}
                                            loading={
                                                contentData.loading ||
                                                userData.loading
                                            }
                                            contactEmail={contactEmail}
                                        />
                                    );
                                }}
                            </SendEmailConnectorApollo>
                        )}
                    </UserNameEmailConnectorApollo>
                )}
            </ContentConnectorApollo>
        );
    }
}

export default ContactPage;
