import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from './MarkProductContext';

class MarkProductProvider extends Component {
    static propTypes = {
        handleMarkProduct: PropTypes.func.isRequired,
        children: PropTypes.node.isRequired,
    };

    render() {
        const { handleMarkProduct } = this.props;

        const providedValue = {
            handleMarkProduct,
        };
        return <Provider value={providedValue}>{this.props.children}</Provider>;
    }
}

export default MarkProductProvider;
