import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import { withLocale } from '@dietlabs/components';

import MealDetailsConnectorApollo from '../Connector/Apollo/FetchMeal/MealDetailsConnectorApollo';
import ReplaceProductConnectorApollo from '../Connector/Apollo/ReplaceProduct/ReplaceProductConnectorApollo';
import MealDetails from './components/DayPlanMealDetails';
import Dish from './components/DayPlanDish';
import IngredientList from './components/DayPlanIngredientList';
import Ingredient from './components/DayPlanIngredient';
import IngredientReplacementList from './components/DayPlanIngredientReplacementList';
import IngredientReplacement from './components/DayPlanIngredientReplacement';

class MealViewContainer extends Component {
    static propTypes = {
        userDietSetId: PropTypes.number.isRequired,
        mealType: PropTypes.number.isRequired,
        dietMealId: PropTypes.number.isRequired,
        date: PropTypes.instanceOf(Date).isRequired,
        toggleModal: PropTypes.func.isRequired,
        addMealToFavorites: PropTypes.shape().isRequired,
        removeMealFromFavorites: PropTypes.shape().isRequired,
        setId: PropTypes.number.isRequired,
        setMealEaten: PropTypes.shape().isRequired,
        toggleModalWithReplacements: PropTypes.func.isRequired,
        loadDayPlanMealReplacements: PropTypes.shape().isRequired,
        showMacros: PropTypes.bool.isRequired,
    };

    renderIngredientReplacements = (
        replacements,
        productAmountId,
        productId,
        productKey,
        clickAction
    ) =>
        replacements.map(replacement => (
            <ReplaceProductConnectorApollo
                date={this.props.date}
                userDietSetId={this.props.userDietSetId}
                mealType={this.props.mealType}
                dietMealId={this.props.dietMealId}
                key={this.props.dietMealId * Math.random()}
            >
                {({ replaceProduct }) => (
                    <IngredientReplacement
                        key={replacement.productId}
                        replacementId={replacement.productId}
                        name={replacement.name}
                        measurements={replacement.measurements}
                        replaceProduct={replaceProduct}
                        productId={productId}
                        productKey={productKey}
                        productAmountId={productAmountId}
                        replaceProductSuccess={clickAction.closeAndScroll}
                    />
                )}
            </ReplaceProductConnectorApollo>
        ));

    renderIngredientReplacementsList = (
        replacements,
        originalProductId,
        productId,
        productKey,
        original,
        productAmountId,
        clickAction
    ) => (
        <IngredientReplacementList
            shouldRenderOryginalProduct={originalProductId !== productId}
            renderOriginalProduct={() =>
                this.renderIngredientReplacements(
                    [original],
                    productAmountId,
                    productId,
                    productKey,
                    clickAction
                )
            }
            hasReplacements={replacements.length > 0}
        >
            {() =>
                this.renderIngredientReplacements(
                    replacements,
                    productAmountId,
                    productId,
                    productKey,
                    clickAction
                )
            }
        </IngredientReplacementList>
    );

    renderIngredients = ingredients =>
        ingredients.map(ingredient => (
            <Ingredient
                key={ingredient.productId}
                name={ingredient.name}
                measurements={ingredient.measurements}
                saleProductVariant={ingredient.saleProductVariant}
                hasReplacement={ingredient.hasReplacement}
                originalProductId={ingredient.original.productId}
                productId={ingredient.productId}
                productKey={ingredient.key}
                print={false}
            >
                {({ closeAndScroll }) =>
                    this.renderIngredientReplacementsList(
                        ingredient.replacements,
                        ingredient.original.productId,
                        ingredient.productId,
                        ingredient.key,
                        ingredient.original,
                        ingredient.productAmountId,
                        { closeAndScroll }
                    )
                }
            </Ingredient>
        ));

    renderIngredientList = ingredients => {
        const filteredIngredients = ingredients.filter(
            ingredient =>
                !ingredient.category.isSeasoning &&
                !ingredient.category.isOptional
        );

        const onlySeasoningsIngredients = ingredients.filter(
            ingredient => ingredient.category.isSeasoning
        );

        const optionalIngredients = ingredients.filter(
            ingredient => ingredient.category.isOptional
        );

        return (
            <IngredientList
                renderIngredients={() =>
                    this.renderIngredients(filteredIngredients)
                }
                renderOnlySeasoningsIngredients={() =>
                    this.renderIngredients(onlySeasoningsIngredients)
                }
                renderOptionalIngredients={() =>
                    this.renderIngredients(optionalIngredients)
                }
            />
        );
    };

    renderDishes = (dishes, name, preparationVideoUrl) =>
        dishes.map(dish => (
            <Dish
                key={dish.key}
                name={dish.name}
                mealName={name}
                recipe={dish.recipe}
                recipeNote={dish.recipeNote}
                isFirstOccurrence={dish.isFirstOccurrence}
                isLastOccurrence={dish.isLastOccurrence}
                isPortioned={dish.isPortioned}
                portions={dish.portions}
                portionsTotal={dish.portionsTotal}
                data-test="dish-component"
                print={false}
                preparationVideoUrl={preparationVideoUrl}
            >
                {() => this.renderIngredientList(dish.ingredients)}
            </Dish>
        ));

    render() {
        return (
            <>
                <MealDetailsConnectorApollo
                    userDietSetId={this.props.userDietSetId}
                    mealType={this.props.mealType}
                    dietMealId={this.props.dietMealId}
                >
                    {({ ...mealData }) => {
                        if (mealData.loading) {
                            return null;
                        }

                        return (
                            <MealDetails
                                refetch={mealData.refetch}
                                key={mealData.meal.key}
                                mealKey={mealData.meal.key}
                                mealId={mealData.meal.id}
                                preparationTime={mealData.meal.preparationTime}
                                name={mealData.meal.mealType.name}
                                kcal={mealData.meal.kcal}
                                macro={mealData.meal.macro}
                                originalMealId={
                                    mealData.meal.originalMeal
                                        ? mealData.meal.originalMeal.id
                                        : null
                                }
                                preparationVideoUrl={
                                    mealData.meal.preparationVideoUrl
                                }
                                preparationImageUrl={
                                    mealData.meal.preparationImageUrl
                                }
                                publicName={mealData.meal.name}
                                canBeAddedToFavorites={
                                    mealData.meal.canBeAddedToFavorites
                                }
                                isFavorite={mealData.meal.isFavorite}
                                isEaten={mealData.meal.isEaten}
                                date={this.props.date}
                                toggleModal={this.props.toggleModal}
                                loadDayPlanMealReplacements={
                                    this.props.loadDayPlanMealReplacements
                                }
                                toggleModalWithReplacements={replacements =>
                                    this.props.toggleModalWithReplacements(
                                        replacements,
                                        mealData.meal
                                    )
                                }
                                setMealEaten={this.props.setMealEaten}
                                setId={this.props.setId}
                                addMealToFavorites={
                                    this.props.addMealToFavorites
                                }
                                removeMealFromFavorites={
                                    this.props.removeMealFromFavorites
                                }
                                mealType={mealData.meal.mealType.type}
                                showMacros={this.props.showMacros}
                            >
                                {() =>
                                    this.renderDishes(
                                        mealData.meal.dishes,
                                        mealData.meal.name,
                                        mealData.meal.preparationVideoUrl
                                    )
                                }
                            </MealDetails>
                        );
                    }}
                </MealDetailsConnectorApollo>
            </>
        );
    }
}

export { MealViewContainer };
export default withRouter(withLocale(MealViewContainer));
