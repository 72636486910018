import React from 'react';

import ContentConnectorApollo from '@dietlabs/components/src/ApolloConnectors/Content/ContentConnectorApollo';
import TermsOfServiceContainer from './TermsOfServiceContainer';

class TermsOfServicePage extends React.Component {
    static propTypes = {};

    render() {
        return (
            <ContentConnectorApollo identifier="terms-of-service-android">
                {({ ...contentData }) => (
                    <ContentConnectorApollo identifier="terms-of-service-ios">
                        {({ ...contentData2 }) => (
                            <ContentConnectorApollo identifier="terms-of-service-web">
                                {({ ...contentData3 }) => (
                                    <TermsOfServiceContainer
                                        loading={
                                            contentData.loading ||
                                            contentData2.loading ||
                                            contentData3.loading
                                        }
                                        content={contentData.content}
                                        content2={contentData2.content}
                                        content3={contentData3.content}
                                    />
                                )}
                            </ContentConnectorApollo>
                        )}
                    </ContentConnectorApollo>
                )}
            </ContentConnectorApollo>
        );
    }
}

export default TermsOfServicePage;
