import gql from 'graphql-tag';

const query = gql`
    query ShoppingList($period: DatePeriodInputType) {
        me {
            id
            shoppingList(period: $period) {
                period {
                    start
                    end
                }
                category {
                    key
                    categoryId
                    name
                    product {
                        key
                        productId
                        name
                        isBought
                        friscoProductId
                        friscoProductMeasure
                        friscoProductUnit
                        measurements {
                            amount
                            unit
                            text
                        }
                    }
                }
            }
            diet {
                id
                availableDays {
                    date
                }
            }
        }
    }
`;

export default query;
