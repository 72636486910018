/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Container, Button } from 'reactstrap';
import scrollToElement from 'components/Helpers/scrollToElement';
import smoothscroll from 'smoothscroll-polyfill';
import PropTypes from 'prop-types';
import withMobileAppMode from 'Layout/withMobileAppMode';
import { withLocale } from '@dietlabs/components';
import Menu from 'Layout/Menu';

smoothscroll.polyfill();

class DayPlanMealNavigation extends Component {
    static propTypes = {
        activeMeal: PropTypes.number,
        changeMeal: PropTypes.func.isRequired,
        mobileAppMode: PropTypes.bool.isRequired,
        t: PropTypes.func.isRequired,
        showMacros: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        activeMeal: undefined,
    };

    listRef = React.createRef();

    activeItemRef = React.createRef();

    componentDidMount() {
        this.scrollToActive();
    }

    scrollToActive() {
        const activeItem = this.activeItemRef.current;
        if (!activeItem) {
            return;
        }
        const list = this.listRef.current;

        scrollToElement(activeItem, {
            parent: list,
            align: 'center',
        });
    }

    handleMealNavClick(event, mealId) {
        event.preventDefault();
        this.props.changeMeal(mealId);
    }

    formatDate = (date, isShort) => {
        const { t } = this.props;

        const weekday = date.getDay();
        let dayName = t(`meal/weekday${weekday}`);

        if (isShort) {
            dayName = t(`meal/weekday${weekday}-short`);
        }

        return `${dayName}`;
    };

    render() {
        return (
            <React.Fragment>
                {!this.props.mobileAppMode && (
                    <React.Fragment>
                        <Menu forceReloadLinks={false} />
                        <div className="top-bar border-bottom">
                            <Container className="py-3 px-3 px-md-0 d-flex align-items-center">
                                <Button
                                    color="secondary"
                                    size="sm"
                                    onClick={() => window.history.back()}
                                    className="mr-2 outline with-arrow-prev"
                                    style={{ minWidth: '0px' }}
                                    outline
                                >
                                    <span className="d-none d-lg-inline">
                                        {this.props.t('back')}
                                    </span>
                                </Button>
                                <h1 className="text-center w-100">
                                    <span className="d-none d-lg-inline">
                                        {this.formatDate(
                                            this.props.date,
                                            false
                                        )}
                                    </span>
                                    <span className="d-lg-none">
                                        {this.formatDate(this.props.date, true)}
                                    </span>
                                    {this.props.showMacros && (
                                        <>, {this.props.kcal} kcal</>
                                    )}
                                </h1>
                                <div className="clearfix" />
                            </Container>
                        </div>
                    </React.Fragment>
                )}
                <section className="meal-navigation">
                    <Container>
                        <ul ref={this.listRef}>
                            {this.props.events.map(event => {
                                if (
                                    event.__typename ===
                                    'UserDailyDietPlanEventMeal'
                                ) {
                                    return (
                                        <li
                                            className="nav-item"
                                            ref={
                                                this.props.activeMeal ===
                                                event.id
                                                    ? this.activeItemRef
                                                    : ''
                                            }
                                            key={event.key}
                                        >
                                            <a
                                                href=" "
                                                className={
                                                    this.props.activeMeal ===
                                                    event.id
                                                        ? 'nav-link active'
                                                        : 'nav-link'
                                                }
                                                onClick={e =>
                                                    this.handleMealNavClick(
                                                        e,
                                                        event.id
                                                    )
                                                }
                                            >
                                                {event.mealType.name}
                                            </a>
                                        </li>
                                    );
                                }
                                return '';
                            })}
                        </ul>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default withMobileAppMode(withLocale(DayPlanMealNavigation));
