import React from 'react';
import PropTypes from 'prop-types';
import {
    FormGroup,
    Label,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import InputRadio from 'components/FormElements/InputRadioControlled';
import { withLocale } from '@dietlabs/components';
import withFirebase from 'view/FirebaseAnalytics/withFirebase';
import withStep from './StepContext/withStep';

class StepDietProgramme extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        dietProgramme: PropTypes.string,
        handleInputChange: PropTypes.func.isRequired,
        changeNextBtnState: PropTypes.func.isRequired,
        trackFirebaseEvent: PropTypes.func.isRequired,
    };

    static defaultProps = {
        dietProgramme: null,
    };

    state = {
        showModal: false,
    };

    componentDidMount() {
        this.props.changeNextBtnState(!this.props.dietProgramme);
        this.props.trackFirebaseEvent('screen_view ', {
            firebase_screen: 'selected_diet',
        });
    }

    componentDidUpdate() {
        this.props.changeNextBtnState(!this.props.dietProgramme);
    }

    toggle = () => {
        this.setState(
            prevState => ({
                showModal: !prevState.showModal,
            }),
            () => {
                if (this.state.showModal) {
                    this.props.trackFirebaseEvent('screen_view ', {
                        firebase_screen: 'selected_diet_info',
                    });
                }
            }
        );
    };

    render() {
        const { dietProgramme, handleInputChange } = this.props;
        return (
            <div className="full-screen3">
                <div className="my-auto">
                    <FormGroup tag="fieldset">
                        <Label>
                            {this.props.t('diet-settings/diet-programme/label')}
                            <Button
                                color="info"
                                onClick={this.toggle}
                                className="ml-2"
                            >
                                i
                            </Button>
                        </Label>

                        <Modal
                            isOpen={this.state.showModal}
                            toggle={this.toggle}
                            centered
                            size="lg"
                        >
                            <ModalHeader toggle={this.toggle}>
                                Zakres diety
                            </ModalHeader>
                            <ModalBody>
                                <h3>Pełny program żywieniowy</h3>
                                <p>
                                    W ramach programu pomożemy Ci przygotować
                                    się do postu, przejść przez post oraz z
                                    niego wyjść, dodatkowo zbudujesz zdrowe
                                    nawyki żywieniowe na dłużej.
                                </p>

                                <h3>Przygotowanie do postu</h3>
                                <p>
                                    Pozwoli odżywić Twój organizm i przygotować
                                    go do przeprowadzenia postu bez użycia
                                    aplikacji.
                                </p>

                                <h3>Post</h3>
                                <p>
                                    Program idealny dla osób, które samodzielnie
                                    przygotowały się do postu. Pomożemy Ci
                                    oczyścić organizm, zgubić Kilogramy i
                                    poprawić samopoczucie.
                                </p>

                                <h3>Wychodzenie z postu</h3>
                                <p>
                                    Post zakończony? Pora rozpocząć stopniowe
                                    zwiększanie kaloryczności Twojego jadłospisu
                                    i wprowadzać produkty eliminowane na poście.
                                </p>

                                <h3>Zdrowe odżywianie</h3>
                                <p>
                                    Czas zmienić nawyki na stałe. Wzbogać
                                    jadłospis o ryby i owoce morza, świeże
                                    warzywa i tłuszcze roślinne lub wyklucz
                                    najpopularniejsze alergeny: jaja, nabiał
                                    oraz gluten.
                                </p>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onClick={this.toggle}>
                                    OK
                                </Button>
                            </ModalFooter>
                        </Modal>

                        <InputRadio
                            label={[
                                <strong key="full" className="d-block">
                                    {this.props.t(
                                        'diet-settings/diet-programme/full/title'
                                    )}
                                </strong>,
                                this.props.t(
                                    'diet-settings/diet-programme/full/description'
                                ),
                            ]}
                            name="dietProgramme"
                            value="full"
                            handleChange={handleInputChange}
                            checked={dietProgramme === 'full'}
                            data-test="diet-programme-full"
                        />

                        <InputRadio
                            label={[
                                <strong
                                    key="onlyFastPreparation"
                                    className="d-block"
                                >
                                    {this.props.t(
                                        'diet-settings/diet-programme/fast-preparation/title'
                                    )}
                                </strong>,
                                this.props.t(
                                    'diet-settings/diet-programme/fast-preparation/description'
                                ),
                            ]}
                            name="dietProgramme"
                            value="fastPreparation"
                            handleChange={handleInputChange}
                            checked={dietProgramme === 'fastPreparation'}
                            data-test="diet-programme-fast-preparation"
                        />

                        <InputRadio
                            label={[
                                <strong key="onlyFast" className="d-block">
                                    {this.props.t(
                                        'diet-settings/diet-programme/fast/title'
                                    )}
                                </strong>,
                                this.props.t(
                                    'diet-settings/diet-programme/fast/description'
                                ),
                            ]}
                            name="dietProgramme"
                            value="fast"
                            handleChange={handleInputChange}
                            checked={dietProgramme === 'fast'}
                            data-test="diet-programme-fast"
                        />

                        <InputRadio
                            label={[
                                <strong key="onlyFast" className="d-block">
                                    {this.props.t(
                                        'diet-settings/diet-programme/fast-exit/title'
                                    )}
                                </strong>,
                                this.props.t(
                                    'diet-settings/diet-programme/fast-exit/description'
                                ),
                            ]}
                            name="dietProgramme"
                            value="fastExit"
                            handleChange={handleInputChange}
                            checked={dietProgramme === 'fastExit'}
                            data-test="diet-programme-fast-exit"
                        />

                        <InputRadio
                            label={[
                                <strong key="healthyEating" className="d-block">
                                    {this.props.t(
                                        'diet-settings/diet-programme/healthy-eating/title'
                                    )}
                                </strong>,
                                this.props.t(
                                    'diet-settings/diet-programme/healthy-eating/description'
                                ),
                            ]}
                            name="dietProgramme"
                            value="regular"
                            handleChange={handleInputChange}
                            checked={dietProgramme === 'regular'}
                            data-test="diet-programme-regular"
                        />
                    </FormGroup>
                </div>
            </div>
        );
    }
}

export default withFirebase(withStep(withLocale(StepDietProgramme)));
