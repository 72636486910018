import PropTypes from 'prop-types';
import { useMutation, useApolloClient } from '@apollo/client';
import { UNIT_TYPES } from 'consts';
import { getUnitForType } from 'utils/unit';
import { formatIsoDate } from 'utils/dateFormatter';
import { subDays } from 'utils/date';
import {
    MEASUREMENT_TYPES,
    PERIOD_END,
    PERIOD_LENGTH,
} from 'Measurement/constants';
import { withRouter } from 'react-router';
import ValidationErrors from 'view/Validation/ValidationErrors';
import MeasurementGroupSaveMutation from './MeasurementGroupSaveMutation';
import CurrentMeasurementsQuery from '../../CurrentMeasurements/CurrentMeasurementsQuery';
import MeasurementHistoryQuery from '../../MeasurementHistory/MeasurementHistoryQuery';
import MeasurementFetchByDateQuery from '../MeasurementFetchByDate/MeasurementFetchByDateQuery';

const MeasurementSaveByDateConnectorApollo = ({ children, match }) => {
    const client = useApolloClient();
    const [saveMeasurements, { loading }] = useMutation(
        MeasurementGroupSaveMutation
    );

    const createSaveMeasurements = async formData => {
        const serializedState = client.cache.extract();
        const userId = Object.values(serializedState).filter(
            item => item.__typename === 'Me'
        )[0].id;

        const request = {
            date: formData.date,
        };

        if (formData.weight != null) {
            request.weight = {
                value: Number(formData.weight),
                unit: getUnitForType(
                    UNIT_TYPES.BODY_WEIGHT,
                    formData.systemOfMeasures
                ),
            };
        }

        if (formData.waist != null) {
            request.waist = {
                value: Number(formData.waist),
                unit: getUnitForType(
                    UNIT_TYPES.LENGTH_BODY_MEASUREMENT,
                    formData.systemOfMeasures
                ),
            };
        }

        if (formData.hip != null) {
            request.hip = {
                value: Number(formData.hip),
                unit: getUnitForType(
                    UNIT_TYPES.LENGTH_BODY_MEASUREMENT,
                    formData.systemOfMeasures
                ),
            };
        }

        if (formData.thigh != null) {
            request.thigh = {
                value: Number(formData.thigh),
                unit: getUnitForType(
                    UNIT_TYPES.LENGTH_BODY_MEASUREMENT,
                    formData.systemOfMeasures
                ),
            };
        }

        if (formData.chest != null) {
            request.chest = {
                value: Number(formData.chest),
                unit: getUnitForType(
                    UNIT_TYPES.LENGTH_BODY_MEASUREMENT,
                    formData.systemOfMeasures
                ),
            };
        }

        if (formData.arm != null) {
            request.arm = {
                value: Number(formData.arm),
                unit: getUnitForType(
                    UNIT_TYPES.LENGTH_BODY_MEASUREMENT,
                    formData.systemOfMeasures
                ),
            };
        }

        const response = await saveMeasurements({
            variables: { request },
            update: cache => {
                cache.modify({
                    id: `Me:${userId}`,
                    fields: {
                        isTimeToNagForCurrentMeasurement() {
                            return false;
                        },
                    },
                });
            },
            refetchQueries: [
                {
                    query: CurrentMeasurementsQuery,
                },
                {
                    query: MeasurementHistoryQuery,
                    variables: {
                        datePeriod: {
                            start: formatIsoDate(
                                subDays(PERIOD_END, PERIOD_LENGTH - 1)
                            ),
                            end: formatIsoDate(PERIOD_END),
                        },
                        [MEASUREMENT_TYPES.WEIGHT]: true,
                    },
                },
                {
                    query: MeasurementFetchByDateQuery,
                    variables: {
                        date: match.params.date
                            ? match.params.date
                            : formatIsoDate(new Date()),
                    },
                },
            ],
            awaitRefetchQueries: true,
        });

        if (
            response.data.me.measurementGroupSave.__typename ===
            'ValidationException'
        ) {
            throw new ValidationErrors(
                response.data.me.measurementGroupSave.messages,
                response.data.me.measurementGroupSave.details
            );
        }

        let goalLost = false;
        let goalReached = false;

        goalLost =
            response.data.me.measurementGroupSave.goal
                .lostBecauseOfLoseWeight ||
            response.data.me.measurementGroupSave.goal.lostBecauseOfPutOnWeight;

        goalReached =
            response.data.me.measurementGroupSave.goal
                .reachedBecauseOfLoseWeight ||
            response.data.me.measurementGroupSave.goal
                .reachedBecauseOfPutOnWeight;

        return {
            goalLost,
            goalReached,
        };
    };

    return children({
        saveMeasurements: createSaveMeasurements,
        saving: loading,
    });
};

MeasurementSaveByDateConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
    date: PropTypes.string.isRequired,
};

export default withRouter(MeasurementSaveByDateConnectorApollo);
