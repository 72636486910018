import React, { Component } from 'react';
import { Collapse } from 'reactstrap';
import MeasurementUnits from 'components/Helpers/MeasurementUnits';
import { withLocale } from '@dietlabs/components';
import PropTypes from 'prop-types';

import changeImg from 'assets/icons/icon-change.svg';
import changeChangedImg from 'assets/icons/icon-change-green.svg';

class DayPlanIngredient extends Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        measurements: PropTypes.arrayOf(PropTypes.object).isRequired,
        saleProductVariant: PropTypes.shape({
            link: PropTypes.string.isRequired,
        }),
        hasReplacement: PropTypes.bool.isRequired,
        originalProductId: PropTypes.number.isRequired,
        productId: PropTypes.number.isRequired,
        t: PropTypes.func.isRequired,
        children: PropTypes.func.isRequired,
        print: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        saleProductVariant: null,
    };

    state = {
        collapse: false,
    };

    closeAndScroll() {
        this.setState({ collapse: false });
    }

    handleClick() {
        this.setState(prevState => ({
            collapse: !prevState.collapse,
        }));
    }

    render() {
        return (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <li onClick={() => this.handleClick()} data-test="ingredient">
                <MeasurementUnits measurements={this.props.measurements} />
                {this.props.name}
                <span className="stay-together">
                    {this.props.hasReplacement ? (
                        <button
                            type="button"
                            className={
                                this.state.collapse
                                    ? 'btn-change animated rotateLeft'
                                    : 'btn-change animated rotateRight'
                            }
                            data-test="exchange-button"
                        >
                            <img
                                className="img-fluid"
                                src={
                                    this.props.originalProductId !==
                                    this.props.productId
                                        ? changeChangedImg
                                        : changeImg
                                }
                                alt={this.props.t('meal/exchange-ingredient')}
                            />
                        </button>
                    ) : (
                        ''
                    )}
                </span>
                <div className="clearfix" />
                {this.props.saleProductVariant && !this.props.print ? (
                    <React.Fragment>
                        <span
                            className="foods-logo"
                            data-test="sale-product-variant-logo"
                        >
                            foods <span className="by-ann">by Ann</span>
                        </span>
                        <a
                            className="btn btn-link btn-auto-width float-right p-0"
                            href={this.props.saleProductVariant.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            data-test="sale-product-variant-button"
                        >
                            {this.props.t('button/buy')}
                        </a>
                        <div className="clearfix" />
                    </React.Fragment>
                ) : (
                    ''
                )}

                <Collapse isOpen={this.state.collapse}>
                    {this.props.hasReplacement
                        ? this.props.children({
                              closeAndScroll: () => this.closeAndScroll(),
                          })
                        : ''}
                </Collapse>
            </li>
        );
    }
}

export { DayPlanIngredient };
export default withLocale(DayPlanIngredient);
