import React from 'react';
import PropTypes from 'prop-types';

class SectionWithoutSpace extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        align: PropTypes.oneOf(['left', 'center', 'right']),
    };

    static defaultProps = {
        align: 'left',
    };

    render() {
        const { children, align } = this.props;
        return <section className={`text-${align} py-0`}>{children}</section>;
    }
}

export default SectionWithoutSpace;
