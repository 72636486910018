/* global Android  */

export function callNativeSharing(url) {
    try {
        if (Android) {
            Android.callSharingActivity(url);
        }
    } catch (err) {
        const target = {
            url,
        };

        if (window.webkit) {
            window.webkit.messageHandlers.shareImages.postMessage(target);
        }
    }
}
