import FavMealsIndexPage from './FavMealsIndexPage';

const PATH_FAVMEALS_INDEX = '/favourite-meals';

const routes = [
    {
        path: PATH_FAVMEALS_INDEX,
        component: FavMealsIndexPage,
        private: true,
        exact: true,
        title: 'route-title/fav-meals',
        mobileAppTabIdentifier: 'diet',
    },
];

export { routes, PATH_FAVMEALS_INDEX };
