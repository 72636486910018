import React, { Component } from 'react';
import { Container, Row, Col, Nav, NavItem } from 'reactstrap';
import PropTypes from 'prop-types';
import appStoreIcon from 'assets/icons/app_store_badge.svg';
import googlePlayIcon from 'assets/icons/google_play_badge.svg';
import blik from 'assets/icons/blik.svg';
import payu from 'assets/icons/payu.svg';
import paypal from 'assets/icons/paypal.svg';
import visa from 'assets/icons/visa.svg';
import mastercard from 'assets/icons/mastercard.svg';
import maestro from 'assets/icons/maestro.svg';
import activerse from 'assets/icons/activerse_logo_horizontal.svg';
import { withLocale } from '@dietlabs/components';
import { Link } from 'react-router-dom';
import { GOOGLE_PLAY_URL, APP_STORE_URL } from 'consts';
import { PATH_DIET_FAQ } from 'Content/routes';
import { PATH_CONTACT } from 'Contact/routes';

class Footer extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        forceReloadLinks: PropTypes.bool,
    };

    static defaultProps = {
        forceReloadLinks: false,
    };

    renderNavLink(href, text) {
        if (this.props.forceReloadLinks) {
            return (
                <a className="nav-link" href={href}>
                    {text}
                </a>
            );
        }

        return (
            <Link className="nav-link" to={href}>
                {text}
            </Link>
        );
    }

    render() {
        return (
            <footer className="d-print-none">
                <Container>
                    <Row>
                        <Col xs="12" md="4">
                            <h1>Aplikacja dr Ewy Dąbrowskiej</h1>
                            <a
                                href={GOOGLE_PLAY_URL}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src={googlePlayIcon} alt="Google Play" />
                            </a>
                            <a
                                href={APP_STORE_URL}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src={appStoreIcon} alt="App Store" />
                            </a>

                            <a
                                href="https://www.facebook.com/groups/161236554372996"
                                className="nav-link mt-4"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Oficjalna grupa wsparcia na FB
                            </a>
                        </Col>
                        <Col xs="12" md="4">
                            <Nav vertical>
                                <NavItem>
                                    {this.renderNavLink(
                                        '/how-to',
                                        this.props.t('footer/service-access')
                                    )}
                                </NavItem>
                                <NavItem>
                                    {this.renderNavLink(
                                        '/terms-of-service',
                                        this.props.t('footer/tos')
                                    )}
                                </NavItem>
                                <NavItem>
                                    {this.renderNavLink(
                                        '/privacy-policy',
                                        this.props.t('footer/privacy-policy')
                                    )}
                                </NavItem>
                                <NavItem>
                                    {this.renderNavLink(
                                        PATH_DIET_FAQ,
                                        this.props.t('footer/faq')
                                    )}
                                </NavItem>
                                <NavItem>
                                    <a
                                        href="https://ewadabrowska.pl/"
                                        className="nav-link"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Strona dr Ewy Dąbrowskiej
                                    </a>
                                </NavItem>
                                <NavItem>
                                    {this.renderNavLink(
                                        PATH_CONTACT,
                                        this.props.t('footer/contact')
                                    )}
                                </NavItem>
                            </Nav>
                        </Col>
                        <Col xs="12" md="4">
                            <p>{this.props.t('footer/secure-payments')}</p>
                            <img src={blik} alt="Blik" />
                            <img src={payu} alt="PayU" />
                            <img src={paypal} alt="PayPal" />
                            <br />
                            <img src={visa} alt="visa" />
                            <img src={mastercard} alt="mastercard" />
                            <img src={maestro} alt="maestro" />
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col md="6">
                            <p>
                                proudly created by{' '}
                                <a
                                    className="d-inline-block"
                                    href="https://activerse.app/"
                                >
                                    <img
                                        src={activerse}
                                        className="mx-0 my-0 ml-2"
                                        alt="activerse.app"
                                    />
                                </a>
                            </p>
                        </Col>
                        <Col md="6" className="text-left text-md-right">
                            <p>
                                {' '}
                                Copyright ©2019-{new Date().getFullYear()}{' '}
                                Aplikacja dr Ewy Dąbrowskiej
                            </p>
                        </Col>
                    </Row>
                </Container>
            </footer>
        );
    }
}

export default withLocale(Footer);
