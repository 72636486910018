export const PATHS = {
    EXTERNAL: {
        HOME: 'https://ewadabrowska.com.pl',
    },
    DEFAULT_PAGE_AFTER_LOGGED_IN: '/day-plan',
    DIET_INTRO: '/day-plan/intro',
    CURRENT_WEIGHT_NEEDED: '/current-weight-needed',
    GOAL_REACHED: '/goal-reached',
    GOAL_LOST: '/goal-lost',
    MAKE_DECISION: '/make-decision',
    DAY_PLAN_PRINT:
        '/print/:start([0-9]{4}-[0-9]{2}-[0-9]{2})/:end([0-9]{4}-[0-9]{2}-[0-9]{2})',
    SHOPPING_LIST: '/shopping-list',
    DIET_SETTINGS: '/diet-settings',
    ERROR_404: '/error/404',
    ERROR_APPLICATION: '/error/application',
    ERROR_NETWORK: '/error/network',
    AUTH: {
        CONFIRMED: '/auth/confirm',
        LOGIN: '/auth/login',
        LOGOUT: '/auth/logout',
        LOGIN_BY_TOKEN: '/auth/login/by-token/:token([a-zA-Z0-9]+)',
        REMIND_PASSWORD: '/auth/remind-password',
        RESET_PASSWORD: '/auth/reset-password/:token([a-zA-Z0-9]+)',
        SET_PASSWORD: '/auth/set-password',
        FACEBOOK_LOGIN: '/auth/login/via-facebook',
        GOOGLE_LOGIN: '/auth/login/via-google',
        APPLE_LOGIN: '/auth/login/via-apple',
        APPLE_REGISTER: '/auth/register/via-apple',
        FACEBOOK_REGISTER: '/auth/register/via-facebook',
        GOOGLE_REGISTER: '/auth/register/via-google',
        FACEBOOK_ACTIVATE: '/auth/activate/via-facebook',
        GOOGLE_ACTIVATE: '/auth/activate/via-google',
        APPLE_ACTIVATE: '/auth/activate/via-apple',
        ACTIVATE: '/auth/activate/:token([a-zA-Z0-9]+)',
    },
    BUY: '/buy',
    PROMO_CODE: {
        EN: '/coupon',
        PL: '/kupon',
    },
    BLOCK_COOKIES: '/block-cookies',
    NEWSLETTER: {
        UNSUBSCRIBE: '/unsubscribe',
    },
    CART: {
        INDEX: '/cart',
        ADD: '/cart/add/:productId([0-9]+)/:source?',
        UPSELL: '/cart/upsell',
        CONFIRM: '/cart/confirm/:token',
        THANK_YOU: '/cart/thank-you/:token',
        BANK_TRANSFER: '/cart/bank-transfer/:token',
    },
    REDIRECT_HELPER: '/redirect-to',
    COUPON: {
        INDEX: '/aktywacja',
        ACTIVATE: '/coupon/access-code',
    },
};
