import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';
import InputRadio from 'components/FormElements/InputRadioControlled';
import { withLocale } from '@dietlabs/components';
import withFirebase from 'view/FirebaseAnalytics/withFirebase';
import withStep from './StepContext/withStep';

class StepDietType extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        dietType: PropTypes.string,
        handleInputChange: PropTypes.func.isRequired,
        changeNextBtnState: PropTypes.func.isRequired,
        trackFirebaseEvent: PropTypes.func.isRequired,
    };

    static defaultProps = {
        dietType: null,
    };

    componentDidMount() {
        this.props.changeNextBtnState(!this.props.dietType);
        this.props.trackFirebaseEvent('screen_view ', {
            firebase_screen: 'diet_type ',
        });
    }

    componentDidUpdate() {
        this.props.changeNextBtnState(!this.props.dietType);
    }

    render() {
        const { dietType, handleInputChange } = this.props;

        return (
            <div className="full-screen3">
                <div className="my-auto">
                    <FormGroup tag="fieldset">
                        <Label>
                            {this.props.t('diet-settings/diet-type/label')}
                        </Label>
                        <InputRadio
                            label={[
                                <strong className="d-block">
                                    {this.props.t(
                                        'diet-settings/diet-type/mediterranean-diet/title'
                                    )}
                                </strong>,
                                this.props.t(
                                    'diet-settings/diet-type/mediterranean-diet/description'
                                ),
                            ]}
                            name="dietType"
                            value="mediterranean"
                            handleChange={handleInputChange}
                            checked={dietType === 'mediterranean'}
                            data-test="diet-type-mediterranean"
                        />

                        <InputRadio
                            label={[
                                <strong className="d-block">
                                    {this.props.t(
                                        'diet-settings/diet-type/elimination-diet/title'
                                    )}
                                </strong>,
                                this.props.t(
                                    'diet-settings/diet-type/elimination-diet/description'
                                ),
                            ]}
                            name="dietType"
                            value="elimination"
                            handleChange={handleInputChange}
                            checked={dietType === 'elimination'}
                            data-test="diet-type-elimination"
                        />

                        <InputRadio
                            label={[
                                <strong className="d-block">
                                    {this.props.t(
                                        'diet-settings/diet-type/vegetarian-diet/title'
                                    )}
                                </strong>,
                                this.props.t(
                                    'diet-settings/diet-type/vegetarian-diet/description'
                                ),
                            ]}
                            name="dietType"
                            value="vegetarian"
                            handleChange={handleInputChange}
                            checked={dietType === 'vegetarian'}
                            data-test="diet-type-vegetarian"
                        />
                    </FormGroup>
                </div>
            </div>
        );
    }
}

export default withFirebase(withStep(withLocale(StepDietType)));
