import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import withLoading from '../../Layout/withLoading';
import UserEmailFormPlaceholder from './UserEmailFormPlaceholder';

class UserEmailForm extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        email: PropTypes.string.isRequired,
    };

    render() {
        return (
            <Container>
                <Row>
                    <Col xs="12" md={{ size: 6, offset: 3 }}>
                        <div className="white-box">
                            <h2>{this.props.t('settings/profile')}</h2>
                            <p className="mb-0">
                                {`${this.props.t('settings/email')}: ${
                                    this.props.email
                                }`}
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default withLoading(withLocale(UserEmailForm), UserEmailFormPlaceholder);
