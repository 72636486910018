import React, { Component } from 'react';
import { Collapse } from 'reactstrap';
import MeasurementUnits from 'components/Helpers/MeasurementUnits';
import { withLocale } from '@dietlabs/components';
import PropTypes from 'prop-types';

import iconChange from 'assets/images/dayplan/change.svg';
import iconChangeDone from 'assets/images/dayplan/change_active.svg';

class DayPlanIngredient extends Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        measurements: PropTypes.arrayOf(PropTypes.object).isRequired,
        saleProductVariant: PropTypes.shape({
            link: PropTypes.string.isRequired,
        }),
        hasReplacement: PropTypes.bool.isRequired,
        originalProductId: PropTypes.number.isRequired,
        productId: PropTypes.number.isRequired,
        t: PropTypes.func.isRequired,
        children: PropTypes.func.isRequired,
    };

    static defaultProps = {
        saleProductVariant: null,
    };

    state = {
        collapse: false,
    };

    closeAndScroll() {
        this.setState({ collapse: false });
    }

    handleClick() {
        this.setState(prevState => ({
            collapse: !prevState.collapse,
        }));
    }

    render() {
        return (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <li onClick={() => this.handleClick()} data-test="ingredient">
                <MeasurementUnits measurements={this.props.measurements} />
                <span className="stay-together">
                    {this.props.hasReplacement ? (
                        <button
                            type="button"
                            className={
                                this.state.collapse
                                    ? 'btn-change animated rotateLeft'
                                    : 'btn-change animated rotateRight'
                            }
                            data-test="exchange-button"
                        >
                            <img
                                className="img-fluid"
                                src={
                                    this.props.originalProductId !==
                                    this.props.productId
                                        ? iconChangeDone
                                        : iconChange
                                }
                                alt={this.props.t('meal/exchange-ingredient')}
                            />
                        </button>
                    ) : (
                        ''
                    )}
                </span>
                {this.props.name}

                <Collapse isOpen={this.state.collapse}>
                    {this.props.hasReplacement
                        ? this.props.children({
                              closeAndScroll: () => this.closeAndScroll(),
                          })
                        : ''}
                </Collapse>

                <div className="clearfix" />
            </li>
        );
    }
}

export { DayPlanIngredient };
export default withLocale(DayPlanIngredient);
