import gql from 'graphql-tag';

export default gql`
    mutation AddMealToFavoritesMutation($mealId: Int!) {
        me {
            addMealToFavorites(mealId: $mealId) {
                __typename
                ... on ValidationException {
                    code
                    messages
                    details {
                        fieldName
                        messages
                    }
                }
                ... on BasicMutationSuccessWithId {
                    id
                }
            }
        }
    }
`;
