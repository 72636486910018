import { useApolloClient } from '@apollo/client';
import PropTypes from 'prop-types';
import MealReaplacementsQuery from './MealReplacementsQuery';

const MealReplacementsConnectorApollo = ({ children }) => {
    const client = useApolloClient();

    const createLoadMealReplacements = async ({
        userDietSetId,
        mealType,
        dietMealId,
        limit,
        prependWithOriginalMeal,
    }) => {
        const result = client.query({
            query: MealReaplacementsQuery,
            variables: {
                userDietSetId,
                mealType,
                dietMealId,
                limit,
                prependWithOriginalMeal,
            },
        });

        return result;
    };

    return children({
        loadDayPlanMealReplacements: {
            load: createLoadMealReplacements,
        },
    });
};

MealReplacementsConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
};

export default MealReplacementsConnectorApollo;
