import gql from 'graphql-tag';

export default gql`
    mutation RemoveMealFromFavoritesMutation($mealId: Int!) {
        me {
            removeMealFromFavoritesByMealId(mealId: $mealId) {
                __typename
                ... on ValidationException {
                    code
                    messages
                    details {
                        fieldName
                        messages
                    }
                }
                ... on BasicMutationSuccess {
                    code
                }
            }
        }
    }
`;
