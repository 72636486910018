import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Label, Row } from 'reactstrap';
import { scrollToTop } from 'components/Helpers/scrollToTop';
import { withLocale } from '@dietlabs/components';
import ExternalProviderContainer from '@dietlabs/components/src/Auth/Login/ByExternalProvider/ExternalProviderContainer';
import { RETURN_URL_LOGIN } from '@dietlabs/components/src/Auth/Login/ByExternalProvider/LoginReturnUrls';
import UserAgreementNotifyConnector from '@dietlabs/components/src/ApolloConnectors/UserSettings/Notifications/UserAgreementNotifyConnector';
import ChangePasswordConnector from '@dietlabs/components/src/ApolloConnectors/UserSettings/ChangePassword/ChangePasswordConnector';
import SettingsConnectorApollo from '@dietlabs/components/src/ApolloConnectors/UserSettings/CurrentSettings/CurrentSettingsConnectorApollo';
import HydrationSettingsConnector from '@dietlabs/components/src/ApolloConnectors/UserSettings/Hydration/HydrationSettingsConnectorApollo';
import ChangeSettingsConnectorApollo from '@dietlabs/components/src/ApolloConnectors/UserSettings/SystemOfMeasurements/ChangeSettingsConnectorApollo';
import Layout from '../Layout/Layout';

import SystemOfMeasurementsForm from './SystemOfMeasurements/SystemOfMeasurementsForm';
import NotificationsSettings from './Notifications/NotificationsSettingsForm';
import ChangePasswordForm from './ChangePassword/ChangePasswordForm';
import Placeholder from './ProductsAndSubscriptions/SettingsProductsAndSubscriptionsPlaceholder';
import SettingsProductsAndSubscriptionsComponent from './ProductsAndSubscriptions/SettingsProductsAndSubscriptionsComponent';
import UserEmailForm from './UserEmail/UserEmailForm';
import HydrationSettings from './Hydration/HydrationSettings';
import RemoveAccountButton from './RemoveAccount/Components/RemoveAccountButton';

class UserSettings extends React.PureComponent {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    componentDidMount() {
        scrollToTop();
    }

    renderActiveSubscriptions = result => {
        if (result.loading) {
            return <Placeholder className="mt-3" />;
        }
        // Filter out undefined values
        const filteredProps = Object.entries(result)
            .filter(([, v]) => v !== undefined)
            .reduce((c, [k, v]) => ({ ...c, [k]: v }), {});
        return (
            <SettingsProductsAndSubscriptionsComponent
                className="mt-3"
                {...filteredProps}
            />
        );
    };

    renderSocialMediaLogin = (
        isAbleToLoginWithGoogle,
        isAbleToLoginWithFacebook,
        isAbleToLoginWithApple
    ) => (
        <Container className="mt-3">
            <Row>
                <Col xs="12" md={{ size: 6, offset: 3 }}>
                    <div className="white-box">
                        <h2>
                            <Label className="not-label">
                                {this.props.t(
                                    'auth/login/social-media-login-header'
                                )}
                            </Label>
                        </h2>
                        <div className="text-center">
                            {isAbleToLoginWithGoogle &&
                            isAbleToLoginWithFacebook &&
                            isAbleToLoginWithApple ? (
                                this.props.t('auth/login/social-media-set-up')
                            ) : (
                                <ExternalProviderContainer
                                    returnUrl={RETURN_URL_LOGIN}
                                    displayGoogleButton={
                                        !isAbleToLoginWithGoogle
                                    }
                                    displayFacebookButton={
                                        !isAbleToLoginWithFacebook
                                    }
                                    displayAppleButton={!isAbleToLoginWithApple}
                                />
                            )}
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );

    render() {
        return (
            <Layout page="settings">
                <header>
                    <h1 className="text-center d-none d-md-block">
                        {this.props.t('settings/title')}
                    </h1>
                </header>
                <section className="pt-0">
                    <SettingsConnectorApollo>
                        {result => (
                            <React.Fragment>
                                <UserEmailForm
                                    email={result.email}
                                    loading={result.loading}
                                />
                                <ChangeSettingsConnectorApollo
                                    rawQueryResult={result.rawData}
                                >
                                    {systemOfMeasures => (
                                        <SystemOfMeasurementsForm
                                            className="mt-3"
                                            setSystemOfMeasures={
                                                systemOfMeasures
                                            }
                                            value={result.systemOfMeasures}
                                            loading={result.loading}
                                        />
                                    )}
                                </ChangeSettingsConnectorApollo>

                                <HydrationSettingsConnector
                                    rawQueryResult={result.rawData}
                                >
                                    {save => (
                                        <HydrationSettings
                                            loading={result.loading}
                                            className="mt-3"
                                            defaultDailyGoal={
                                                result.hydrationSettings
                                                    .defaultDailyGoal
                                            }
                                            hydrationGoal={result.hydrationGoal}
                                            save={save}
                                        />
                                    )}
                                </HydrationSettingsConnector>

                                <UserAgreementNotifyConnector
                                    rawQueryResult={result.rawData}
                                >
                                    {notify => (
                                        <NotificationsSettings
                                            agreements={result.agreements}
                                            onNotifyChange={
                                                notify.changeAgreement
                                            }
                                            loading={result.loading}
                                        />
                                    )}
                                </UserAgreementNotifyConnector>

                                {result.passwordIsSet ? (
                                    <ChangePasswordConnector>
                                        {changePassword => (
                                            <ChangePasswordForm
                                                className="mt-3"
                                                changePassword={
                                                    changePassword.changePassword
                                                }
                                                username={result.email}
                                            />
                                        )}
                                    </ChangePasswordConnector>
                                ) : null}

                                {this.renderActiveSubscriptions(result)}

                                {this.renderSocialMediaLogin(
                                    result.isAbleToLoginWithGoogle,
                                    result.isAbleToLoginWithFacebook,
                                    result.isAbleToLoginWithApple
                                )}
                                <RemoveAccountButton loading={result.loading} />
                            </React.Fragment>
                        )}
                    </SettingsConnectorApollo>
                </section>
            </Layout>
        );
    }
}

export default withLocale(UserSettings);
