import React from 'react';
import withLoading from 'Layout/withLoading';
import PropTypes from 'prop-types';
import Layout from 'Layout/Layout';
import Header from 'Layout/Header';
import Loader from 'Layout/Loader';
import { Link, NavLink } from 'react-router-dom';
import { withLocale } from '@dietlabs/components';
import {
    Container,
    CardDeck,
    Card,
    CardBody,
    Nav,
    NavItem,
    Button,
} from 'reactstrap';
import ContentPlaceholder from 'Content/Index/ContentPlaceholder';
import withFirebase from 'view/FirebaseAnalytics/withFirebase';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { scrollToTop } from 'components/Helpers/scrollToTop';
import {
    PATH_CATEGORY_LECTURES,
    PATH_CATEGORY_DIET,
    PATH_KNOWLEDGE_ARTICLE,
    PATH_CATEGORY_TIPS,
    PATH_CATEGORY_MOTIVATION,
} from '../paths';

import withApolloClient from '../../Apollo/withApolloClient';

function splitArrayIntoChunks(arr, chunkLen) {
    const chunkList = [];
    const chunkCount = Math.ceil(arr.length / chunkLen);
    for (let i = 0; i < chunkCount; i += 1) {
        chunkList.push(arr.splice(0, chunkLen));
    }
    return chunkList;
}

class ArticleList extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        articleList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        trackFirebaseEvent: PropTypes.func.isRequired,
        category: PropTypes.string.isRequired,
        articlePagination: PropTypes.shape({
            pageCount: PropTypes.number,
            articlesCount: PropTypes.number,
            __typename: PropTypes.string,
        }).isRequired,
        client: PropTypes.shape({
            query: PropTypes.func.isRequired,
        }).isRequired,
        loadArticle: PropTypes.func.isRequired,
        loadArticleList: PropTypes.func.isRequired,
        articlesPerPage: PropTypes.number.isRequired,
    };

    state = {
        isLoading: false,
        pageNumber: {
            all: 1,
            lectures: 1,
            diet: 1,
            tips: 1,
            motivation: 1,
        },
        articleList: {
            all: [],
            lectures: [],
            diet: [],
            tips: [],
            motivation: [],
        },
    };

    handleScroll = () => {
        const bottom =
            Math.ceil(window.innerHeight + window.scrollY) >=
            document.documentElement.scrollHeight;

        if (bottom) {
            this.fetchMore();
        }
    };

    componentDidMount() {
        const { category, articleList } = this.props;

        scrollToTop();
        if (this.state.articleList[category].length === 0) {
            this.setState(prevState => ({
                articleList: {
                    ...prevState.articleList,
                    [category]: articleList,
                },
            }));
        }
        this.props.trackFirebaseEvent('ArticleList_pageview');

        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate(prevProps) {
        const { category, articleList } = this.props;

        if (prevProps.category !== category) {
            if (this.state.articleList[category].length === 0) {
                // eslint-disable-next-line react/no-did-update-set-state
                this.setState(prevState => ({
                    articleList: {
                        ...prevState.articleList,
                        [category]: articleList,
                    },
                }));
            }
        }
    }

    loadMore = () => {
        const { category, articlesPerPage, loadArticleList } = this.props;

        this.setState(
            () => ({
                isLoading: true,
            }),
            async () => {
                const articleList = await loadArticleList({
                    variables: {
                        categoryIdentifier:
                            category === 'all' ? null : category,
                        pageNumber: this.state.pageNumber[category] + 1,
                        articlesPerPage,
                    },
                });

                this.setState(prevState => ({
                    isLoading: false,
                    articleList: {
                        ...prevState.articleList,
                        [category]: [
                            ...prevState.articleList[category],
                            ...articleList.data.article,
                        ],
                    },
                    pageNumber: {
                        ...prevState.pageNumber,
                        [category]: prevState.pageNumber[category] + 1,
                    },
                }));
            }
        );
    };

    async fetchMore() {
        const { category, articlesPerPage } = this.props;

        await this.props.loadArticleList({
            variables: {
                categoryIdentifier: category === 'all' ? null : category,
                pageNumber: this.state.pageNumber[category] + 1,
                articlesPerPage,
            },
        });
    }

    renderFilters() {
        return (
            <div className="categoryFilter">
                <Nav>
                    <NavItem>
                        <NavLink
                            exact
                            className="nav-link"
                            to="/knowledge/all"
                            active={this.props.category === 'all'}
                        >
                            {this.props.t('knowledge/all')}
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            exact
                            className="nav-link"
                            to={PATH_CATEGORY_LECTURES}
                            active={this.props.category === 'lectures'}
                        >
                            {this.props.t('knowledge/lectures')}
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            exact
                            className="nav-link"
                            to={PATH_CATEGORY_DIET}
                            active={this.props.category === 'diet'}
                        >
                            {this.props.t('knowledge/diet')}
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            exact
                            className="nav-link"
                            to={PATH_CATEGORY_TIPS}
                            active={this.props.category === 'tips'}
                        >
                            {this.props.t('knowledge/tips')}
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            exact
                            className="nav-link"
                            to={PATH_CATEGORY_MOTIVATION}
                            active={this.props.category === 'motivation'}
                        >
                            {this.props.t('knowledge/motivation')}
                        </NavLink>
                    </NavItem>
                </Nav>
            </div>
        );
    }

    renderArticleList = () => {
        const { category, loadArticle, t } = this.props;

        if (this.state.articleList[category].length > 0) {
            // create copy of article in articlesArray instead of reference to them
            const articlesArray = Object.assign(
                [],
                this.state.articleList[category]
            );
            const articleChunks = splitArrayIntoChunks(articlesArray, 3);

            return (
                <Container>
                    {articleChunks.map((chunk, i) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <CardDeck key={i}>
                            {chunk.map(article => (
                                <Card
                                    key={article.id}
                                    tag={Link}
                                    to={`${
                                        PATH_KNOWLEDGE_ARTICLE.split(':')[0]
                                    }${article.id}-${article.slug}`}
                                    onMouseEnter={() => {
                                        loadArticle({
                                            variables: {
                                                articleId: article.id,
                                            },
                                        });
                                    }}
                                    onTouchStart={() => {
                                        loadArticle({
                                            variables: {
                                                articleId: article.id,
                                            },
                                        });
                                    }}
                                >
                                    <div className="thumbImg">
                                        <LazyLoadImage
                                            className="img-fluid"
                                            effect="blur"
                                            src={article.thumbUrl}
                                            alt={article.title}
                                        />
                                    </div>
                                    <CardBody>
                                        <span
                                            className={`category ${article.category.name.toLowerCase()}`}
                                        >
                                            {article.category.name}
                                        </span>
                                        <h3>{article.title}</h3>
                                        <p>{article.teaser.markdown}</p>
                                        <Link
                                            to={`${
                                                PATH_KNOWLEDGE_ARTICLE.split(
                                                    ':'
                                                )[0]
                                            }${article.id}-${article.slug}`}
                                        >
                                            {t('read-more')}
                                        </Link>
                                    </CardBody>
                                </Card>
                            ))}
                        </CardDeck>
                    ))}
                </Container>
            );
        }

        return (
            <p className="text-center my-5">
                {this.props.t('knowledge/no-results')}
            </p>
        );
    };

    render() {
        const { category, articlePagination, t } = this.props;

        return (
            <Layout page="article-list">
                <Header>{this.props.t('knowledge/title')}</Header>
                {this.renderFilters()}
                {this.renderArticleList()}
                {articlePagination.pageCount >
                this.state.pageNumber[category] ? (
                    <section className="text-center pt-3 pb-4">
                        <Button
                            outline
                            color="primary"
                            className="mb-2"
                            onClick={() => this.loadMore()}
                            onMouseEnter={() => this.fetchMore()}
                            onTouchStart={() => this.fetchMore()}
                        >
                            {t('knowledge/load-more')}
                        </Button>
                    </section>
                ) : (
                    ''
                )}

                {this.state.isLoading ? <Loader /> : null}
            </Layout>
        );
    }
}

export default withLoading(
    withApolloClient(withFirebase(withLocale(ArticleList))),
    ContentPlaceholder
);
