import React from 'react';

import { Consumer } from './StepContext';

const withStep = ChildComponent => props => (
    <Consumer>
        {({
            prevStep,
            nextStep,
            handleInputChange,
            changeNextBtnState,
            preload,
        }) => (
            <ChildComponent
                {...props}
                prevStep={prevStep}
                nextStep={nextStep}
                handleInputChange={handleInputChange}
                changeNextBtnState={changeNextBtnState}
                preload={preload}
            />
        )}
    </Consumer>
);

export default withStep;
