import gql from 'graphql-tag';
import * as types from 'DailyDietPlan/DayPlanTypes';

const query = gql`
    query DailyDietPlan($date: Date!, $canBeCopiedToDate: Date!) {
        me {
            id
            dailyDietPlan(date: $date) {
                __typename
                ... on ${types.UNAUTHORIZED} {
                    code
                }
                ... on ${types.DAY_REDIRECT} {
                    date
                }
                ... on ${types.PAYMENT_PENDING} {
                    token
                    description
                    originatesFromWeb
                }
                ... on ${types.DIET_IN_FUTURE} {
                    date
                }
                ... on ${types.DIET_COMPLETED} {
                    __typename
                    dietId
                    dietEndDate
                }
                ... on ${types.AFTER_LONG_BREAK} {
                    dietId
                }
                ... on ${types.DAY_PLAN} {
                    id
                    key: id
                    setId
                    date
                    canBeCopiedToDate(targetDate: $canBeCopiedToDate)
                    showMacros
                    events {
                        __typename
                        ... on UserDailyDietPlanEventMeal {
                            id
                            mealType{
                                type
                                name
                            }
                            name
                            key
                            kcal
                            preparationVideoUrl
                            preparationImageUrl
                            canBeAddedToFavorites
                            isFavorite
                            isEaten
                            isReplacement
                            macro {
                                protein {
                                    grams
                                    kcal
                                    percentage
                                }
                                animalProtein {
                                    grams
                                    kcal
                                    percentage
                                }
                                fat {
                                    grams
                                    kcal
                                    percentage
                                }
                                carbohydrates {
                                    grams
                                    kcal
                                    percentage
                                }
                            }
                            preparationTime {
                                years
                                months
                                days
                                hours
                                minutes
                                seconds
                            }
                            originalMeal{
                                id
                            }
                        }
                    }
                    dayDiet {
                        id
                        dietId
                        name
                        kcal
                        macro {
                            protein {
                                grams
                                kcal
                                percentage
                            }
                            animalProtein {
                                grams
                                kcal
                                percentage
                            }
                            fat {
                                grams
                                kcal
                                percentage
                            }
                            carbohydrates {
                                grams
                                kcal
                                percentage
                            }
                        }
                        phase {
                            name
                            identifier
                            colour
                        }
                    }
                }
            }
            diet {
                id
                nextDaySet: set(date: $canBeCopiedToDate) {
                    ... on UserDietSet {
                        dietSetId
                    }
                }
                set(date: $date) {
                    ... on UserDietSet {
                        dietSetId
                        userDietSetId
                        holiday {
                            identifier
                            name
                            description
                            icon
                        }
                        categories{
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`;

export default query;
