import { useApolloClient } from '@apollo/client';
import PropTypes from 'prop-types';
import { formatIsoDate } from 'utils/dateFormatter';
import DayReaplacementsQuery from './DayReplacementsQuery';

const DayReplacementsConnectorApollo = ({ children, date }) => {
    const client = useApolloClient();

    const createLoadDayReplacements = async (search = '') => {
        const result = client.query({
            query: DayReaplacementsQuery,
            variables: {
                date: formatIsoDate(date),
                text: search,
            },
        });

        return result;
    };

    return children({
        loadDayPlanDayReplacements: {
            load: createLoadDayReplacements,
        },
    });
};

DayReplacementsConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
    date: PropTypes.instanceOf(Date).isRequired,
};

export default DayReplacementsConnectorApollo;
