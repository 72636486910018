import React from 'react';

import { Consumer } from './MarkProductContext';

const withMarkProduct = ChildComponent => props => (
    <Consumer>
        {({ handleMarkProduct }) => (
            <ChildComponent {...props} handleMarkProduct={handleMarkProduct} />
        )}
    </Consumer>
);

export default withMarkProduct;
