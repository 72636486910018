import PropTypes from 'prop-types';
import { useMutation, useApolloClient } from '@apollo/client';
import GoalChangeMutation from './GoalChangeMutation';

const GoalChangeConnectorApollo = ({ children }) => {
    const client = useApolloClient();
    const [mutate] = useMutation(GoalChangeMutation);

    const createGoalChange = async request => {
        const serializedState = client.cache.extract();
        const userId = Object.values(serializedState).filter(
            item => item.__typename === 'Me'
        )[0].id;

        const variables = request;

        const response = await mutate({
            variables,
            update: cache => {
                cache.modify({
                    id: `Me:${userId}`,
                    fields: {
                        goal() {
                            return {
                                reachedBecauseOfLoseWeight: false,
                                reachedBecauseOfPutOnWeight: false,
                                lostBecauseOfLoseWeight: false,
                                lostBecauseOfPutOnWeight: false,
                            };
                        },
                    },
                });
            },
        });

        return response;
    };

    return children({ goalChange: createGoalChange });
};

GoalChangeConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
};

export default GoalChangeConnectorApollo;
