import * as actionTypes from './ArticleActionType';
import ArticleFetchReducer from './Fetch/ArticleFetchReducer';
import ArticleListFetchReducer from './FetchList/ArticleListFetchReducer';

const initialState = {
    isLoading: false,
    articleList: {},
    article: {},
};

export default function ArticleReducer(state = initialState, action) {
    let result = state;

    switch (action.type) {
        case actionTypes.ARTICLE_SEND_REQUEST:
        case actionTypes.ARTICLE_RECEIVE_RESPONSE: {
            result = ArticleFetchReducer(state, action);
            break;
        }

        case actionTypes.ARTICLE_LIST_SEND_REQUEST:
        case actionTypes.ARTICLE_LIST_RECEIVE_RESPONSE: {
            result = ArticleListFetchReducer(state, action);
            break;
        }
    }

    return result;
}
