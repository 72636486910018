import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';

import Layout from 'Layout/Layout';
import signet from 'assets/images/sygnet_dr_dabrowska.jpg';
import { withLocale } from '@dietlabs/components';
import { dateTimeToDate } from 'utils/date';

class DietInFuture extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        date: PropTypes.instanceOf(Date).isRequired,
        renderTimeline: PropTypes.func.isRequired,
    };

    render() {
        const { date } = this.props;

        const numberOfDays =
            1 +
            (dateTimeToDate(date) - dateTimeToDate(new Date())) /
                1000 /
                60 /
                60 /
                24;

        return (
            <Layout page="diet">
                {this.props.renderTimeline()}
                <section className="bg-white">
                    <Container>
                        <Row>
                            <Col xs="12" className="text-center">
                                <img
                                    className="mb-4"
                                    src={signet}
                                    alt="Anna Lewandowska"
                                />
                                <h1>
                                    {this.props.t(
                                        'info-pages/diet-available-in-future/title',
                                        { count: numberOfDays }
                                    )}
                                </h1>
                                <p>
                                    {this.props.t(
                                        'info-pages/diet-available-in-future/content'
                                    )}
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Layout>
        );
    }
}

export { DietInFuture };
export default withLocale(DietInFuture);
