import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { formatIsoDate, addDays } from 'utils/dateFormatter';
import ReplaceDayMutation from './ReplaceDayMutation';
import DailyDietPlanQuery from '../Fetch/DailyDietPlanQuery';

const ReplaceDayConnectorApollo = ({ children, date }) => {
    const [replaceDay, { data, loading, error }] = useMutation(
        ReplaceDayMutation,
        {
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: DailyDietPlanQuery,
                    variables: {
                        date: formatIsoDate(date),
                        canBeCopiedToDate: formatIsoDate(addDays(date, 1)),
                    },
                },
            ],
        }
    );

    const createReplaceDay = async ({ replacementId }) => {
        await replaceDay({
            variables: {
                request: {
                    date: formatIsoDate(date),
                    replacementId,
                },
            },
        });
    };

    return children({
        replaceDay: {
            replace: createReplaceDay,
            response: data,
            loading,
            error,
        },
    });
};

ReplaceDayConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
    date: PropTypes.instanceOf(Date).isRequired,
};

export default ReplaceDayConnectorApollo;
