import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import * as types from 'DailyDietPlan/DayPlanTypes';
import { formatIsoDate } from 'utils/dateFormatter';
import FavMealsQuery from './FavMealsQuery';

const FavMealsConnectorApollo = ({ children }) => {
    const { loading, error, data, refetch } = useQuery(FavMealsQuery, {
        variables: {
            text: '',
            date: formatIsoDate(new Date()),
        },
    });

    const result = {
        loading,
        error,
        refetch,
    };

    if (!loading) {
        result.favMeals = data.me.favoriteMeals;
        result.lastAvailableDay =
            data.me.diet.availableDays.length > 0
                ? data.me.diet.availableDays.slice(-1)[0].date
                : '0';
        result.currentPhase =
            data.me.dailyDietPlan.__typename === types.DAY_PLAN
                ? data.me.dailyDietPlan.dayDiet.phase.colour
                : undefined;
    }

    return children(result);
};

FavMealsConnectorApollo.propTypes = {
    children: PropTypes.func.isRequired,
};

export default FavMealsConnectorApollo;
